import { Component } from 'react'
import FormFlowDesigner from '../formBuilder/formFlowDesigner';

class EntryQuestion extends Component {
    state = {
        views: {
            selectedQuestionType: '',
            collapse: false
        }
    }

    render() {
        return (
            <div style={{ marginTop: '40px' }}>
                <div className="container">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <h5>Pertanyaan</h5>
                                </td>
                                <td>
                                    <button onClick={() => {
                                        const currViewsState = this.state.views
                                        currViewsState['collapse'] = !this.state.views.collapse
                                        this.setState({
                                            currViewsState
                                        })
                                    }}>[{this.state.views.collapse ? 'Buka' : 'Tutup'}]</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div style={{ display: this.state.views.collapse ? 'none' : 'block' }}>
                    <FormFlowDesigner formId={this.props.formId} />
                </div>
            </div>
        )
    }
}

export default EntryQuestion;