import { useEffect } from 'react';
import { SmileTwoTone } from '@ant-design/icons';
import ButtonLink from '../../../component/button';
import { Row, Col } from 'antd';
import LandingPage1 from './landingPage1';
import company from '../../../app/config/company_setting';
import 'antd/dist/antd.css';
import Auth from '../../../app/utils/auth';
import useQuery from '../../../public/final/main/hooks/useQuery';

const Customer = () => {

    const query = useQuery()
    const source = query.get('source')

    useEffect(() => {
        document.body.classList.remove('body-bg')
        document.title = "Final"

        const token = localStorage.getItem('token')
        const isTokenExist = token != undefined && token != null
        const sourceUrl = source != null ? "?source=" + source : ""
        if (!isTokenExist) window.location.href = "/login" + sourceUrl
    }, [])

    const auth = new Auth();
    const userData = auth.getUser();

    const bgColor = company === 'pms'
        ? 'rgb(217 236 255)'
        : '#DEDBE8';
    const userBarLogoutBgColor = company === 'pms'
        ? '#f8f9fa'
        : '#FB6600';
    const userBarLogoutFontColor = company === 'pms'
        ? '#000'
        : '#fff';
    const userSmileTwoToneColor = company === 'pms'
        ? '#52c41a'
        : '#715cb9';

    const userBar = <div>
        {
            userData !== null ? <Row>
                <Col xs={0} sm={0} md={12} lg={20} xl={20} xxl={20}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width='3%'><SmileTwoTone twoToneColor={userSmileTwoToneColor} style={{ fontSize: '20pt' }} /></td>
                                <td width='90%'>
                                    <b>{userData.name}</b> <br />
                                    <span style={{ fontSize: '8pt' }}>{userData.email}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4} style={{ paddingBottom: '10px' }}>
                    <ButtonLink title="Keluar" to="/user/logout" style={{ marginTop: '10px', border: 'none', color: userBarLogoutFontColor, backgroundColor: userBarLogoutBgColor }} />
                </Col>
            </Row>
                : ''
        }
    </div>;

    return (
        <div>
            <LandingPage1 />
            <div style={{ maxHeight: '120px', backgroundColor: bgColor, width: '100%', position: 'fixed', bottom: '0px', padding: '10px' }}>
                {userBar}
            </div>
        </div>
    )
}

export default Customer;