import AuthApi from '../../../app/utils/auth'
import React, { useEffect } from 'react'
import FinalMain from './finalMain'
import { Redirect, useParams } from 'react-router-dom'
import Guard from '../../../app/utils/security'
import useQuery from './hooks/useQuery'
import { useHistory } from 'react-router-dom'

function Final() {

    const params = useParams('id')

    // query param
    const query = useQuery()
    const company = query.get('company')
    const source = query.get('source')

    const history = useHistory()

    useEffect(() => {
        document.body.classList.add('.body-bg')

        // check authentication status
        const authApi = new AuthApi()
        const user = authApi.getUser()

        let authenticated = false
        if (user !== undefined && user !== null) {
            // user not authenticated
            authenticated = true
        }

        if (!authenticated) {
            // get current form id
            return <Redirect to={'/unauthorized/' + params.id} />
        }

        const guard = new Guard();
        if (!guard.isFinalUser()) {
            const isCompanyAvaliable = company !== null
            const isSourceAvaliable = source !== null

            if (!(isCompanyAvaliable && isSourceAvaliable)) {
                history.push('/sales-setup')
            }
        }
    }, [])

    return (
        <div>
            <FinalMain formId={params.id} />
        </div>
    )
}

export default Final