import parse from 'html-react-parser';
import { useEffect, useState, useCallback, useRef } from 'react';
import { Popover, Divider } from 'antd';
import 'antd/dist/antd.css';
import '../style.css';

export default function SingleSelectOption(props) {

    const isMounted = useRef(false);
    const inputText = useRef(null);

    const [popOverVisibility, setPopOverVisibility] = useState(false);
    const [optAlphabet, setOptAlphabet] = useState('');

    // controlled otherValue input, make sure to always set to empty string first 
    // to avoid error of detected as uncontrolled input because of undefined state as the state empty
    const [otherValue, setOtherValue] = useState('');

    const [fullData] = useState(props.fullData);
    const [optId] = useState(props.id);

    const keyListener = useCallback((e) => {
        if (isMounted.current) {
            let pressedKey = e.key.toUpperCase();

            if (optAlphabet !== '' && pressedKey === optAlphabet && optId !== 'other' && props.otherInputTextFocus !== 'other') {
                props.action();
            } else if (optAlphabet !== '' && pressedKey === optAlphabet && optId === 'other') {
                if (props.selectedOpt !== 'other') {
                    props.action();
                }

                if (otherValue !== '') {
                    props.otherInputTextFocusAction(props.id, otherValue);
                } else {
                    props.otherInputTextFocusAction(props.id, props.value);
                }

                inputText.current.focus();
            }
        }
    }, [optAlphabet, props.otherInputTextFocus])

    useEffect(() => {
        isMounted.current = true;

        if (optAlphabet === '') {
            for (let index = 0; index < fullData.length; index++) {
                const element = fullData[index];
                if (element.id === optId) {
                    setOptAlphabet(String.fromCharCode(65 + index));
                }
            }
        }

        document.addEventListener('keydown', keyListener, false);

        return () => {
            isMounted.current = false;
            // always remove document listener to make sure we've got latest state
            document.removeEventListener('keydown', keyListener);
        }
    }, [optAlphabet, fullData, optId, props.otherInputTextFocus, keyListener])

    // explanation content
    const popOverContent = <div>
        {parse(props.desc)}
        <br />
        <br />
        <Divider />
        <button onClick={() => setPopOverVisibility(!popOverVisibility)}>[Tutup]</button>
    </div>;

    // help button
    const helpBtn = <Popover content={popOverContent} title="Informasi" visible={popOverVisibility}>
        <span style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => setPopOverVisibility(!popOverVisibility)} className="explanation-btn">Informasi</span>
    </Popover>;

    const handleOtherValueInputChange = (e) => {
        if (e.target.value !== null && e.target.value !== undefined && e.target.value.toLowerCase() !== optAlphabet.toLowerCase()) {
            setOtherValue(e.target.value);

            // update parent other input text
            if (otherValue !== '') {
                props.otherInputTextFocusAction(props.id, e.target.value);
            } else {
                props.otherInputTextFocusAction(props.id, props.value);
            }
        }
    }

    const optionSelector = () => {
        if (props.id !== 'other') {
            return props.value;
        }

        return <input onChange={handleOtherValueInputChange} ref={inputText} className="input-underline-single-select-input" value={otherValue} placeholder={props.value} type="text" />
    }

    const onClickHandler = () => {
        if (optAlphabet !== '' && optId !== 'other' && props.otherInputTextFocus !== 'other') {
            props.action();
        } else if (optAlphabet !== '' && optId === 'other') {
            if (props.selectedOpt !== 'other') {
                props.action();
            }

            // props.otherInputTextFocusAction();
            props.otherInputTextFocusAction(props.id, otherValue);

            inputText.current.focus();
        }
    }

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <button className={parseInt(props.selectedOpt) === parseInt(optId) ? "btn-option-selected" : "btn-option"} onClick={() => onClickHandler()} >
                                <span className="opt-key" style={{ marginRight: '10px' }}>{optAlphabet}</span>
                                {optionSelector()}
                            </button>
                        </td>
                        <td>
                            {props.desc !== '' ? helpBtn : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}