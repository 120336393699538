var itemQtyNode = {
    id: 0, // [FIRST ID] NEED UPDATE
    data: {
        id: 0, // [SECOND ID] VALUE SAME WITH FIRST ID. THIS ID USED TO GET THE NODE ID TO SINGLE OPTION COMPONENT
        required: false,
        label: '',
        score: 1,
        itemCode: '',
        
        // child node only able to access this
        type: 'itemQtyNode',
        style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
        position: { x: 650, y: 100 },
        targetPosition: 'left',
    },
    type: 'itemQtyNode',
    style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
    position: { x: 650, y: 100 },
    targetPosition: 'left',
}

export default itemQtyNode