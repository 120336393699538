/**
 * ONLY external user allowed to use the API
 * hanlde the questionaire view :
 * - load next question
 * - save to draft
 */

import Auth from './auth'
import axios from 'axios'
import EndPoint from '../config/end_point'

export default class FormPublic {

    constructor() {
        this.token = null
        this.endPoint = EndPoint
        this.client = null
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        })

        return this.client
    }

    getFormList() {
        return axios.get(this.endPoint + 'get-form-list')
    }

    getFormHeader(formId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user ?? ''

            return this.init().get('/public/get-header', {
                    params: {
                        id_user: idUser,
                        token: token,
                        form_id: formId
                    }
                })
                .catch(err => err.response.status)
        }

        return new Promise(function(resolve) {
            // call resolve if the method succeeds
            resolve(403)
        })
    }

    getFirstInput(inputId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().get('/public/get-first-question', {
                    params: {
                        id_user: idUser,
                        token: token,
                        initial_input: inputId
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    getSalesArea(draftId, source, kodeCustomer = null) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user
            const salesOffline = api.getUser().sales_offline

            return this.init().get('/public/get-sales-area', {
                    params: {
                        id_user: idUser,
                        token: token,
                        draft_id: draftId,
                        source: source,
                        kode_customer: kodeCustomer,
                        sales_offline: salesOffline
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    getAndStoreInput(
        nextInputId,
        currInputId,
        draftId,
        answer,
        score,
        pendingDestination, // for multiselect option
        kodeCustomer = null,
        asalInquiry = null,
        kotaAsalInquiry = null
    ) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            let formData = new FormData()
            formData.append("id_user", idUser)
            formData.append("token", token)
            formData.append("next_input_id", nextInputId)
            formData.append("curr_input_id", currInputId)
            formData.append("draft_id", draftId)
            formData.append("answer", answer)
            formData.append("score", score)
            if (kodeCustomer != null) formData.append("kode_customer", kodeCustomer)
            if (asalInquiry != null) formData.append("asal_inquiry", asalInquiry)
            if (kotaAsalInquiry != null) formData.append("kota_asal_inquiry", kotaAsalInquiry)

            // console.log('answer', answer)
            if (pendingDestination !== null && pendingDestination !== undefined) {
                formData.append("pending_destination", pendingDestination)
            }

            return this.init().post('/public/get-question-and-store-answer', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth()

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    getBack(currInputId, draftId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            let formData = new FormData()
            formData.append("id_user", idUser)
            formData.append("token", token)
            formData.append("curr_input_id", currInputId)
            formData.append("draft_id", draftId)

            return this.init().get('/public/back', {
                    params: {
                        id_user: idUser,
                        token: token,
                        curr_input_id: currInputId,
                        draft_id: draftId
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth()

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    searchLocation(name) {
        return this.init().get('https://app.geocodeapi.io/api/v1/autocomplete?', {
            params: {
                apikey: '68f95500-1daa-11eb-8a37-5d8f94ecd2bf',
                text: name
            }
        })
    }

    getDraft(formId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().get('/public/get-draft', {
                    params: {
                        id_user: idUser,
                        token: token,
                        form_id: formId
                    }
                })
                .catch(err => err.response.status)
        }

        return this.init().get('/unauthenticated')
    }

    deleteDraft(formId, draftId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/public/delete-draft', {
                    id_user: idUser,
                    token: token,
                    form_id: formId,
                    draft_id: draftId
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    /**
     * executed on draft load. this method will receive last form state
     * 
     * @param {String} draftId 
     * @returns {Object} lastDraftState
     */
    getLastFormState(draftId) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().get('/public/get-draft-last-state', {
                    params: {
                        id_user: idUser,
                        token: token,
                        draft_id: draftId
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    getProvince() {
        var url = "https://web.firecek.com/api/provinsi"
        return this.init().get(url, {
            params: {}
        })
    }

    getCity(provId) {
        var url = "https://web.firecek.com/api/kota/" + provId
        return this.init().get(url, {
            params: {}
        })
    }

    recordActivity(link) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/public/record/user-goto-shop', {
                    id_user: idUser,
                    token: token,
                    link: link
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    recordInquirySource(page, source) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/public/record/inquiry-source', {
                    id_user: idUser,
                    token: token,
                    page: page,
                    source: source
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    recordFeedback(entryId, feedback) {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/public/record/feedback', {
                    id_user: idUser,
                    token: token,
                    entry_id: entryId,
                    feedback: feedback
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

    getSubmissionHistory() {
        const api = new Auth()
        const token = api.getToken()

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().get('/public/submission-history', {
                    params: {
                        id_user: idUser,
                        token: token
                    }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        const auth = new Auth();

                        auth.removeUserSession()
                        window.location.href = "/"
                    }
                })
        }

        return this.init().get('/unauthenticated')
    }

}