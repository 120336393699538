import axios from 'axios'
import endPoint from '../config/end_point';
import Auth from '../utils/auth';

export default class Customer {

    constructor() {
        this.endPoint = endPoint
        this.client = null;
        
        const api = new Auth()
        const token = api.getToken();
        const idUser = api.getUser() != null ? api.getUser().id_user : null

        this.token = token
        this.idUser = idUser
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        })

        return this.client
    }

    search = (keyword) => {
        return this.init()
            .get('customer/find', {
                params: {
                    'keyword': keyword
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    const auth = new Auth();

                    auth.removeUserSession()
                    window.location.href = "/"
                }
            })
    }

    getUser = (kodeCustomer) => {
        return this.init()
            .get('customer/get-user', {
                params: {
                    'id_user': this.idUser,
                    'token': this.token,
                    'kode_customer': kodeCustomer
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    const auth = new Auth();

                    auth.removeUserSession()
                    window.location.href = "/"
                }
            })
    }

    type = (kodeCustomer) => {
        return axios
            .get(this.endPoint + 'customer/get-type', {
                params: {
                    'kode_customer': kodeCustomer
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    const auth = new Auth();

                    auth.removeUserSession()
                    window.location.href = "/"
                }
            })
    }
}