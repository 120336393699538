import { Alert, Col, Row, Carousel } from "antd"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import AuthApi from '../../../../app/utils/auth'
import BmmSlider1 from '../../../../resources/bmm-slider-1.jpg'
import BmmSlider2 from '../../../../resources/bmm-slider-2.jpg'
import BmmSlider3 from '../../../../resources/bmm-slider-3.jpg'
import BmmSlider4 from '../../../../resources/bmm-slider-4.jpg'

const PMSForgotPassword = () => {

    const [viewportWidth, setViewportWidth] = useState()

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)
    }, [])

    const [formField, setFormField] = useState({ email: "" })
    const [errorMsg, setErrorMsg] = useState('')
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const history = useHistory()
    const query = useQuery()
    const source = query.get('source')

    const containerStyle = {
        width: "100%",
        height: "100%",
        paddingTop: "30px",
        paddingHeight: "30px",
        textAlign: "center"
    }

    const inputStyle = {
        border: "none",
        fontSize: "13pt",
        borderBottom: "3px solid #EBEBEB",
        width: "100%",
        outline: "none",
        color: "#5454548c"
    }

    const labelStyle = {
        color: "##212934",
        width: "100%",
        textAlign: "left",
        fontWeight: "bold"
    }

    const buttonStyle = {
        fontWeight: "bold",
        backgroundColor: "rgb(255, 102, 0)",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const buttonStyleDisabled = {
        fontWeight: "bold",
        backgroundColor: "rgb(255 102 0 / 49%)",
        cursor: "not-allowed",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const marginTop30px = {
        marginTop: "30px"
    }

    const marginTop15px = {
        marginTop: "15px"
    }

    const registerStyle = {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        padding: "30px",
        bottom: "0px"
    }

    const required = <span style={{ color: "#FB0606" }}>*</span>

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setApiCallStatus(true);

        const email = formField.email;

        const auth = new AuthApi();
        auth
            .forgotPassword(email)
            .then(response => {
                setApiCallStatus(false);

                const token = response.data.data;
                const sourceUrl = source != null ? "?source=" + source : "";
                history.push('/' + token + '/confirm' + sourceUrl);
            })
    }

    const carausel = <Carousel autoplay>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider1} alt="" width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider2} alt="" width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider3} alt="" width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider4} alt="" width="100%" />
        </div>
    </Carousel>

    return <div style={{ minHeight: "100vh" }}>
        <Row>
            <Col xs={24} sm={24} md={12} lg={12} style={{ backgroundColor: "#DEDBE8", height: viewportWidth < 768 ? "auto" : "100vh" }}>
                {carausel}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Row>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                    <Col xs={16} sm={16} md={16} lg={18}>
                        <div style={containerStyle}>
                            <h1 style={{ fontWeight: "bold" }}>Lupa Password</h1>

                            <span style={{ color: "#212934", fontWeight: "500", ...marginTop30px }}>
                                Memulihkan password Anda dimulai dengan <br />
                                email yang ditautkan ke akun Anda.
                            </span>

                            <form style={{ ...marginTop30px }} onSubmit={submitHandler}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <label style={labelStyle}>
                                            Email {required}
                                        </label>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <input type="email" style={inputStyle} value={formField.email} onChange={(e) => setFormField({ ...formField, email: e.target.value })} required />
                                    </Col>
                                </Row>

                                <Alert message={errorMsg} type="error" style={{ ...marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                                <button type="submit" style={!apiCallStatus ? buttonStyle : buttonStyleDisabled} disabled={apiCallStatus}>Reset Password</button>
                            </form>
                        </div>

                        <div style={registerStyle}>
                            <Link to='/login' style={{ 
                                opacity: ".5", 
                                textDecoration: "none", 
                                color: "#212934", 
                                fontWeight: 700, 
                                padding: "10px",
                                border: "2px solid #808080",
                                borderRadius: "13px"
                            }}>
                                Kembali ke Masuk
                            </Link>
                        </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default PMSForgotPassword