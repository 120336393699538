import { Component } from 'react';
import Navbar from '../../component/navbar/navbar';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormApi from '../../app/utils/formPrivate';
import Guard from '../../app/utils/security';
import { withRouter } from "react-router-dom";
import Auth from '../../app/utils/auth';

class CreateForm extends Component {

    state = {
        fields: {
            name: '',
            desc: '',
            openingTitle: '',
            openingMsg: EditorState.createEmpty(),
            closingMsg: EditorState.createEmpty(),
            openingImage: null
        },
        errors: {
            name: '',
            desc: '',
            openingTitle: '',
            openingMsg: '',
            closingMsg: ''
        }
    }

    componentDidMount() {
        document.title = "Buat form baru"
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["formName"]) {
            formIsValid = false;
            errors["formName"] = "Harus diisi";
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Harus diisi";
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    // return true if token doesn't match the database record
    // means session expired, user need to re-login
    isForbidden(response) {
        return response === 403;
    }

    // clear token then redirect to login page
    clearTokenAndRedirectToLogin() {
        const auth = new Auth();

        // remove session
        auth.removeUserSession();

        this.props.history.push('/pv');
    }

    handleSubmit(e) {
        e.preventDefault();

        // get opening msg
        const getContent = this.state.fields.openingMsg.getCurrentContent();
        const convertContentToRaw = JSON.stringify({
            content: convertToRaw(getContent),
        });
        const openingMsg = convertContentToRaw;

        // get closing msg
        const getClosingMsgContent = this.state.fields.closingMsg.getCurrentContent();
        const convertClosingMsgContent = JSON.stringify({
            content: convertToRaw(getClosingMsgContent),
        });
        const closingMsg = convertClosingMsgContent;

        const formApi = new FormApi();
        formApi
            .createNewForm(
                this.state.fields.name,
                this.state.fields.desc,
                this.state.fields.openingTitle,
                openingMsg,
                this.state.fields.openingImage,
                closingMsg
            )
            .then(response => {
                if (this.isForbidden(response)) {
                    return this.clearTokenAndRedirectToLogin();
                }

                const formId = response.data.data.id;
                this.props.history.push('/pv/setup-form/' + formId);
            });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        let targetValue;

        if (field === 'openingImage') {
            // console.log(e.target.files);
            targetValue = e.target.files[0];
        } else {
            targetValue = e.target.value;
        }

        fields[field] = targetValue;

        console.log(fields);
        this.setState({ fields });
    }

    onEditorOpeningMsgChange = (editorState) => {
        let fields = this.state.fields;
        fields['openingMsg'] = editorState;

        this.setState({
            fields
        });
    }

    onEditorClosingMsgChange = (editorState) => {
        let fields = this.state.fields;
        fields['closingMsg'] = editorState;

        this.setState({
            fields
        });
    }

    render() {
        const guard = new Guard();
        if (guard.privateGeneralGuard() !== undefined) {
            return guard.privateGeneralGuard();
        }

        return (
            <div>
                <Navbar />
                <div className="container" style={{ paddingTop: '20px' }}>
                    <h1>Buat form baru</h1> <br />
                    <form className="form-control" onSubmit={this.handleSubmit.bind(this)} style={{ paddingTop: '20px' }}>
                        <b>Nama form *</b>
                        <input type="text" className="form-control" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]} style={{ marginTop: '10px' }} required />
                        <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                        <br />
                        <b>Deskripsi form *</b>
                        <textarea className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "desc")} value={this.state.fields["desc"]} required></textarea>
                        <span style={{ color: "red" }}>{this.state.errors["desc"]}</span>
                        <br />
                        <b>Opening image *</b>
                        <input type="file" className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "openingImage")} required />
                        <span style={{ color: "red" }}>{this.state.errors["openingImage"]}</span>
                        <br />
                        <b>Opening title *</b>
                        <input type="text" className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "openingTitle")} value={this.state.fields["openingTitle"]} required />
                        <span style={{ color: "red" }}>{this.state.errors["openingTitle"]}</span>
                        <br />
                        <b>Opening message</b>
                        <Editor
                            editorState={this.state.fields['openingMsg']}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="form-control"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorOpeningMsgChange}
                        />
                        <br />
                        <b>Closing message</b>
                        <Editor
                            editorState={this.state.fields['closingMsg']}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="form-control"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorClosingMsgChange}
                        />
                        <br />
                        <button className="btn btn-md btn-dark">Save</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateForm);