import { Row, Col } from 'antd';
import { useEffect } from 'react';
import DocsIllustrationImg from '../../../../resources/docs-illustration-min.svg';
import ButtonLink from '../../../button';
import useQuery from '../../../../public/final/main/hooks/useQuery';
import FormPublic from '../../../../app/utils/formPublic';

function Bromindo2() {

    const param = useQuery();
    const source = param.get('source');
    const formPublic = new FormPublic();

    useEffect(() => {
        if (source != null) formPublic.recordInquirySource('https://final.bromindo.com/next', source)
    }, [])

    return (
        <div style={{ backgroundImage: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', minHeight: '100vh'}}>
            <div className="container">
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Col lg={12} xl={12} xxl={12} align="center">
                        <img src={DocsIllustrationImg} width="70%" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div style={{ width: "100%" }}>
                            <h1 style={{ color: '#fff' }}>
                                Supply & Jasa Instalasi
                            </h1>
                            <h6 style={{ color: '#fff' }}>FIRE HYDRANT - FIRE ALARM - FIRE SYSTEM</h6>
                            <p style={{ color: 'rgb(218 218 218)', fontSize: '15pt' }}>
                                Kirimkan secara lengkap data yang kami butuhkan agar dapat menghitung kebutuhan proyek yang akan kalian bangun / kerjakan, tanpa data yang lengkap inquiry semakin lambat.
                                <br />
                                Persiapkan data telebih dulu, Kalau sudah komplit, silahkan klik lanjut
                            </p>
                        </div>
                        <br />
                        <br />
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: '5px', paddingTop: '10px' }}>
                                <ButtonLink title="Lanjut" to={"/Mg==" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ backgroundColor: 'rgb(237 147 64)', fontWeight: 'bold', border: '1px solid rgb(237 147 64)' }} />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                                <ButtonLink title="Kembali" to={"/" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ fontWeight: 'bold', color: "white", backgroundColor: "transparent", border: '1px solid white' }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Bromindo2;