import Button from '../../component/button';

function navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <span className="navbar-brand">
                    <Button title="Final" to="/pv" />
                </span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Button title="Buat Form" to="/pv/create-form" />
                        </li>
                    </ul>
                </div>
                <form className="form-inline my-2 my-lg-0">
                    <Button title="Keluar" to="/pv/logout" />
                </form>
            </div>
        </nav>
    )
}

export default navbar;