import React, { useState, useEffect } from 'react'
import { Handle } from 'react-flow-renderer'
import { connect } from 'react-redux'
import FormApi from '../../app/utils/formPrivate'
import useDebounce from '../../public/final/main/hooks/useDebounce';

const nodeMultiOption = React.memo(function NodeMultiOption(props) {

    const currentElement = props.elements.filter(i => i.id === props.data.id)[0];

    const [nodeBgColor, setNodeBgColor] = useState(currentElement.data.style.backgroundColor);
    const [nodeData, setNodeData] = useState({
        newLabel: currentElement.data.label,
        newFinishDstName: currentElement.data.onFinishDstName,
        newOptions: currentElement.data.options,
        newRequired: currentElement.data.required,
        newBgColor: currentElement.style.backgroundColor
    });

    const debouncedData = useDebounce(nodeData, 1000);

    useEffect(() => {
        // console.log('[nodeMultiOption.js] isChanged', isChanged());

        if (debouncedData && isChanged()) {
            changeHandler();
        }
    }, [debouncedData])

    // check for change
    const isChanged = () => {
        // check label
        if (currentElement.data.label !== nodeData.newLabel) return true;

        // check required
        if (currentElement.data.required !== nodeData.newRequired) return true;

        // check background color
        if (currentElement.style.backgroundColor !== nodeData.newBgColor) return true;

        // check options length
        if (currentElement.data.options.length !== nodeData.newOptions.length) return true;

        // check option name, desc, score
        // console.log('[nodeMultiOption.js] nodeData, currentElement',[nodeData.newOptions, currentElement.data.options]);
        for (let i = 0; i < nodeData.newOptions.length; i++) {
            const el = nodeData.newOptions[i];
            if (el.name !== currentElement.data.options[i].name) return true;
            if (el.desc !== currentElement.data.options[i].desc) return true;
            if (el.score !== currentElement.data.options[i].score) return true;
        }

        return false;
    }

    const changeHandler = () => {
        // change node bg color
        setNodeBgColor(nodeData.newBgColor);

        // console.log('[nodeMultiOption.js] debouncedData', debouncedData);
        const fromElement = currentElement;
        const currentNode = {
            id: fromElement.id,
            data: {
                id: fromElement.id,
                required: nodeData.newRequired,
                label: nodeData.newLabel,
                // use dst name as dst with id doesn't exist
                onFinishDstName: nodeData.newFinishDstName,
                options: [
                    ...nodeData.newOptions
                ],
                type: 'multiSelectOptionNode',
                style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
                position: fromElement.data.position,
                targetPosition: 'left',
            },
            type: 'multiSelectOptionNode',
            style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
            position: fromElement.data.position,
            targetPosition: 'left',
        }

        props.nodeMultiOptionUpdate(currentNode)

        props.updateMessage({
            status: 'start',
            msg: '[MULTI-OPTION] Memperbarui pertanyaan'
        });

        const formApi = new FormApi();
        formApi
            .updateMultiOptionInput(currentNode)
            .then(response => {
                // console.log(response.data)
                props.updateMessage({
                    status: 'finish-succeed',
                    msg: '[MULTI-OPTION] Berhasil disimpan'
                });

                props.updateMessage({
                    status: 'finish-hide',
                    msg: ''
                });
            })
            .catch(err => {
                props.updateMessage({
                    status: 'finish-failed',
                    msg: '[MULTI-OPTION] Gagal'
                });
            })
    }

    let optionIndex = 0
    
    const optionHandle = nodeData.newOptions.map((i) => {
        optionIndex++;
        let handleTopPosition = 280 + (220 / 2) + ((optionIndex - 1) * 220)
        
        return <Handle
            key={i.id}
            type="source"
            position="right"
            id={i.id.toString()}
            onConnect={(params) => console.log(params)}
            style={{ 
                top: handleTopPosition, 
                background: '#555' 
            }}
        />
    })

    const optionList = nodeData.newOptions.map((i) => {
        return <div key={i.id} style={{ height: '220px'}}>
            <hr style={{ margin:'0px', padding: '0px' }} />
            <span style={{ fontSize: '12px' }}>Nama pilihan </span>
            <br/>
            <input type="text" value={i.name} onChange={(e) => {
                const copyNode = [...nodeData.newOptions];
                const updatedNode = copyNode.map(item => {
                    // update node in an immutable way
                    if (item.id === i.id) return Object.assign({}, item, { name: e.target.value });

                    return item
                })

                setNodeData({
                    ...nodeData,
                    newOptions: updatedNode,
                })
            }} />
            <br />
            <span style={{ fontSize: '12px' }}>Deskripsi </span>
            <br />
            <input type="text" value={i.desc} onChange={(e) => {
                const copyNode = [...nodeData.newOptions];
                const updatedDesc = copyNode.map(item => {
                    // update node in an immutable way
                    if (item.id === i.id) return Object.assign({}, item, { desc: e.target.value });

                    return item
                })

                setNodeData({
                    ...nodeData,
                    newOptions: updatedDesc,
                })
            }} />
            <br />
            <span style={{ fontSize: '12px' }}>Skor </span>
            <br />
            <input type="number" value={i.score} onChange={(e) => {
                const copyNode = [...nodeData.newOptions];
                const updatedScore = copyNode.map(item => {
                    // update node in an immutable way
                    if (item.id === i.id) return Object.assign({}, item, { score: e.target.value });

                    return item
                })

                setNodeData({
                    ...nodeData,
                    newOptions: updatedScore,
                })
            }} />
            <br />
            <button className="btn btn-xs btn-default" onClick={() => {
                // remove option
                const removeOption = [...nodeData.newOptions.filter(item => item.id !== i.id)];
                // console.log('[nodeMultiOption.js] fromElement.data.options', removeOption);
                
                setNodeData({
                    ...nodeData,
                    newOptions: removeOption
                })
            }}>Hapus Pilihan</button>
        </div>
    })

    const addOption = () => {
        let newId = 1;
        if (nodeData.newOptions.length > 0) {
            let lastNode = nodeData.newOptions[(nodeData.newOptions.length - 1)]
            newId = lastNode.id + 1
        }

        const emptyOption = {
            id: (newId),
            name: '',
            desc: '',
            dst: 0,
            score: 0
        }

        const newOptions = [...nodeData.newOptions, {...emptyOption}]
        // console.log('newOptions', newOptions);
        setNodeData({
            ...nodeData,
            newOptions: newOptions
        })
    }

    return (
        <div style={{ backgroundColor: nodeBgColor, margin: '-10px', padding: '10px' }}>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <div style={{ height: '280px' }}>
                <input
                    style={{ marginRight: '10px' }}
                    type="color"
                    onChange={(e) => {
                        setNodeData({
                            ...nodeData,
                            newBgColor: e.target.value
                        })
                    }}
                    defaultValue={nodeBgColor}
                />
                
                Multi select option <hr />
                <b>Pertanyaan :</b> <br/>
                <input type="text" value={nodeData.newLabel} onChange={(e) => {
                    setNodeData({
                        ...nodeData,
                        newLabel: e.target.value
                    })
                }} />
                <hr/>
                <b>Harus diisi :</b>
                <input type="checkbox" style={{ marginLeft: '10px' }} onChange={(e) => {
                    setNodeData({
                        ...nodeData,
                        newRequired: !nodeData.newRequired
                    })
                }} checked={nodeData.newRequired} />
                <hr />
                <b>On Finish :</b>
                <Handle
                    type="source"
                    position="right"
                    id={props.id + '-onFinishDst'}
                    onConnect={(params) => {
                        // console.log('onFinishDst')
                        // console.log(params)

                        setNodeData({
                            ...nodeData,
                            newFinishDstName: params.target
                        })
                    }}
                    style={{
                        background: '#555', top: '220px'
                    }}
                />
                <hr />
                <b>Pilihan :</b>
            </div>
            {optionList}
            <hr/>
            <button className="btn btn-xs btn-default" onClick={addOption}>+ Tambah Pilihan</button>
            {optionHandle}
        </div>
    );
})

const mapStateToProps = state => {
    return {
        elements: state.elements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nodeMultiOptionUpdate: params => dispatch({
            type: 'NODE-MULTI-OPTION.UPDATE', payload: {
                node: params
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(nodeMultiOption);