var multiSelectOptionNode = {
    id: 0, // [FIRST ID] NEED UPDATE
    data: {
        id: 0, // [SECOND ID] VALUE SAME WITH FIRST ID. THIS ID USED TO GET THE NODE ID TO MULTI OPTION COMPONENT
        required: false,
        label: '',
        onFinishDst: '',
        options: [
            // sample
            // {
            //     id: 1,
            //     name: 'Pilihan 1',
            //     desc: 'Desc pilihan 1',
            //     dst: 0,
            //     score: 10
            // },
            // {
            //     id: 2,
            //     name: 'Pilihan 2',
            //     desc: 'Desc pilihan 2',
            //     dst: 0,
            //     score: 2
            // }
        ],
        // child node only able to access this
        type: 'multiSelectOptionNode',
        style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
        position: { x: 650, y: 100 },
        targetPosition: 'left',
    },
    type: 'multiSelectOptionNode',
    style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
    position: { x: 650, y: 100 },
    targetPosition: 'left',
}

export default multiSelectOptionNode