import { Row, Col, Button, Select, Card, Modal, Form, Input, Alert } from 'antd'
import { useEffect, useState } from 'react'
import Auth from '../../app/utils/auth'
import { BMMAuthStyle } from '../../component/layout/BMMAuthLayout'
import DebounceSelect from '../../component/debounce_select'
import Customer from '../../app/service/customer'
import { useHistory } from 'react-router-dom'
import Inquiry from '../../app/service/inquiry'
import company from '../../app/config/company_setting'
import { PMSAuthStyle } from '../../component/layout/PMSAuthLayout'

const SalesSetup = () => {

        const auth = new Auth()
        const customer = new Customer()
        const inquiry = new Inquiry()

        const history = useHistory()

        //const [asalInquiryList, setAsalInquiryList] = useState([])
        const [asalInquiryList, setAsalInquiryList] = useState([ <option key = { '0' }
                    value = "" > Pilih... </option>])
                    const [asalInquiryDetailList, setAsalInquiryDetailList] = useState([])
                    const [successMsg, setSuccessMsg] = useState("")
                    const [errorMsg, setErrorMsg] = useState("")
                    const [apiCallStatus, setApiCallStatus] = useState()
                    const [kategoriCustomerList, setKategoriCustomerList] = useState([])
                    const [registerCustomerModalVisibility, setRegisterCustomerModalVisibility] = useState(false)
                    const [cityList, setCityList] = useState([])
                    const [provinceList, setProvinceList] = useState([ <option key = { '0' }
                            value = "" > Pilih... </option>])
                            const [customerInfoField, setCustomerInfoField] = useState({
                                source: "",
                                company: { value: "", label: "" },
                                inquiryKota: { value: "", label: "" }
                            })
                            const [registerFormField, setRegisterFormField] = useState({
                                name: "",
                                gender: "",
                                email: "",
                                password: "",
                                password_confirmation: "",
                                organization_type: "",
                                organization_name: "",
                                telephone: "8",
                                telephone_changed: false,
                                id_kategori: 1,
                                province: {
                                    label: "",
                                    value: ""
                                },
                                city: {
                                    label: "",
                                    value: ""
                                },
                                address: ""
                            })

                            const showModal = () => {
                                setRegisterCustomerModalVisibility(true)
                            }

                            const handleCancel = () => {
                                setRegisterCustomerModalVisibility(!registerCustomerModalVisibility)
                            }

                            const submitHandler = (e) => {
                                e.preventDefault();
                                const isFormValid = !apiCallStatus &&
                                    registerFormField.password == registerFormField.password_confirmation &&
                                    !(registerFormField.telephone.length < 9 && registerFormField.telephone_changed);

                                if (!isFormValid) return;
                                setErrorMsg('');

                                const formData = {
                                    email: registerFormField.email,
                                    password: registerFormField.password,
                                    perusahaan: registerFormField.organization_name,
                                    alamat: registerFormField.address,
                                    telp: registerFormField.telephone,
                                    kota: registerFormField.city.label,
                                    id_kategori: registerFormField.id_kategori,
                                    id_kabupaten: registerFormField.city.value,
                                    provinsi: registerFormField.province.label,
                                    id_provinsi: registerFormField.province.value,
                                    bentuk_organisasi: registerFormField.organization_type,
                                    nama: registerFormField.name,
                                    gender: registerFormField.gender,
                                    telp: "+62" + registerFormField.telephone,
                                    sales_offline: auth.getUser().sales_offline
                                }

                                setApiCallStatus(true);

                                auth
                                    .verifyEmailavailability(formData.email)
                                    .then(res => {

                                        if (res.status === 200 && res.data.availability) {
                                            proceedRegistration(formData)
                                        } else {
                                            setApiCallStatus(false);
                                            setErrorMsg('Email sudah digunakan. Pernah input customer dengan email ' + formData.email + '? Mungkin customer sudah pernah dimasukkan sebelumnya');
                                        }
                                    })
                                    .catch((er) => {
                                        setErrorMsg(er.toString())
                                        setApiCallStatus(false);
                                    })
                            }

                            const proceedRegistration = (formData) => {
                                const formDataMergeWithProvAndCityId = {
                                    ...formData,
                                    id_provinsi: parseInt(formData.id_provinsi),
                                    id_kabupaten: parseInt(formData.id_kabupaten)
                                };

                                formData = formDataMergeWithProvAndCityId;

                                auth
                                    .register(formData)
                                    .then(response => {
                                        setRegisterCustomerModalVisibility(false)
                                        if (response.status === 200) {
                                            setSuccessMsg("Customer berhasil disimpan")
                                            setTimeout(() => {
                                                setSuccessMsg("")
                                            }, 5000);
                                        }

                                        setApiCallStatus(false);

                                        // reset form value
                                        resetForm()
                                    })
                                    .catch((er) => {
                                        setRegisterCustomerModalVisibility(false)
                                        setErrorMsg(er.toString())
                                        setApiCallStatus(false);
                                    })
                            }

                            const resetForm = () => {
                                setCityList([])
                                setRegisterFormField({
                                    name: "",
                                    gender: "",
                                    email: "",
                                    password: "",
                                    password_confirmation: "",
                                    organization_type: "",
                                    organization_name: "",
                                    telephone: "8",
                                    telephone_changed: false,
                                    id_kategori: 1,
                                    province: {
                                        label: "",
                                        value: ""
                                    },
                                    city: {
                                        label: "",
                                        value: ""
                                    },
                                    address: ""
                                })
                            }

                            useEffect(() => {
                                auth.getProvince()
                                    .then(response => {
                                        setProvinceList([
                                            ...provinceList,
                                            response.data.map(
                                                i => <option key = { i.id }
                                                value = { i.id } > { i.name } </option>
                                            )
                                        ])
                                    })

                                auth.getKategoriCustomer()
                                    .then(response => {
                                        if ("data" in response.data) setKategoriCustomerList([
                                            ...kategoriCustomerList,
                                            response.data.data.map(
                                                i => <option key = { i.id_kategori }
                                                value = { i.id_kategori } > { i.nama_kategori } </option>
                                            )
                                        ])
                                    })

                                // inquiry.getAsalInquiry()
                                //     .then(res => setAsalInquiryList(res.data.data))

                                inquiry.getAsalInquiry()
                                    .then(response => {
                                        setAsalInquiryList([
                                            ...asalInquiryList,
                                            response.data.data.map(
                                                i => <option key = { i.id }
                                                value = { i.id } > { i.nama } </option>
                                            )
                                        ])
                                    })
                            }, [])

                            const registerCustomerForm = () => {
                                return <form onSubmit = { submitHandler } >
                                <Row>
                                    <Col xs = { 24 }
                                        sm = { 24 }
                                        md = { 24 }
                                        lg = { 24 }
                                        style = {
                                                {...BMMAuthStyle.marginTop15px }
                                            } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Nama { BMMAuthStyle.required } 
                                        </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                        sm = { 24 }
                                        md = { 24 }
                                        lg = { 24 }
                                        style = {
                                                {...BMMAuthStyle.marginTop15px }
                                            } >
                                        <input type = "text"
                                        style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.name }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, name: e.target.value })
                                        }
                                        required />
                                    </Col>

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Email { BMMAuthStyle.required } 
                                        </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <input type = "email"
                                        style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.email }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, email: e.target.value })
                                        }
                                        required />
                                    </Col>

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Jenis Kelamin { BMMAuthStyle.required } 
                                        </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <select style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.gender }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, gender: e.target.value })
                                        }
                                        required >
                                            <option value = "" > Pilih.. </option> 
                                            <option value = { "L" } > Laki - laki </option> 
                                            <option value = { "P" } > Perempuan </option> 
                                        </select > 
                                    </Col>

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Password { BMMAuthStyle.required } 
                                        </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <input type = "password"
                                        style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.password }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, password: e.target.value })
                                        }
                                        required />
                                    </Col>

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Konfirmasi Password { BMMAuthStyle.required } 
                                        </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <input type = "password"
                                        style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.password_confirmation }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, password_confirmation: e.target.value })
                                        }
                                        required />

                                        <Alert message = { "Password tidak sama" }
                                        type = "error"
                                        style = {
                                            {...BMMAuthStyle.marginTop15px, display: registerFormField.password === registerFormField.password_confirmation ? "none" : "block" }
                                        }
                                        /> 
                                    </Col>

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <label style = { BMMAuthStyle.labelStyle } >
                                        Bentuk Organisasi { BMMAuthStyle.required } 
                                        </label> 
                                    </Col >

                                    <Col xs = { 24 }
                                    sm = { 24 }
                                    md = { 24 }
                                    lg = { 24 }
                                    style = {
                                            {...BMMAuthStyle.marginTop15px }
                                        } >
                                        <select style = { BMMAuthStyle.inputStyle }
                                        value = { registerFormField.organization_type }
                                        onChange = {
                                            (e) => setRegisterFormField({...registerFormField, organization_type: e.target.value })
                                        }
                                        required >
                                            <option value = "" > Pilih.. </option> 
                                            <option value = { "perusahaan" } > Perusahaan </option> 
                                            <option value = { "perseorangan" } > Perseorangan </option> 
                                            </select > 
                                    </Col>

                                {
                                    registerFormField.organization_type === "perusahaan" ?
                                        <Col xs = { 24 }
                                        sm = { 24 }
                                        md = { 24 }
                                        lg = { 24 }
                                        style = {
                                                {...BMMAuthStyle.marginTop15px }
                                            } >
                                            <label style = { BMMAuthStyle.labelStyle } >
                                            Nama Perusahaan / Instansi { BMMAuthStyle.required } 
                                            </label> 
                                        </Col >: < Col xs = { 0 }
                                    />
                                }

                                {registerFormField.organization_type === "perusahaan" ?
                                        <Col xs = { 24 }
                                        sm = { 24 }
                                        md = { 24 }
                                        lg = { 24 }
                                        style = {
                                                {...BMMAuthStyle.marginTop15px }
                                            } >
                                            <input type = "text"
                                            style = { BMMAuthStyle.inputStyle }
                                            value = { registerFormField.organization_name }
                                            onChange = {
                                                (e) => setRegisterFormField({...registerFormField, organization_name: e.target.value })
                                            }
                                            required />
                                        </Col>: <Col xs = { 0 } />
                                }

                                <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <label style = { BMMAuthStyle.labelStyle } >
                                    Nomor Telepon { BMMAuthStyle.required } 
                                    </label> 
                                </Col > 
                                <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <Row >
                                    <Col xs = { 4 }
                                sm = { 4 }
                                md = { 4 }
                                lg = { 3 } >
                                    <input type = "text"
                                style = { BMMAuthStyle.inputStyle }
                                value = "+62"
                                readOnly />
                                    </Col> 
                                <Col xs = { 20 }
                                sm = { 20 }
                                md = { 20 }
                                lg = { 21 } >
                                    <input type = "number"
                                style = { BMMAuthStyle.inputStyle }
                                value = { registerFormField.telephone }
                                onChange = {
                                    (e) => setRegisterFormField({
                                        ...registerFormField,
                                        telephone: e.target.value,
                                        telephone_changed: true
                                    })
                                }
                                required />
                                    </Col> 
                                </Row >

                                    <Alert message = { "Nomor telepon minimal 10 karakter" }
                                type = "error"
                                style = {
                                    {...BMMAuthStyle.marginTop15px, display: registerFormField.telephone.length < 9 && registerFormField.telephone_changed ? "block" : "none" }
                                }
                                /> </Col >

                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <label style = { BMMAuthStyle.labelStyle } >
                                    Kategori { BMMAuthStyle.required } 
                                    </label> 
                                </Col > 
                                <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <select style = { BMMAuthStyle.inputStyle }
                                onChange = {
                                    (e) => {
                                        setRegisterFormField({
                                            ...registerFormField,
                                            id_kategori: e.target.value
                                        })
                                    }
                                }
                                value = { registerFormField.id_kategori }
                                required > { kategoriCustomerList } 
                                </select> 
                                </Col >

                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <label style = { BMMAuthStyle.labelStyle } >
                                    Provinsi { BMMAuthStyle.required } 
                                    </label> 
                                </Col > 
                                <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <select style = { BMMAuthStyle.inputStyle }
                                onChange = {
                                    (e) => {
                                        const provinceId = e.target.value
                                        const optionLabel = e.target.selectedOptions[0].label

                                        auth
                                            .getCity(provinceId)
                                            .then(response => {
                                                setRegisterFormField({
                                                    ...registerFormField,
                                                    province: { label: optionLabel, value: provinceId },
                                                    city: {
                                                        label: response.data.length > 0 ?
                                                            response.data[0].name : "",
                                                        value: response.data.length > 0 ?
                                                            response.data[0].id : ""
                                                    }
                                                })

                                                setCityList(response.data.map(
                                                    i => <option key = { i.id }
                                                    value = { i.id } > { i.name } </option>
                                                ))
                                            });
                                    }
                                }
                                value = { registerFormField.province.value }
                                required > { provinceList } 
                                </select> 
                                </Col >

                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <label style = { BMMAuthStyle.labelStyle } >
                                    Kota { BMMAuthStyle.required } 
                                    </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <select style = { BMMAuthStyle.inputStyle }
                                onChange = {
                                    (e) => {
                                        const cityId = e.target.value
                                        console.log('cityId', cityId)
                                        const optionLabel = e.target.selectedOptions[0].label

                                        setRegisterFormField({...registerFormField, city: { label: optionLabel, value: cityId } })
                                    }
                                }
                                value = { registerFormField.city.value }
                                required > { cityList } </select> 
                                </Col >

                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <label style = { BMMAuthStyle.labelStyle } >
                                    Alamat Lengkap { BMMAuthStyle.required } 
                                    </label> 
                                    </Col > 
                                    <Col xs = { 24 }
                                sm = { 24 }
                                md = { 24 }
                                lg = { 24 }
                                style = {
                                        {...BMMAuthStyle.marginTop15px }
                                    } >
                                    <input type = "text"
                                style = { BMMAuthStyle.inputStyle }
                                value = { registerFormField.address }
                                onChange = {
                                    (e) => setRegisterFormField({...registerFormField, address: e.target.value })
                                }
                                required />
                                    </Col>

                                </Row>

                                <Alert message = { errorMsg }
                                type = "error"
                                style = {
                                    {...BMMAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }
                                }/> 
                                <button 
                                type = "submit"
                                style = {
                                    {
                                        ...(!apiCallStatus &&
                                            registerFormField.password == registerFormField.password_confirmation &&
                                            !(registerFormField.telephone.length < 9 && registerFormField.telephone_changed)
                                        ) ?
                                        company == 'bmm' ?
                                            BMMAuthStyle.buttonStyle :
                                            PMSAuthStyle.buttonStyle: company == 'bmm' ?
                                            BMMAuthStyle.buttonStyleDisabled :
                                            PMSAuthStyle.buttonStyleDisabled,
                                            marginBottom: "0px",
                                            marginTop: "40px"
                                    }
                                }
                                disabled = { apiCallStatus } >
                                    Submit
                                </button> 
                            </form>
                        }

                            async function fetchCustomerList(keyword) {
                                return customer.search(keyword)
                                    .then((body) => {
                                        return body.data.data.map((customer) => ({
                                            label: <div > { customer.nama_customer } < br /> { customer.alamat } < br />
                                                Email: { customer.email } | PIC: { customer.cp } 
                                                </div>,
                                            value: customer.kode_customer,
                                        }))
                                    }, );
                            }

                            const next = () => {
                                if (!(customerInfoField.company.length == 0 || customerInfoField.source.length == 0)) {
                                    history.push(`/Mg==?company=${customerInfoField.company.value}&source=${customerInfoField.source}&kota=${customerInfoField.inquiryKota}`)
                                }
                            }

                            const back = () => history.goBack()

                            return <div >
                                <Modal
                            visible = { registerCustomerModalVisibility }
                            title = "Input Customer"
                            onCancel = { handleCancel }
                            footer = {
                                []
                            } > { registerCustomerForm() } 
                            </Modal>

                            <Row justify = "center"
                            align = "middle" >
                            <Col xs = { 24 }
                            sm = { 24 }
                            md = { 24 }
                            lg = { 24 }
                            xl = { 24 } >
                            <div style = {
                                { width: '100%', minHeight: '100vh' }
                            } >
                            <div style = {
                                { zIndex: '51' }
                            } >
                            <div >
                            <Row justify = "center"
                            align = "middle"
                            style = {
                                { minHeight: '100vh', backgroundColor: '#eee' }
                            } >
                            <Col xs = { 2 }
                            sm = { 2 }
                            md = { 7 }
                            lg = { 7 }
                            xl = { 7 } > 
                            </Col> 
                            <Col xs = { 20 }
                            sm = { 20 }
                            md = { 10 }
                            lg = { 10 }
                            xl = { 10 } >
                            <Alert message = { successMsg }
                            type = "success"
                            style = {
                                { marginBottom: "20px", display: successMsg !== "" ? "block" : "none" }
                            }
                            />

                            <Card title = "Informasi Customer"
                            style = {
                                { border: "1px solid #eee" }
                            } >
                            <div style = {
                                { marginBottom: '10px' }
                            } > Pilih Customer 
                            </div> 
                            <DebounceSelect
                            value = { customerInfoField.company }
                            fetchOptions = { fetchCustomerList }
                            onChange = { newValue => setCustomerInfoField({...customerInfoField, company: newValue }) }
                            style = {
                                {
                                    width: '100%',
                                }
                            }
                            />

                            {
                                /* <div style={{ marginBottom: '10px', marginTop: '20px' }}>Asal Inquiry</div>
                                                                        <select style={BMMAuthStyle.inputStyle} onChange={(e) => setCustomerInfoField({ ...customerInfoField, source: e.target.value })} required >
                                                                            <option value="">Pilih ..</option>
                                                                            {
                                                                                asalInquiryList.map(i => <option key={i.id} value={i.id}>{i.nama}</option>)
                                                                            }
                                                                        </select> */
                            }

                            <div style = {
                                { marginBottom: '10px', marginTop: '20px' }
                            } > Asal Inquiry 
                            </div> 
                            <select style = { BMMAuthStyle.inputStyle }
                            onChange = {
                                (e) => {
                                    setCustomerInfoField({...customerInfoField, source: e.target.value })

                                    const asalId = e.target.value
                                        //const optionLabel = e.target.selectedOptions[0].label

                                    inquiry.getAsalInquiryDetail(asalId)
                                        .then(response => {
                                            // setRegisterFormField({
                                            //     ...registerFormField,
                                            //     province: { label: optionLabel, value: provinceId },
                                            //     city: {
                                            //         label: response.data.length > 0
                                            //             ? response.data[0].name
                                            //             : "",
                                            //         value: response.data.length > 0
                                            //             ? response.data[0].id
                                            //             : ""
                                            //     }
                                            // })

                                            setAsalInquiryDetailList(response.data.data.map(
                                                i => <option key = { i.id }
                                                value = { i.id } > { i.kota } </option>
                                            ))
                                        });
                                }
                            }
                            required > { asalInquiryList } 
                            </select>

                            {
                                asalInquiryDetailList.length != 0 ?
                                    <div >
                                    <div style = {
                                        { marginBottom: '10px', marginTop: '20px' }
                                    } > Kota Asal Inquiry </div> 
                                    <select style = { BMMAuthStyle.inputStyle }
                                onChange = {
                                        (e) => {
                                            setCustomerInfoField({...customerInfoField, inquiryKota: e.target.value })

                                            const asalICityId = e.target.value
                                            console.log('asalICityId', asalICityId)
                                            const optionLabel = e.target.selectedOptions[0].label

                                            //setRegisterFormField({ ...registerFormField, city: { label: optionLabel, value: cityId } })
                                        }
                                    }
                                    //value={registerFormField.city.value}
                                required > { asalInquiryDetailList } 
                                </select> 
                                </div >: ''
                            }

                            <Button
                            style = {
                                { marginTop: '10px' }
                            }
                            disabled = { customerInfoField.company.length == 0 || customerInfoField.source.length == 0 }
                            onClick = { next }
                            type = "primary" >
                            Lanjut </Button> 
                            <Button
                            style = {
                                { marginTop: '10px', marginLeft: '10px' }
                            }
                            onClick = { back }
                            type = "text" >
                            Kembali </Button> <br/>
                            Tidak dapat menemukan customer ? <Button style = {
                                { marginTop: '10px' }
                            }
                            onClick = { showModal }
                            type = "text" > Daftarkan Customer </Button> 
                            </Card > 
                            </Col> 
                            <Col xs = { 2 }
                            sm = { 2 }
                            md = { 7 }
                            lg = { 7 }
                            xl = { 7 } > 
                            </Col> 
                            </Row > 
                            </div> 
                            </div > 
                            </div> 
                            </Col > 
                            </Row> 
                            </div >
                        }

                        export default SalesSetup