import React, { useState, useEffect } from 'react'
import {  Handle } from 'react-flow-renderer'
import { connect } from 'react-redux'
import FormApi from '../../app/utils/formPrivate';
import useDebounce from '../../public/final/main/hooks/useDebounce';

const nodeInformation = React.memo(function NodeInformation(props) {

    const currentElement = props.elements.filter(i => i.id === props.data.id)[0];
    const [nodeData, setNodeData] = useState({
        newLabel: currentElement.data.label,
        newBgColor: currentElement.style.backgroundColor
    });

    const debouncedData = useDebounce(nodeData, 1000);

    useEffect(() => {
        // console.log('[nodeInformation.js] state, props', [nodeData.newBgColor, currentElement.style.backgroundColor]);
        // console.log('[nodeInformation.js] isChanged', isChanged());
        if (debouncedData && isChanged()) {
            changeHandler();
        }
    }, [debouncedData])

    // check for change
    const isChanged = () => {
        // check label
        if (currentElement.data.label !== nodeData.newLabel) return true;

        if (currentElement.style.backgroundColor !== nodeData.newBgColor) return true;

        return false;
    }

    const changeHandler = () => {
        const currentNode = {
            id: currentElement.id,
            data: {
                id: currentElement.id,
                label: nodeData.newLabel,

                // child node only able to access this
                type: 'informationNode',
                style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
                position: currentElement.position,
                targetPosition: 'left',
            },
            type: 'informationNode',
            style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
            position: currentElement.position,
            targetPosition: 'left',
        }

        props.nodeUpdate(currentNode)

        props.updateMessage({
            status: 'start',
            msg: '[INFORMATION] Memperbarui pertanyaan'
        });

        const formApi = new FormApi();
        // update database
        formApi
            .updateInformationInput(currentNode)
            .then(response => {
                console.log(response.data)
                props.updateMessage({
                    status: 'finish-succeed',
                    msg: '[INFORMATION] Berhasil disimpan'
                });

                props.updateMessage({
                    status: 'finish-hide',
                    msg: ''
                });
            })
            .catch(err => {
                props.updateMessage({
                    status: 'finish-failed',
                    msg: '[INFORMATION] Gagal'
                });
            })
    }

    return (
        <div style={{ backgroundColor: nodeData.newBgColor, margin: '-10px', padding: '10px' }}>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <div>
                <input
                    style={{ marginRight: '10px' }}
                    type="color"
                    onChange={(e) => setNodeData({
                        ...nodeData,
                        newBgColor: e.target.value
                    })}
                    defaultValue={nodeData.newBgColor}
                />

                Information <hr />
                <b>Note :</b> <br />
                <input type="text" value={nodeData.newLabel} onChange={(e) => setNodeData({
                    ...nodeData,
                    newLabel: e.target.value
                })} />
            </div>
            <Handle
                type="source"
                position="right"
                id={'1'}
                onConnect={(params) => {console.log(params)}}
                style={{
                    background: '#555'
                }}
            />
        </div>
    );
});

const mapStateToProps = state => {
    return {
        elements: state.elements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nodeUpdate: params => dispatch({
            type: 'GENERAL.UPDATE-ELEMENTS', payload: {
                node: params
            }
        }),
        nodeInformationSetQuestion: params => dispatch({
            type: 'NODE-INFORMATION.SET-QUESTION', payload: {
                node: params
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(nodeInformation);