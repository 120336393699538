import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { Row, Col, Alert } from "antd"
import AuthApi from '../../../../app/utils/auth'
import { BMMAuthLayout, BMMAuthStyle } from "../../../../component/layout/BMMAuthLayout"
import useQuery from '../../main/hooks/useQuery'
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

const BMMConfirmPasswordResetCode = () => {

    const [errorMsg, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const [timer, setTimer] = useState(60)
    const [code, setCode] = useState("")

    const { token } = useParams()
    const query = useQuery()
    const source = query.get('source')
    const history = useHistory()

    const auth = new AuthApi()

    useEffect(() => {
        let myInterval = setInterval(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => {
            clearInterval(myInterval)
        }
    }, [timer])

    const confirmCode = (e) => {
        e.preventDefault()
        setErrorMsg("")
        setSuccessMsg("")

        setApiCallStatus(true)

        auth
            .confirmForgotPasswordCode(token, code)
            .then(response => {
                if (response.data.code !== 200) throw response.data.msg
                
                setApiCallStatus(false)
                setSuccessMsg(response.data.msg)
                setTimeout(() => {
                    setSuccessMsg("")
                }, 5000);

                const token = response.data.data
                const sourceUrl = source != null ? "?source=" + source : ""
                history.push('/' + token + '/new-password' + sourceUrl)
            })
            .catch((err) => {
                setApiCallStatus(false)
                setErrorMsg(err)
            })
    }

    const resendConfirmationCode = (e) => {
        e.preventDefault()
        setErrorMsg("")
        setSuccessMsg("")

        setApiCallStatus(true)

        auth
            .forgotPassword(null, token)
            .then(response => {
                setApiCallStatus(false)
                setSuccessMsg("Email berhasil dikirim")
                setTimer(60)
                setTimeout(() => {
                    setSuccessMsg("")
                }, 5000);

                const token = response.data.data
                const sourceUrl = source != null ? "?source=" + source : ""
                history.push('/' + token + '/confirm' + sourceUrl)
            })
            .catch((err) => {
                setApiCallStatus(false)
                setErrorMsg("Oops, sepertinya ada yang salah (" + err + ")")
            })
    }

    return <BMMAuthLayout>
        <div style={BMMAuthStyle.containerStyle}>
            <h1 style={{ fontWeight: "bold" }}>Konfirmasi Kode</h1>

            <span style={{ color: "#212934", fontWeight: "500", ...BMMAuthStyle.marginTop30px }}>
                Kami telah mengirim email kode konfirmasi ke email Anda,<br />
                silahkan periksa spam apabila email belum masuk.<br />
            </span>

            <form style={{ ...BMMAuthStyle.marginTop30px }} onSubmit={confirmCode} >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <label style={BMMAuthStyle.labelStyle}>
                            Masukkan Kode Reset {BMMAuthStyle.required}
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <input type="number" maxLength={5} style={BMMAuthStyle.inputStyle} value={code} onChange={(e) => setCode(e.target.value)} required />
                    </Col>
                </Row>

                <Alert message={successMsg} type="success" style={{ ...BMMAuthStyle.marginTop15px, display: successMsg == "" ? "none" : "block" }} />
                <Alert message={errorMsg} type="error" style={{ ...BMMAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                <button type="submit" 
                    style={{ ...(!apiCallStatus ? BMMAuthStyle.buttonStyle : BMMAuthStyle.buttonStyleDisabled), marginBottom: "0px" }} 
                    disabled={apiCallStatus}>
                    Reset Password
                </button>
                <button type="button"
                    onClick={resendConfirmationCode}
                    style={{ 
                        ...((timer > 0 || apiCallStatus)
                            ? BMMAuthStyle.buttonStyleDisabled 
                            : BMMAuthStyle.buttonStyle),
                        marginTop: "10px", 
                        color: "rgb(255, 102, 0)", 
                        border: "2px solid rgb(255, 102, 0)",
                        backgroundColor: (timer > 0) || apiCallStatus
                            ? "#dfdfdf"
                            : "white"
                    }}
                    
                    disabled={(timer > 0 || apiCallStatus)}>
                    Kirim Ulang Email 
                    {
                        timer > 0
                            ? ` (${timer})`
                            : ""
                    }
                </button>
            </form>
        </div>

        <div style={BMMAuthStyle.registerStyle}>
            <Link to='/login' style={{
                opacity: ".5",
                textDecoration: "none",
                color: "#212934",
                fontWeight: 700,
                padding: "10px",
                border: "2px solid #808080",
                borderRadius: "13px"
            }}>
                Kembali ke Masuk
            </Link>
        </div>
    </BMMAuthLayout>
}

export default BMMConfirmPasswordResetCode