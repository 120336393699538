import parse from 'html-react-parser';
import { useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';
import './style.css';
import ContactPerson from '../../../../component/contactPerson';

export default function InformationComponent(props) {
    
    const keyListener = useCallback(
        () => {
            props.action(props.data.id, props.data.next_input_id, props.draftId, '', props.data.score);
        }, [props.draftId, props, props.data.id, props.data.next_input_id, props.data.score]
    )

    useEffect(() => {
        // if is currrent is true the the state is latest
        let isCurrent = true;
        // console.log('props information = ' + isCurrent, props);

        // listen keyboard
        document.addEventListener('keydown', (e) => {
            let pressedKey = e.key != undefined ? e.key.toUpperCase() : "";

            // check if enter btn pressed and the state value is latest state
            if (pressedKey === 'ENTER' && isCurrent) {
                keyListener();
            }
        }, false);

        return () => {
            isCurrent = false;
        }
    }, [keyListener])

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>{parse(props.data.question) }</h1>

                    <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                        <Button className="fancy-shadow" onClick={() => keyListener()}>OK</Button> 
                        <span style={{ fontSize:'10pt', marginLeft:'10px' }}>tekan <strong>Enter ↵</strong></span>
                    </div>

                    <ContactPerson />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}