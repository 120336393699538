import React, { useState, useEffect } from 'react'
import { Handle } from 'react-flow-renderer'
import { connect } from 'react-redux'
import FormApi from '../../app/utils/formPrivate';
import useDebounce from '../../public/final/main/hooks/useDebounce';

const nodeCompanyType = React.memo(function NodeCompanyType(props) {

    const currentElement = props.elements.filter(i => i.id === props.data.id)[0];

    const [nodeData, setNodeData] = useState({
        newBgColor: currentElement.style.backgroundColor
    });

    const debouncedData = useDebounce(nodeData, 1000);

    useEffect(() => {
        // console.log('[nodeCompanyType.js] props', props);
        // console.log('[nodeCompanyType.js] isChanged', isChanged());

        if (debouncedData && isChanged()) {
            changeHandler();
        }
    }, [debouncedData])

    // check for change
    const isChanged = () => {
        // check background color
        if (currentElement.style.backgroundColor !== nodeData.newBgColor) return true;

        return false;
    }

    const changeHandler = () => {
        const currentNode = {
            id: currentElement.id,
            data: {
                id: currentElement.id, // [SECOND ID] VALUE SAME WITH FIRST ID. THIS ID USED TO GET THE NODE ID TO SINGLE OPTION COMPONENT
                label: '',
                options: {
                    perusahaan: currentElement.data.perusahaan,
                    perseorangan: currentElement.data.perseorangan
                },

                // child node only able to access this
                type: 'companyTypeNode',
                style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
                position: { x: currentElement.position.x, y: currentElement.position.y },
                targetPosition: 'left',
            },
            type: 'companyTypeNode',
            style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
            position: { x: currentElement.position.x, y: currentElement.position.y },
            targetPosition: 'left',
        }

        props.nodeUpdate(currentNode)
        props.updateMessage({
            status: 'start',
            msg: '[COMPANY-TYPE] Memperbarui pertanyaan'
        });
        
        // prevent react calling API on unnecessary need
        const delayDebounceFn = setTimeout(() => {
            const formApi = new FormApi();
            formApi
                .updateCompanyTypeInput(currentNode)
                .then(response => {
                    // console.log(response.data)
                    props.updateMessage({
                        status: 'finish-succeed',
                        msg: '[COMPANY-TYPE] Berhasil disimpan'
                    });

                    props.updateMessage({
                        status: 'finish-hide',
                        msg: ''
                    });
                })
                .catch(err => {
                    props.updateMessage({
                        status: 'finish-failed',
                        msg: '[COMPANY-TYPE] Gagal'
                    });
                })
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }

    return (
        <div style={{ backgroundColor: currentElement.data.style.backgroundColor, margin: '-10px', padding: '10px' }}>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <div>
                <input
                    style={{ marginRight: '10px' }}
                    type="color"
                    onChange={(e) => {
                        setNodeData({
                            ...nodeData,
                            newBgColor: e.target.value
                        })
                    }}
                    defaultValue={currentElement.data.style.backgroundColor}
                />

                Customer Type
            </div>
            <hr />
            Perusahaan
            <Handle
                type="source"
                position="right"
                id={'perusahaan'}
                onConnect={(params) => {
                    // console.log(params)
                }}
                style={{
                    background: '#555'
                }}
            />
            <hr />
            Perseorangan
            <Handle
                type="source"
                position="right"
                id={'perseorangan'}
                onConnect={(params) => {
                    // console.log(params)
                }}
                style={{
                    background: '#555',top: '130px'
                }}
            />
        </div>
    );
})

const mapStateToProps = state => {
    return {
        elements: state.elements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nodeUpdate: params => dispatch({
            type: 'GENERAL.UPDATE-ELEMENTS', payload: {
                node: params
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(nodeCompanyType);