import parse from 'html-react-parser';
import { useEffect, useCallback } from 'react';
import { Row, Col, Button, Divider } from 'antd';
import 'antd/dist/antd.css';
import './style.css';
import Avatar from 'antd/lib/avatar/avatar';
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import ContactPerson from '../../../../component/contactPerson';

export default function SalesAreaInfoComponent(props) {
    
    const keyListener = useCallback(
        () => {
            props.action(props.data.id, props.data.next_input_id, props.draftId, '', props.data.score);
        }, [props.draftId, props, props.data.id, props.data.next_input_id, props.data.score]
    )

    useEffect(() => {
        // if is currrent is true the the state is latest
        let isCurrent = true;

        // listen keyboard
        document.addEventListener('keydown', (e) => {
            let pressedKey = e.key.toUpperCase();

            // check if enter btn pressed and the state value is latest state
            if (pressedKey === 'ENTER' && isCurrent) {
                keyListener();
            }
        }, false);

        return () => {
            isCurrent = false;
        }
    }, [keyListener])

    const salesInformation = (avatarUrl, name, phone, email) => {
        return <div>
            <Divider />
            <table width="100%">
                <tbody>
                    <tr>
                        <td rowSpan={2} width="10%">
                            <Avatar
                                style={{ width:'100%', height: 'auto' }}
                                src={avatarUrl}
                                alt="Avatar"
                            />
                        </td>
                        <td>
                            <div style={{ paddingLeft:'20px', fontSize: '18pt' }}>
                                <strong>{name}</strong>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ paddingLeft: '20px', fontSize: '18pt' }}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={10} xl={8} >
                                        <WhatsAppOutlined /> {phone}
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={14} xl={16} >
                                        <MailOutlined /> {email}
                                    </Col>
                                </Row>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />
        </div>
    }

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>{parse(props.data.question) }</h1>
                    
                    { salesInformation(props.value.avatar, props.value.name, props.value.hp_pribadi, props.value.email) }

                    <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                        <Button className="fancy-shadow" onClick={() => keyListener()}>OK</Button> 
                        <span style={{ fontSize:'10pt', marginLeft:'10px' }}>tekan <strong>Enter ↵</strong></span>
                    </div>

                    <ContactPerson />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}