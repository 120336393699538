import EndPoint from '../config/end_point'
import axios from 'axios'

export default class Auth {

    /**
     * handle authentication process :
     * - communicating with backend API
     * - store token on localStorage
     */

    /**
     * setting up TOKEN, api ENDPOINT to perform API REQUEST
     */
    constructor() {
        this.token = null
        this.endPoint = EndPoint
        this.client = null
    }

    /**
     * initiate axios settings
     * - set up baseURL
     * - set up timeout
     */
    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        })

        return this.client
    }

    /**
     * handle login API call
     * 
     * @param String email, password
     * @returns login promise
     */
    login(email, password) {
        return this.init().post('/login', {
            email: email,
            password: password
        })
        .catch(err => new Promise(function (resolve) {
            // call resolve if the method succeeds
            resolve({
                status: err.response.status
            })
        }))
    }

    /**
     * handle register API call
     *
     * @param Object formData
     * @returns register promise
     */
    register(formData) {
        return this.init().post('/register', {
            email: formData.email,
            password: formData.password,
            perusahaan: formData.perusahaan,
            is_organization_exist: formData.is_organization_exist,
            existing_organization_code: formData.existing_organization_code,
            alamat: formData.alamat,
            telp: formData.telp,
            kelurahan: formData.kelurahan,
            kecamantan: formData.kecamantan,
            kota: formData.kota,
            id_kategori: formData.id_kategori,
            id_kabupaten: formData.id_kabupaten,
            provinsi: formData.provinsi,
            id_provinsi: formData.id_provinsi,
            bentuk_organisasi: formData.bentuk_organisasi,
            nama: formData.nama,
            gender: formData.gender,
            sales_offline: formData.sales_offline
        })
    }

    forgotPassword = (email, token = null) => {
        // send email to customer
        return this.init().post('/forgot-password', {
            email: email,
            token: token
        })
    }

    confirmForgotPasswordCode = (token, code) => {
        return this.init().post('/reset-password/confirm-code', {
            code: code,
            token: token
        })
    }

    changePassword = (token, password) => {
        return this.init().post('/reset-password/change-password', {
            password: password,
            token: token
        })
    }

    /**
     * get the user data from the session storage
     *
     * @returns user Object
     */
    getUser = () => {
        const userStr = localStorage.getItem('user')
        if (userStr) return JSON.parse(userStr)
        else return null
    }

    /**
     * get all province
     *
     * @returns province promise
     */
    getProvince = () => {
        return axios
            .get('https://web.firecek.com/api/provinsi')
    }

    /**
     * get all city
     *
     * @returns city promise
     */
    getAllCity = () => {
        return axios
            .get('https://web.firecek.com/api/kota')
    }

    /**
     * get respective city according to province ID
     *
     * @param Integer provinceId
     * @returns city promise
     */
    getCity = (provId) => {
        return axios
            .get('https://web.firecek.com/api/kota/' + provId)
    }

    // return the token from the session storage
    getToken = () => {
        return localStorage.getItem('token') || null
    }

    /**
     * remove the token and user from the session storage
     * this function used to help logout process
     *
     * @returns void
     */
    removeUserSession = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }

    /**
     * set the token and user from the session storage
     * this function used to help login process by storing the API TOKEN
     *
     * @param String token, user
     * @returns user and token Object
     */
    setUserSession = (token, user) => {
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))

        return {
            token: token,
            user: JSON.stringify(user)
        }
    }

    verifyEmailavailability = (email) => {
        return axios
            .get(this.endPoint + 'verify/email', {
                    params : {
                    'email': email
                }
            })
    }

    varifyTokenValidity = (token) => {
        return axios
            .get(this.endPoint + 'verify/token', {
                params: {
                    'token': token
                }
            })
    }

    getKategoriCustomer = () => {
        return axios
            .get(this.endPoint + 'kategori-customer')
    }
}