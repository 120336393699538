import { Component } from 'react';
import Navbar from '../../component/navbar/navbar';
import { withRouter } from 'react-router-dom';
import Auth from '../../app/utils/auth';

class Logout extends Component {

    componentDidMount() {
        const api = new Auth();
        api
            .removeUserSession()
        this.props.history.push('/pv')
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <br />
                    Logging you out ...
                </div>
            </div>
        )
    }
}

export default withRouter(Logout);