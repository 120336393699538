import { Component } from 'react';
import { Link } from 'react-router-dom';

class Button extends Component {
    render() {
        let style = {
            width: '100%', 
            height: '100%',
            ...this.props.style
        };
        
        return (
            <div>
                <Link to={this.props.to} className={"btn btn-light " + this.props.className} style={style}>
                    {this.props.title}
                </Link>
            </div>
        )
    }
}

export default Button;