/**
 * only allow developer
 * 
 * @returns Object
 * example : {
 *     validity: true
 * }
 */

async function OnlyDeveloperMiddleware() {
    const userJson = localStorage.getItem('user')
    if (userJson === null) return { validity: false }

    const user = JSON.parse(userJson)
    const role = user.level
    const isDeveloper = role == 1

    if (isDeveloper) return { validity: true }
    return { validity: false }
}

export default OnlyDeveloperMiddleware