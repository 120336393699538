import axios from 'axios'
import endPoint from '../config/end_point';

export default class Inquiry {

    constructor() {
        this.endPoint = endPoint
        this.client = null;
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        })

        return this.client
    }

    getAsalInquiry = () => {
        return axios
            .get(this.endPoint + 'get-asal-inquiry')
    }

    getAsalInquiryDetail = (asalId) => {
        return axios
            .get(this.endPoint + 'get-asal-inquiry-detail/' + asalId)
    }
}