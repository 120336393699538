import Auth from '../../../../app/utils/auth';
import { Row, Col, Avatar, Card } from 'antd';
import ButtonLink from '../../../button';
import FormPublic from '../../../../app/utils/formPublic';
import SubmissionHistory from '../submissionHistory';
import BromindoImage from '../../../../resources/bromindo.jpeg';
import useQuery from '../../../../public/final/main/hooks/useQuery';
import BmmAlarm from '../../../../resources/bmm-alarm.jpeg'
import BmmExtinguisher from '../../../../resources/bmm-extinguisher.jpeg';
import BmmHydrant from '../../../../resources/bmm-hydrant.jpg';
import { useEffect } from 'react';

function Bromindo1() {
    const auth = new Auth();
    const formPublic = new FormPublic();
    const { Meta } = Card;
    const fontFamily = "'ProductSansRegular', Courier, monospace";
    const param = useQuery();
    const source = param.get('source');

    useEffect(() => {
        if (source != null) formPublic.recordInquirySource('https://final.bromindo.com', source)
    }, [])

    const loginOrSignupMessage = () => {
        return <div style={{ color: 'rgb(218 218 218)' }}>
            <br />
            <p style={{ color: 'rgb(218 218 218)', fontSize: '15pt' }}>
                Silahkan login apabila sudah punya akun FINAL atau daftar apabila belum punya akun
            </p>
            <br />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: '5px', paddingTop: '10px' }}>
                    <ButtonLink title="Login" to={"/login" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ backgroundColor: 'rgb(237 147 64)', fontWeight: 'bold', border: '1px solid rgb(237 147 64)' }} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                    <ButtonLink title="Daftar" to={"/register" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ fontWeight: 'bold', color: "white", backgroundColor: "transparent", border: '1px solid white' }} />
                </Col>
            </Row>
        </div>
    }

    const suggestionOption = () => {
        return (
            <div>
                <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px' }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                <h3 style={{ color: '#124265', fontFamily: fontFamily }}>
                                    Belanja Produk
                                </h3>
                                <p style={{ color: 'rgb(154 154 154)' }}>
                                    Kalau kamu butuh belanja produk silahkan pilih kategori produk yang kamu butuhkan
                                </p>
                                <Row>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE HYDRANT" src={BmmHydrant} />}
                                            onClick={() => {
                                                const link = 'https://www.bromindo.com/distributor/smg/hydrant-smg/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE HYDRANT" description="https://www.bromindo.com/distributor/smg/hydrant-smg/" />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE ALARM" src={BmmAlarm} />}
                                            onClick={() => {
                                                const link = 'https://www.bromindo.com/distributor/smg/alarm-smg/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE ALARM" description="https://www.bromindo.com/distributor/smg/alarm-smg/" />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE EXTINGUISHER" src={BmmExtinguisher} />}
                                            onClick={() => {
                                                const link = 'https://www.bromindo.com/distributor/smg/apar-smg/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE EXTINGUISHER" description="https://www.bromindo.com/distributor/smg/apar-smg/" />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ backgroundColor: "#FB6600" }}>
                    <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px', textAlign: "center" }}>
                        <Row justify="center" align="middle">
                            <Col xxl={15} xl={20} lg={20} md={20} sm={20} xs={20}>
                                <h4 style={{ padding: '20px 20px 0px 0px', marginBottom: "40px", color: "white", fontFamily: fontFamily }}>Kalau kamu mau belanja produk dan butuh penawaran harga dari kami, silahkan klik Lanjut</h4>
                                <ButtonLink title="Lanjut" to={"/next" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ width: '200px', color: "white", backgroundColor: 'transparent', fontWeight: 'bold' }} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container" style={{ paddingTop: '50px' }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <SubmissionHistory style={{ marginBottom: '120px' }} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div style={{ backgroundColor: "#fff", paddingBottom: '120px' }}>
                <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px', height: "100vh" }}>
                    <img alt="" src="https://www.bromindo.com/wp-content/uploads/2018/02/logo.png" width="120px" />
                    <Row justify="center" align="middle" style={{ height: "100%" }}>
                        <Col xs={0} sm={0} md={0} lg={12} xl={12} justify="center" align="middle">
                            <Avatar src={BromindoImage} className="" style={{ width: "500px", height: "500px" }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <h1 style={{ color: '#212934', marginTop: '20px', fontFamily: fontFamily }}>Terima kasih sudah menghubungi kami 😊</h1>
                            <p style={{ color: '#212934', fontSize: '15pt' }}>
                                Ayo bantu Bromindo untuk memberikan penawaran terbaik untuk Kamu sesuai dengan kebutuhanmu dengan cepat & akurat
                            </p>

                            {
                                auth.getToken() === null
                                    ? loginOrSignupMessage()
                                    : ''
                            }
                        </Col>
                    </Row>
                </div>
            </div>
            {
                auth.getToken() !== null
                 ? suggestionOption()
                 : ''
            }
        </div>
    )
}

export default Bromindo1;