import parse from 'html-react-parser';
import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Select } from 'antd';
import 'antd/dist/antd.css';
import './style.css';
import FormPublic from '../../../../app/utils/formPublic';

function SalesAreaComponent(props) {
    
    const [valueSetted, setValueSetted] = useState(false);
    const [alert, setAlert] = useState('');

    const [provinceList, setProvinceList] = useState([]);
    const [selectedProvId, setSelectedProvinceId] = useState();
    const [selectedProvName, setSelectedProvinceName] = useState();
    const [cityList, setCityList] = useState([]);
    const [selectedCityName, setSelectedCityName] = useState('');
    const [selectedCityId, setSelectedCityId] = useState('');

    const dropdownStyle = {
        border: '0px',
        outline: '0px',
        backgroundColor: 'transparent',
        width: '90%',
        borderBottom: '2px solid black'
    };

    const { Option } = Select;
    const formPublic = new FormPublic();

    const prevValueFromBackExist = useCallback(() => {
        return !valueSetted && props.value !== null && props.value !== undefined && props.value !== "" && Object.keys(props.value).length > 0 && props.value.input_value !== '""';
    }, [valueSetted, props.value]);

    const keyListener = useCallback(
        () => {
            if (props.data.required === 1) {
                if (selectedCityId !== "") {
                    setAlert('');
                    const newAnswer = {
                        'province': {
                            'id': selectedProvId,
                            'name': selectedProvName
                        },
                        'city': {
                            'id': selectedCityId,
                            'name': selectedCityName
                        }
                    }
                    // console.log('[salesAreaComponent.js] newAnswer 1', newAnswer);

                    props.action(props.data.id, props.data.next_input_id, props.draftId, JSON.stringify(newAnswer), props.data.score);
                } else {
                    setAlert('Mohon isi bidang input');
                }
            } else {
                let newAnswer = '';
                if (selectedCityId !== "") {
                    newAnswer = {
                        'province': {
                            'id': selectedProvId,
                            'name': selectedProvName
                        },
                        'city': {
                            'id': selectedCityId,
                            'name': selectedCityName
                        }
                    }
                }
                // console.log('[salesAreaComponent.js] selectedCityId', selectedCityId );
                // console.log('[salesAreaComponent.js] newAnswer 2', newAnswer);

                props.action(props.data.id, props.data.next_input_id, props.draftId, JSON.stringify(newAnswer), props.data.score);
            }
        }, [props.draftId, props, props.data.id, props.data.next_input_id, props.data.score, selectedCityId, selectedCityName]
    )

    useEffect(() => {
        // console.log('[salesAreaComponent.js] reload', props);
        // [first load] load province list
        formPublic
            .getProvince()
            .then(res => {
                setProvinceList(res.data.map(
                    i => <Option key={i.id} value={i.name}>{i.name}</Option>
                ))
            })

        // if city value exist, set city list
        if (prevValueFromBackExist()) {
            let decodeLocationRawJson = JSON.parse(props.value.input_value);
            const provID = decodeLocationRawJson.province.id;

            formPublic
                .getCity(provID)
                .then(response => {
                    setCityList(response.data.map(
                        i => <Option key={i.id} value={i.name}>{i.name}</Option>
                    ))
                });
        }
    }, [])

    useEffect(() => {
        // if is currrent is true the the state is latest
        let isCurrent = true;

        // set prev value if exist
        if (prevValueFromBackExist()) {
            // decode location JSON raw value
            let decodeLocationRawJson = JSON.parse(props.value.input_value);
            // console.log('[salesAreaComponent.js] decodeLocationRawJson', decodeLocationRawJson);
            
            // setSelectedLocationObj(decodeLocationRawJson.value_object);
            // setValue(props.value);

            setSelectedCityId(decodeLocationRawJson.city.id);
            setSelectedCityName(decodeLocationRawJson.city.name);
            setSelectedProvinceId(decodeLocationRawJson.province.id);
            setSelectedProvinceName(decodeLocationRawJson.province.name);

            setValueSetted(true);
        }

        // listen keyboard
        document.addEventListener('keydown', (e) => {
            if (e.key !== undefined) {
                let pressedKey = e.key.toUpperCase();
    
                // check if enter btn pressed and the state value is latest state
                if (pressedKey === 'ENTER' && isCurrent) {
                    keyListener();
                }
            }
        }, false);

        // clean up function
        return () => {
            isCurrent = false;
            document.removeEventListener('keydown', this);
        }
    }, [keyListener, props.data, selectedCityId, selectedProvId, selectedProvName, selectedCityName ])

    return (
        <div>
            <Row justify="center" align="middle" style={{ paddingTop: '50px', paddingBottom: '50px',minHeight:'100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>{props.data !== undefined ? parse(props.data.question) : ''}</h1>

                    {/* display required status */}
                    { props.data !== undefined && props.data.required ? <span style={{ fontSize: '10pt' }}>* harus diisi</span> : ''}

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ paddingBottom: '16px' }}>
                            <Select defaultValue="" value={selectedProvName} onChange={(val, opt) => {
                                // get respective city under the selected province if only the selected province is difference
                                if (val === '') return;

                                if (selectedProvId !== opt.key) {
                                    setSelectedProvinceName(val);
                                    setSelectedProvinceId(opt.key);

                                    formPublic
                                        .getCity(opt.key)
                                        .then(response => {
                                            setCityList(response.data.map(
                                                i => <Option key={i.id} value={i.name}>{i.name}</Option>
                                            ))

                                            setSelectedCityId(response.data[0].id);
                                            setSelectedCityName(response.data[0].name);
                                        });
                                }
                            }} style={dropdownStyle}>
                                <Option value="">PILIH PROVINSI</Option>
                                {provinceList}
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ paddingBottom: '16px' }}>
                            <Select defaultValue="" value={selectedCityName} onChange={(val, opt) => {
                                if (val === '') return;
                                
                                setSelectedCityId(opt.key);
                                setSelectedCityName(val);
                            }} style={dropdownStyle}>
                                <Option value="">PILIH KOTA</Option>
                                {cityList}
                            </Select>
                        </Col>
                    </Row>

                    {/* alert */}
                    <span style={{ color: 'red'}}>{alert}</span>

                    <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                        <Button className="fancy-shadow" onClick={() => keyListener()}>OK</Button> 
                        <span style={{ fontSize:'10pt', marginLeft:'10px' }}>tekan <strong>Enter ↵</strong></span>
                    </div>
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}

export default SalesAreaComponent;