import { Col, Row } from "antd"
import PMSIllustration from '../../resources/Final_pms.jpg'

const containerStyle = {
    width: "100%",
    height: "100%",
    paddingTop: "0px",
    textAlign: "center"
}

const inputStyle = {
    border: "none",
    fontSize: "13pt",
    borderBottom: "3px solid #EBEBEB",
    width: "100%",
    outline: "none",
    color: "#5454548c"
}

const labelStyle = {
    color: "##212934",
    width: "100%",
    textAlign: "left",
    fontWeight: "bold"
}

const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "rgb(34 169 158)",
    color: "white",
    outline: "none",
    border: "0px",
    padding: "10px 50px 10px 50px",
    borderRadius: "5px"
}

const buttonStyleDisabled = {
    fontWeight: "bold",
    backgroundColor: "rgb(34 169 158 / 50%)",
    cursor: "not-allowed",
    color: "white",
    outline: "none",
    border: "0px",
    padding: "10px 50px 10px 50px",
    borderRadius: "5px"
}

const marginTop30px = {
    marginTop: "30px"
}

const marginTop15px = {
    marginTop: "15px"
}

const registerStyle = {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    padding: "30px",
    bottom: "0px"
}

const required = <span style={{ color: "#FB0606" }}>*</span>

const PMSAuthStyle = {
    required: required,
    registerStyle: registerStyle,
    marginTop15px: marginTop15px,
    marginTop30px: marginTop30px,
    containerStyle: containerStyle,
    inputStyle: inputStyle,
    labelStyle: labelStyle,
    buttonStyle: buttonStyle,
    buttonStyleDisabled: buttonStyleDisabled
}

const PMSAuthLayout = (props) => {

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const column1 = <Col xs={24} sm={24} md={12} lg={12} style={vw >= 768 ? { maxHeight: "100vh" } : { height: "auto" }}>
        <Row>
            <Col xs={0} sm={1} md={4} lg={4}></Col>
            <Col xs={24} sm={22} md={16} lg={16}>
                {props.children}
            </Col>
            <Col xs={0} sm={1} md={4} lg={4}></Col>
        </Row>
    </Col>

    const column2 = <Col xs={24} sm={24} md={12} lg={12} style={{ 
        height: vw < 768 ? "auto" : "100vh",
        textAlign: vw < 768 ? "center" : "auto"
    }}>
        <img src={PMSIllustration} alt="" style={{ 
            maxWidth: "80%", 
            position: vw < 768 ? "block" : "absolute", 
            bottom: "0px" 
        }} />
    </Col>

    return <div style={{ backgroundColor: "#B4D9E2", minHeight: "100vh" }}>
        {
            vw < 768
                ? <Row>
                    {column2}
                    {column1}
                </Row>
                : <Row>
                    {column1}
                    {column2}
                </Row>
        }
    </div>
}

const PMSRegisterLayout = (props) => {

    return <div style={{ backgroundColor: "#B4D9E2", minHeight: "100vh" }}>
        <Row>
            <Col xs={0} sm={0} md={4} lg={4}></Col>
            <Col xs={24} sm={24} md={16} lg={16}>
                {props.children}
            </Col>
            <Col xs={0} sm={0} md={4} lg={4}></Col>
        </Row>
    </div>
}

export {
    PMSAuthLayout,
    PMSRegisterLayout,
    PMSAuthStyle
}