import React, { Component } from 'react';
import { Row, Col, Avatar, Divider, Spin, Button } from 'antd';
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import FormPublic from '../../../../app/utils/formPublic';
import 'antd/dist/antd.css';
import company from '../../../../app/config/company_setting';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';

class FinishingComponent extends Component {

    state = {
        openingTitle: '',
        openingImage: '',
        closingMsg: '',
        initialInput: '',
        apiRequestDone: false,
        emailSent: false,
        entryId: 0,
        feedbackShow: true,
        feedbackText: '',
        feedbackLoading: false,
        salesPerson: {}
    }

    componentDidMount() {
        // console.log('[finishingComponent.js] props', this.props.draftId);
        
        const errorMsg = <div>
            <div style={{ fontSize: '20px' }}>
                Sepertinya ada yang salah, mohon coba lagi
            </div>
        </div>

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const kodeCustomer = 'company' in params ? params.company : null;
        const api = new FormPublic();
        api
            .getSalesArea(this.props.draftId, this.props.source, kodeCustomer)
            .then(res => {
                // console.log('[finishingComponent.js] getSalesArea', res);

                let closingMsg = '';
                if (res.data.closing_msg !== '') {
                    const contentState = convertFromRaw(res.data.closing_msg);
                    const editorState = EditorState.createWithContent(contentState);
                    closingMsg = <Editor editorState={editorState} readOnly={true} />;
                }

                this.setState({
                    ...this.state,
                    closingMsg: closingMsg,
                    apiRequestDone: true,
                    salesPerson: { ...res.data.sales },
                    emailSent: res.data.email_sent,
                    entryId: res.data.entry_id
                })
            })
            .catch(err => this.setState({
                ...this.state,
                closingMsg: errorMsg,
                apiRequestDone: true
            }))
    }

    render () {
        let salesInformation = '';
        if ('id_user' in this.state.salesPerson) {
            salesInformation = <div>
                <Divider />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td rowSpan={2} width="40px">
                                <Avatar
                                    src={this.state.salesPerson.avatar_url}
                                    alt="Avatar"
                                />
                            </td>
                            <td>
                                <strong>{this.state.salesPerson.name}</strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={10} xl={8} >
                                        <WhatsAppOutlined /> {this.state.salesPerson.hp_pribadi}
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={14} xl={16} >
                                        <MailOutlined /> {this.state.salesPerson.email}
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }

        const closingMsgStyle = {
            fontSize: '20px'
        }

        const showMessage = <div>
            <div style={closingMsgStyle}>
                {this.state.closingMsg}
            </div>
            {
                this.state.emailSent
                    ? <table style={{ width: "100%", marginBottom: '20px', border: "1px solid rgb(132 147 180)" }}>
                        <thead>
                            <tr>
                                <td style={{ padding: "10px" }}>
                                    <b>Kami sedang memproses inquiry Anda, mohon bersedia menunggu. Penawaran akan kami kirim ke email Anda.</b>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    : ''
            }

            {
                salesInformation !== ''
                    ? <p>Jika ada hal lain yang perlu disampaikan silahkan hubungi sales area kami</p>
                    : ''
            }
            {salesInformation}
        </div>

        const showLoading = <div style={closingMsgStyle}>
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: '50px' }}><Spin /></td>
                        <td>
                            Tunggu sebentar ya, kami sedang memproses permintaan Anda ...
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        const sendFeedback = () => {
            const isTextAreaEmpty = this.state.feedbackText == '' || this.state.feedbackText == null;
            if (isTextAreaEmpty) return;
            this.setState({
                ...this.state,
                feedbackLoading: true
            }, function () {
                formPublic.recordFeedback(this.state.entryId, this.state.feedbackText)
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.setState({
                                ...this.state,
                                feedbackShow: false,
                                feedbackLoading: false
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                feedbackLoading: false
                            })
                        }
                    });
            });
        }

        const formPublic = new FormPublic();
        const feedbackBtnBgColor = company === 'pms' ? '#ffffff26' : 'rgb(224 173 17)';
        const feedback = <div>
            Silahkan berikan kritik & saran agar kami dapat melayani lebih baik.
            <TextArea rows={4} value={this.state.feedbackText} onChange={(e) => this.setState({
                ...this.state,
                feedbackText: e.target.value
            })} style={{ background: feedbackBtnBgColor, borderColor: 'azure', marginTop: '10px', marginBottom: '10px' }} />
            <Button className="btn btn-xs btn-light" onClick={sendFeedback} style={{ background: feedbackBtnBgColor, border: 'none' }} loading={this.state.feedbackLoading}>Kirim Masukan</Button>
            <Divider />
        </div>;

        return (
            <div>
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Col xs={2} sm={2} md={8} lg={8} xl={8} ></Col>
                    <Col xs={20} sm={20} md={8} lg={8} xl={8} >
                        {
                            this.state.apiRequestDone
                                ? showMessage
                                : showLoading
                        }
                        <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                            <Divider />                            
                            {
                                this.state.feedbackShow ? feedback : <div style={{ paddingBottom: '20px' }}>
                                    Terima kasih! Masukkan Anda telah kami rekam 😊
                                </div>
                            }
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Link to='/' className="btn btn-xs btn-light fancy-shadow" style={{ width: '100%', height: '100%' }}>
                                                Kembali
                                            </Link>
                                        </td>
                                        <td>
                                            <span style={{ fontSize: '10pt', marginLeft: '10px' }}>Klik untuk kembali ke <strong>Beranda</strong></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col xs={2} sm={2} md={8} lg={8} xl={8} ></Col>
                </Row>
            </div>
        )
    }
}

export default FinishingComponent;