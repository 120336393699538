import Layout1 from "../../layout/Layout1"
import { Row, Col, Card } from "antd"
import { useHistory } from 'react-router-dom'

const Sales = () => {
    const history = useHistory()

    return <Layout1 title="Final">
        <Row gutter={16}>
            <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ marginTop: "20px" }}>
                <Card
                    hoverable
                    title="Buat Inquiry"
                    bordered={false}
                    style={{ height: "200px" }}
                    onClick={() => history.push('/sales-setup')}
                >
                    Entri inquiry customer untuk kemudian di proses menjadi penawaran Bisma.
                </Card>
            </Col>
            <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ marginTop: "20px" }}>
                <Card
                    hoverable
                    title="Cek Akun Customer"
                    bordered={false}
                    style={{ height: "200px" }}
                    onClick={() => history.push('/check-account')}
                >
                    Bantu customer yang lupa akunnya untuk dapat masuk Final lagi. Lihat akun customer berdasarkan nama / email.
                </Card>
            </Col>
        </Row>
    </Layout1>
}

export default Sales