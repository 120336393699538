import { useEffect } from 'react';
import company from "../../../app/config/company_setting";
import Patigeni2 from './patigeni/patigeni2';
import Bromindo2 from './bromindo/bromindo2';

function LandingPage2() {

    useEffect(() => {
        document.body.classList.remove('body-bg');
    }, [])

    return (
        <div>
            {
                company === 'pms'
                ? <Patigeni2 />
                : <Bromindo2 />
            }
        </div>
    )
}

export default LandingPage2;