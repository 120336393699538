import React, { Component } from 'react';
import Auth from '../../app/utils/auth';
import { withRouter } from "react-router-dom";
import Guard from '../../app/utils/security';

class Login extends Component {
    state = {
        fields: {
            email: '',
            password: ''
        },
        errors: {}
    }

    componentDidMount() {
        document.title = "Final - Login"
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Harus diisi";
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Harus diisi";
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    addSubmit(e) {
        e.preventDefault();

        // console.log(this.state);
        if (this.handleValidation()) {
            const api = new Auth();

            api
                .login(this.state.fields.email, this.state.fields.password)
                .then(response => {
                    // console.log(response.data);
                    api.setUserSession(response.data.user.remember_token, response.data.user);

                    this.props.history.push('/pv/home')
                })
        } else {
            alert("Form has errors.")
        }

    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;

        this.setState({ fields });
    }

    render() {
        const guard = new Guard();
        if (guard.privateLoginGuard() !== undefined) {
            return guard.privateLoginGuard();
        }

        return (
            <div className="container">
                <h1>Login</h1>
                <form name="loginForm" className="form-control" onSubmit={this.addSubmit.bind(this)}>
                    <input type="email" size="30" className="form-control" placeholder="Email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} required />
                    <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
                    <br />
                    <input type="password" size="30" className="form-control" placeholder="Password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} required />
                    <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                    <br />
                    <button type="submit" className="btn btn-md btn-dark">Save</button>
                </form>
            </div>
        )
    }
}

export default withRouter(Login);