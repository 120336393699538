import Layout1 from "../../component/layout/Layout1"
import { Row, Col, Card, Skeleton, Button, Modal, Alert } from "antd"
import DebounceSelect from "../../component/debounce_select"
import { useEffect, useState } from "react"
import Customer from "../../app/service/customer"
import { BMMAuthStyle } from "../../component/layout/BMMAuthLayout"
import User from "../../app/service/user"

const CheckAccount = () => {

    const customer = new Customer()
    const user = new User()

    const [customerInfoField, setCustomerInfoField] = useState({ company: { value: "", label: "" } })
    const [apiCall, setApiCall] = useState(false)
    const [accountInformation, setAccountInformation] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [modalVisibility, setModalVisibility] = useState(false)
    const [password, setPassword] = useState({password: "", confirmation: ""})
    const [successAlert, setSuccessAlert] = useState("")

    async function fetchCustomerList(keyword) {
        return customer.search(keyword)
            .then((body) => {
                return body.data.data.map((customer) => ({
                    label: <div>
                        {customer.nama_customer} <br />
                        {customer.alamat} <br />
                        Email : {customer.email} | PIC : {customer.cp}
                    </div>,
                    value: customer.kode_customer,
                }))
            },
        );
    }

    useEffect(() => {
        if (customerInfoField.company.value.length > 0) {
            setApiCall(true)
            customer.getUser(customerInfoField.company.value)
                .then((res) => {
                    setAccountInformation(res.data.data)
                    setApiCall(false)
                })
        }
    },[customerInfoField.company])

    const modalClose = () => {
        setModalVisibility(false)
    }

    const changePasswordHanlder = () => {
        const isPasswordValid = password.confirmation == password.password && password.password.length >= 8
        const email = accountInformation.length > 0 ? accountInformation[0].email : null
        if (isPasswordValid) {
            // update
            user.updatePassword(password.password, email)
                .then((_) => {
                    modalClose()
                    setPassword({
                        password: "",
                        confirmation: ""
                    })

                    setSuccessAlert("Kata sandi berhasil diubah!")
                    setTimeout(() => {
                        setSuccessAlert("")
                    }, 3000);
                })
        }
    }

    const modalView = (idUser) => {
        setModalVisibility(!modalVisibility)
        setSelectedUser(idUser)
    }

    return <Layout1>

        <Modal 
            title="Ubah Kata Sandi" 
            visible={modalVisibility}
            onOk={changePasswordHanlder}
            onCancel={modalClose}
        >
            <input style={{ ...BMMAuthStyle.inputStyle, marginBottom: '15px' }} type="password" min={8} value={password.password} onChange={(e) => setPassword({...password, password: e.target.value})} placeholder="Kata sandi" />
            <input style={BMMAuthStyle.inputStyle} type="password" min={8} value={password.confirmation} onChange={(e) => setPassword({ ...password, confirmation: e.target.value })} placeholder="Konfirmasi sandi" />
            <Alert
                style={{ 
                    marginTop: "10px",
                    display: password.confirmation != password.password 
                    ? "block"
                    : password.password.length < 8
                        ? "block"
                        : "none"
                }}
                type="error"
                message={
                    password.confirmation != password.password
                    ? "Kata sandi tidak cocok"
                    : password.password.length < 8
                        ? "Minimal 8 karakter"
                        : ""
                }
             />
        </Modal>

        <Card
            title="Cek Akun"
            bordered={false}
        >
            <Row gutter={16}>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: "20px" }}>
                    <h5>Cari Customer</h5>
                    <hr/>
                    <DebounceSelect
                        value={customerInfoField.company}
                        fetchOptions={fetchCustomerList}
                        onChange={newValue => setCustomerInfoField({ ...customerInfoField, company: newValue })}
                        style={{
                            width: '100%',
                        }}
                    />
                </Col>
                <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: "20px" }}>
                    <Alert
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            display: successAlert.length > 0
                                ? "block"
                                : "none"
                        }}
                        type="success"
                        message={successAlert}
                    />
                    <Skeleton active loading={apiCall} />
                    {
                        accountInformation.length == 0 && !apiCall && customerInfoField.company.value.length > 0
                            ? "Akun tidak ditemukan 😔"
                            : ""
                    }

                    <Alert
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            display: accountInformation.length > 0
                                ? "block"
                                : "none"
                        }}
                        type="info"
                        message="Kata sandi tidak dapat ditampilkan. Untuk mendapatkan kata sandi, silahkan reset password"
                    />

                    {
                        accountInformation.map(
                            i => <div key={i.id_user}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Nama</td>
                                            <td style={{ paddingRight: "10px", paddingLeft: "10px" }}>:</td>
                                            <td>{i.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td style={{ paddingRight: "10px", paddingLeft: "10px" }}>:</td>
                                            <td>{i.email}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                                <Button
                                                    type="primary"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={() => modalView(i.id_user)}
                                                >
                                                    Reset Password
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr/>
                            </div>
                        )
                    }
                </Col>
            </Row>
        </Card>
    </Layout1>
}

export default CheckAccount