/**
 * set the main end-point for this app to use in all API request
 *
 * @returns String endPoint
 */

// var endPoint = "http://localhost/bisma/bisma_patigeni/api/";
// var endPoint = "http://localhost/bisma/bisma_bmm/api/";

//var endPoint = "https://bisma.patigeni.com/api/";
var endPoint = "https://bisma.bromindo.com/api/";

// var endPoint = "https://staging.bisma.bromindo.com/api/";
// var endPoint = "https://staging.bisma.patigeni.com/bisma_patigeni/api/";

export default endPoint;