import parse from 'html-react-parser';
import { useEffect, useState, useRef } from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import './style.css';
import useKeyPress from '../hooks/useKeyPress';
import MultiSelectOption from './subComponent/multiSelectOption';
import ContactPerson from '../../../../component/contactPerson';

function MultiSelectOptionComponent(props) {

    const isMounted = useRef(false);

    const enterPressed = useKeyPress('Enter');
    const [errMsg, setErrMsg] = useState({
        multiselect_option: ''
    })

    const onClickHandler = (id) => {
        props.setOption([id]);
    }

    const nextForm = () => {
        if (props.data.required === 1 && 'selectedOpt' in props.userFinal) {

            if (props.userFinal.selectedOpt.length > 0) {
                // mandatory question. User choose one of the option
                // remove error msg if exist
                if (errMsg.multiselect_option !== '') {
                    let newMsg = { ...errMsg };
                    newMsg.multiselect_option = '';

                    setErrMsg(newMsg);
                }

                // generate question tree
                const copyOptList = [...props.userFinal.optionList];
                const getSelectedList = copyOptList.filter(i => props.userFinal.selectedOpt.indexOf(i.id) !== -1);

                let newQuestionTree = [];
                let score = 0;
                let addedDstHistory = [];
                let onFinishedDstMap = null;
                getSelectedList.map((i) => {
                    // generate id for question tree
                    const date = new Date();
                    const newId = parseInt(date.getTime()) + parseInt(Math.floor(Math.random() * 101));

                    // increment score based selected option
                    score += parseInt(i.score);

                    if (addedDstHistory.indexOf(i.next_input_id) === -1) {
                        let liv = 1;
                        let onFinishDstParseInt = parseInt(props.data.on_finished_dst);
                        if (onFinishDstParseInt > 0) {
                            liv = 0;
                        }

                        newQuestionTree.push({
                            id: newId,
                            optId: i.id,
                            inputId: props.data.id,
                            visited: 0,
                            destinationId: i.next_input_id,
                            lastInput: null,
                            lastInputVisited: 1,
                            // lastInput: onFinishDstParseInt,
                            // lastInputVisited: liv,
                            nestedDst: []
                        });

                        let isOnFinishedDstMapEligbileForAdd = onFinishedDstMap === null && onFinishDstParseInt !== null && onFinishDstParseInt > 0;
                        if (isOnFinishedDstMapEligbileForAdd) {
                            onFinishedDstMap = {
                                id: newId * 1000000,
                                optId: i.id,
                                inputId: props.data.id,
                                visited: 0,
                                destinationId: onFinishDstParseInt,
                                lastInput: null,
                                lastInputVisited: 1,
                                nestedDst: []
                            };
                        }
                    }

                    addedDstHistory.push(i.next_input_id);
                });

                if (onFinishedDstMap !== null) {
                    newQuestionTree.push(onFinishedDstMap);
                }

                // console.log('newQuestionTree', newQuestionTree);
                const currInputId = props.data.id;
                const nextInputId = null;
                const draftId = props.draftId;
                const pendingDestination = JSON.stringify(newQuestionTree);
                const answer = JSON.stringify(getSelectedList);

                props.action(currInputId, nextInputId, draftId, answer, score, pendingDestination);
            } else {
                // mandatory question. but user doesn't choose any of the option
                // add error msg
                if (errMsg.multiselect_option === '') {
                    let newMsg = { ...errMsg };
                    newMsg.multiselect_option = '* Mohon pilih minimal 1';

                    setErrMsg(newMsg);
                }
            }
        } else {
            // non mandatory question

            if (props.userFinal.selectedOpt.length > 0) {
                // user choose one/more of the option
                // remove error msg if exist
                if (errMsg.multiselect_option !== '') {
                    let newMsg = { ...errMsg };
                    newMsg.multiselect_option = '';

                    setErrMsg(newMsg);
                }

                // generate question tree
                const copyOptList = [...props.userFinal.optionList];
                const getSelectedList = copyOptList.filter(i => props.userFinal.selectedOpt.indexOf(i.id) !== -1);

                let pendingDst = [];
                let score = 0;
                let addedDstHistory = [];
                let onFinishedDstMap = null;
                getSelectedList.map((i) => {
                    // generate id for question tree
                    const date = new Date();
                    const newId = parseInt(date.getTime()) + parseInt(Math.floor(Math.random() * 101));

                    // increment score based selected option
                    score += parseInt(i.score);

                    // prevent question tree from redundant dst
                    if (addedDstHistory.indexOf(i.next_input_id) === -1) {
                        let liv = 1;
                        let onFinishDstParseInt = parseInt(props.data.on_finished_dst);
                        if (onFinishDstParseInt > 0) {
                            liv = 0;
                        }
                        // pendingDst.push({
                        //     id: newId,
                        //     optId: i.id,
                        //     inputId: props.data.id,
                        //     visited: 0,
                        //     destinationId: i.next_input_id,
                        //     lastInput: parseInt(props.data.on_finished_dst),
                        //     lastInputVisited: liv,
                        //     nestedDst: []
                        // })

                        pendingDst.push({
                            id: newId,
                            optId: i.id,
                            inputId: props.data.id,
                            visited: 0,
                            destinationId: i.next_input_id,
                            lastInput: null,
                            lastInputVisited: 1,
                            // lastInput: onFinishDstParseInt,
                            // lastInputVisited: liv,
                            nestedDst: []
                        });

                        let isOnFinishedDstMapEligbileForAdd = onFinishedDstMap === null && onFinishDstParseInt !== null && onFinishDstParseInt > 0;
                        if (isOnFinishedDstMapEligbileForAdd) {
                            onFinishedDstMap = {
                                id: newId * 1000000,
                                optId: i.id,
                                inputId: props.data.id,
                                visited: 0,
                                destinationId: onFinishDstParseInt,
                                lastInput: null,
                                lastInputVisited: 1,
                                nestedDst: []
                            };
                        }
                    }

                    addedDstHistory.push(i.next_input_id);
                });

                if (onFinishedDstMap !== null) {
                    pendingDst.push(onFinishedDstMap);
                }

                const currInputId = props.data.id;
                const nextInputId = null;
                const draftId = props.draftId;
                const pendingDestination = JSON.stringify(pendingDst);
                const answer = JSON.stringify(getSelectedList);
                // console.log('pendingDestination', pendingDestination);
                // console.log('answer', answer);
                // nextQuestion = (currInputId, nextInputId, draftId, answer, score)
                props.action(currInputId, nextInputId, draftId, answer, score, pendingDestination);
            } else {
                // user doesn't choose any of the option
                // on this case user will heading to ON FINSIHED DST instead
                // console.log('[PENDING] GOTO ON FINISHED DST props.data.on_finished_dst', props.data.on_finished_dst);
                const currInputId = props.data.id;
                const nextInputId = null;
                const draftId = props.draftId;
                props.action(currInputId, nextInputId, draftId, '[]', 0, '[]');
            }
        }

    }

    useEffect(() => {
        // console.log('multiselect props', props);
        isMounted.current = true;

        if (enterPressed && isMounted.current) {
            nextForm();
        }

        const propsDataToJson = { ...props.data };

        // get value from back
        let multiselectValue = [];
        // make sure props.value has input_value_id property
        if (props.value.input_value_id !== '' && props.value.input_value_id !== undefined && 'input_value_id' in props.value) {
            multiselectValue = props.value.input_value_id.split(',').map(elem => parseInt(elem, 10));
        }
        // const multiselectValue = multiselectGetValue.split(',');
        // console.log('multiselectValue', multiselectValue);

        if (isMounted.current) {
            if (Object.keys(props.userFinal) <= 0) {
                const propsDataOptList = { ...JSON.parse(propsDataToJson.option_list) };

                const initialData = {
                    question: propsDataToJson.question,
                    draftId: props.draftId,
                    score: 0,
                    inputId: propsDataToJson.id,
                    selectedOpt: multiselectValue,
                    optionList: propsDataOptList.multiselect_option
                };

                props.setInitialData(initialData);
            } else if (propsDataToJson.id !== props.userFinal.id && propsDataToJson.input_type_id === 2) {
                const propsDataOptList = { ...JSON.parse(propsDataToJson.option_list) };

                const initialData = {
                    question: propsDataToJson.question,
                    draftId: props.draftId,
                    score: 0,
                    inputId: propsDataToJson.id,
                    selectedOpt: multiselectValue,
                    optionList: propsDataOptList.multiselect_option
                };

                props.setInitialData(initialData);
            }
        }

        return () => {
            isMounted.current = false;
            
            props.clearData();
        }
    }, [enterPressed, props.userFinal.question, props.data])
    
    let optionList = [];
    if ('optionList' in props.userFinal) {
        optionList = [];
        for (let i = 0; i < props.userFinal.optionList.length; i++) {
            const el = props.userFinal.optionList[i];
            
            optionList.push(
                <MultiSelectOption 
                    optionAlphabet={String.fromCharCode(65 + i)}
                    key={el.id + props.data.id}
                    key2={el.id + props.data.id}
                    id={el.id}
                    value={el.value}
                    desc={el.desc}
                    action={() => onClickHandler(el.id)}
                    selectedOpt={'selectedOpt' in props.userFinal ? props.userFinal.selectedOpt : []}
                />
            );
        }
    }

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh',paddingTop: '20px' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>{props.userFinal !== undefined && 'question' in props.userFinal ? parse(props.userFinal.question.toString()) : ''}</h1>

                    <span style={{ fontSize: '10pt' }}>* Pilih sebanyak yang Anda suka</span>
                    {
                        props.data.required === 1
                            ? <span style={{ fontSize: '10pt' }}><br />* Wajib memilih</span>
                            : ''
                    }
                    <br />
                    <span style={{ fontSize: '10pt', color: 'red' }}>{errMsg.multiselect_option}</span>

                    <div style={{ fontSize: '13pt', marginTop: '20px' }}>
                        {optionList}
                    </div>

                    <div style={{ fontSize: '13pt', marginTop: '40px', marginBottom: '70px' }}>
                        <Button className="fancy-shadow" onClick={nextForm}>OK</Button>
                        <span style={{ fontSize: '10pt', marginLeft: '10px' }}>tekan <strong>Enter ↵</strong></span>
                    </div>

                    <ContactPerson />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userFinal: state.userFinal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialData: option => dispatch({
            type: 'USER-FINAL.MULTI-SELECT.SET-INITIAL-DATA', payload: {
                data: option
            }
        }),
        setOption: option => dispatch({
            type: 'USER-FINAL.MULTI-SELECT.CHOOSE-OPT', payload: {
                selectedOpt: option
            }
        }),
        clearData: () => dispatch({
            type: 'USER-FINAL.MULTI-SELECT.CLEAR-DATA', payload: {}
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectOptionComponent);