import React, { useState, useEffect } from 'react'
import { Handle } from 'react-flow-renderer'
import { connect } from 'react-redux'
import FormApi from '../../app/utils/formPrivate';
import useDebounce from '../../public/final/main/hooks/useDebounce';

const nodeSingleOption = React.memo(function NodeSingleOption(props) {

    const currentElement = props.elements.filter(i => i.id === props.data.id)[0];

    const [isMounted, setIsMounted] = useState(true);
    const [nodeData, setNodeData] = useState({
        label: currentElement.data.label,
        nodes: currentElement.data.options,
        otherOption: currentElement.data.otherOption,
        backgroundColor: currentElement.style.backgroundColor
    });

    const debouncedData = useDebounce(nodeData, 1000);

    useEffect(() => {
        // console.log('[nodeSingleOption.js] props', props);
        // console.log('[nodeSingleOption.js] isChanged', isChanged());
        
        setIsMounted(true);
        if (isMounted) {
            if (debouncedData && isChanged()) {
                changeHandler();
            }
        }

        return () => {
            setIsMounted(false);
        };
    }, [debouncedData])

    // check for change
    const isChanged = () => {
        // check label
        if (currentElement.data.label !== nodeData.label) return true;

        // check other option
        if (currentElement.data.otherOption !== nodeData.otherOption) return true;

        // check background color
        if (currentElement.style.backgroundColor !== nodeData.backgroundColor) return true;

        // check options length
        if (currentElement.data.options.length !== nodeData.nodes.length) return true;

        // check option name, desc, score
        for (let i = 0; i < nodeData.nodes.length; i++) {
            const el = nodeData.nodes[i];
            if (el.name !== currentElement.data.options[i].name) return true;
            if (el.desc !== currentElement.data.options[i].desc) return true;
            if (el.score !== currentElement.data.options[i].score) return true;
        }

        return false;
    }

    const changeHandler = () => {
        const currentNode = {
            id: currentElement.id, // IMMUTABLE
            data: {
                id: currentElement.id, // IMMUTABLE
                required: 1,
                otherOption: nodeData.otherOption,
                label: nodeData.label,
                options: [
                    ...nodeData.nodes
                ],
                type: 'singleSelectOptionNode',
                style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.backgroundColor },
                position: currentElement.position,
                targetPosition: 'left',
            },
            type: 'singleSelectOptionNode',
            style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.backgroundColor },
            position: currentElement.position,
            targetPosition: 'left',
        }

        props.nodeSingleOptionUpdate(currentNode)
        props.updateMessage({
            status: 'start',
            msg: '[SINGLE-OPTION] Memperbarui pertanyaan'
        });

        // update node
        const formApi = new FormApi();
        formApi
            .updateSingleOptionInput(currentNode)
            .then(res => {
                props.updateMessage({
                    status: 'finish-succeed',
                    msg: '[SINGLE-OPTION] Berhasil disimpan'
                });

                props.updateMessage({
                    status: 'finish-hide',
                    msg: ''
                });
            })
            .catch(err => {
                props.updateMessage({
                    status: 'finish-failed',
                    msg: '[SINGLE-OPTION] Gagal'
                });
            })
    }

    let optionIndex = 0

    const optionHandle = nodeData.nodes.map((i) => {
        optionIndex++;
        let handleTopPosition = 230 + (220 / 2) + ((optionIndex - 1) * 220)

        return <Handle
            key={i.id}
            type="source"
            position="right"
            id={i.id.toString()}
            onConnect={(params) => console.log('[nodeSingleOption.js] params',params)}
            style={{
                top: handleTopPosition,
                background: '#555'
            }}
        />
    })

    const copyOptionList = [...nodeData.nodes];
    const optionList = copyOptionList.map((i) => {
        let otherNote = '';
        if (i.id === 'other') {
            otherNote = 'LAINNYA | ';
        }

        return <div key={i.id} style={{ height: '220px' }}>
            <hr style={{ margin: '0px', padding: '0px' }} />
            <span style={{ fontSize: '12px' }}>
                <b>{otherNote} </b>
                Nama pilihan 
            </span>
            <br />
            <input type="text" value={i.name} onChange={(e) => {
                const copyNode = [...nodeData.nodes];
                const updatedNode = copyNode.map(node => {
                    // update node in an immutable way
                    if (node.id === i.id) return Object.assign({}, node, { name: e.target.value});

                    return node
                })
                
                setNodeData({
                    ...nodeData,
                    nodes: updatedNode,
                })
            }} />
            <br />
            <span style={{ fontSize: '12px' }}>Deskripsi </span>
            <br />
            <input type="text" value={i.desc} onChange={(e) => {
                const copyNode = [...nodeData.nodes];
                const updatedNode = copyNode.map(node => {
                    // update node in an immutable way
                    if (node.id === i.id) return Object.assign({}, node, { desc: e.target.value });

                    return node
                })

                setNodeData({
                    ...nodeData,
                    nodes: updatedNode,
                })
            }} />
            <br />
            <span style={{ fontSize: '12px' }}>Skor </span>
            <br />
            <input type="number" value={i.score} onChange={(e) => {
                const copyNode = [...nodeData.nodes];
                const updatedNode = copyNode.map(node => {
                    // update node in an immutable way
                    if (node.id === i.id) return Object.assign({}, node, { score: e.target.value });

                    return node
                })

                setNodeData({
                    ...nodeData,
                    nodes: updatedNode,
                })
            }} />
            <br />
            {
                i.id !== 'other'
                ? <button className="btn btn-xs btn-default" onClick={()=> {
                        const copyNode = [...nodeData.nodes];
                        const updatedNode = copyNode.filter(item => item.id !== i.id)
        
                        setNodeData({
                            ...nodeData,
                            nodes: updatedNode
                        })
                    }}>Hapus Pilihan</button>
                : ''
            }
        </div>
    })

    const addOption = () => {
        let newId = 1;
        const newOptions = [...currentElement.data.options];
        if (newOptions.length > 0) {
            let lastNode = newOptions[(newOptions.length - 1)]
            newId = lastNode.id + 1
        }

        const emptyOption = {
            id: (newId),
            name: '',
            desc: '',
            dst: 0,
            score: 0
        }

        const concatOpt = [...currentElement.data.options, emptyOption];
        setNodeData({
            ...nodeData,
            nodes: concatOpt
        })
    }

    return (
        <div style={{ backgroundColor: nodeData.backgroundColor, margin: '-10px', padding: '10px' }}>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('[nodeSingleOption.js] params', params)}
            />
            <div style={{ height: '230px' }}>
                <input
                    style={{ marginRight:'10px' }}
                    type="color"
                    onChange={(event) => {
                        let updateColor = {
                            ... nodeData,
                            backgroundColor: event.target.value
                        }

                        setNodeData(updateColor);
                    }}
                    defaultValue={nodeData.backgroundColor}
                />
                Single select option 
                <hr />
                <b>Pertanyaan :</b> <br />
                <input type="text" value={nodeData.label} onChange={(e) => {
                    let copyData = {
                        ...nodeData,
                        label: e.target.value
                    };

                    setNodeData(copyData);
                }} />
                <hr />
                <b>Opsi lainnya :</b>
                <input type="checkbox" style={{ marginLeft: '10px' }} onChange={(e) => {
                    let newNodes = [...nodeData.nodes];
                    
                    // update other option
                    if (!nodeData.otherOption) {
                        // add new other option node if it doesn't exist yet
                        if (nodeData.nodes.filter(i => i.id === 'other').length <= 0) {
                            newNodes.push({
                                id: 'other',
                                name: 'Lainnya',
                                desc: '',
                                dst: 0,
                                score: 0
                            });
                        }
                    } else if (nodeData.otherOption && nodeData.nodes.filter(i => i.id === 'other').length > 0) {
                        // remove other input if exist
                        newNodes = [...nodeData.nodes.filter(i => i.id !== 'other')];
                    }

                    setNodeData({
                        ...nodeData,
                        label: nodeData.label,
                        nodes: newNodes,
                        otherOption: !(nodeData.otherOption)
                    })
                }} checked={nodeData.otherOption} />
                <hr />
                <b>Pilihan :</b>
            </div>
            {optionList}
            <hr />
            <button className="btn btn-xs btn-default" onClick={addOption}>+ Tambah Pilihan</button>
            {optionHandle}
        </div>
    )
})

const mapStateToProps = state => {
    return {
        elements: state.elements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nodeSingleOptionUpdate: params => dispatch({
            type: 'NODE-SINGLE-OPTION.UPDATE', payload: {
                node: params
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(nodeSingleOption);