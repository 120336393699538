import React, { Fragment, useState, useEffect, useCallback } from 'react';
import ReactFlow, {
    removeElements,
    Background,
    Controls,
    MiniMap
} from 'react-flow-renderer';
import { connect } from 'react-redux';

import SingleOption from './nodeSingleOption';
import EmailNode from './nodeEmail';
import TextNode from './nodeText';
import NumberNode from './nodeNumber';
import DateNode from './nodeDate';
import TimeNode from './nodeTime';
import InformationNode from './nodeInformation';
import FileNode from './nodeFile';
import SalesAreaNode from './nodeSalesArea';
import SalesAreaInfoNode from './nodeSalesAreaInfo';
import CompanyTypeNode from './nodeCompanyType';
import MultiOption from './nodeMultiOption';
import ItemQty from './nodeItemQty';

import FormApi from '../../app/utils/formPrivate';

import InitialSingleSelectOption from './initialNode/nodeSingleSelectOption';
import InitialEmail from './initialNode/nodeEmail';
import InitialText from './initialNode/nodeText';
import InitialNumber from './initialNode/nodeNumber';
import InitialDate from './initialNode/nodeDate';
import InitialTime from './initialNode/nodeTime';
import InitialInformation from './initialNode/nodeInformation';
import InitialFile from './initialNode/nodeFile';
import InitialSalesArea from './initialNode/nodeSalesArea';
import InitialSalesAreaInfo from './initialNode/nodeSalesAreaInfo';
import InitialCompanyType from './initialNode/nodeCompanyType';
import InitialMultiSelectOption from './initialNode/nodeMultiSelectOption';
import InitialItemQty from './initialNode/nodeItemQty';
import { message } from 'antd';

const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
}

const nodeTypes = {
    singleSelectOptionNode: SingleOption,
    emailNode: EmailNode,
    textNode: TextNode,
    numberNode: NumberNode,
    dateNode: DateNode,
    timeNode: TimeNode,
    informationNode: InformationNode,
    fileNode: FileNode,
    salesAreaNode: SalesAreaNode,
    salesAreaInfoNode: SalesAreaInfoNode,
    companyTypeNode: CompanyTypeNode,
    multiSelectOptionNode: MultiOption,
    itemQtyNode: ItemQty
}

const FormFlowDesigner = (props) => {

    const [formId] = useState(props.formId)
    const [elements, setElements] = useState([])
    const [selectedType, setSelectedType] = useState("")

    // store queued node
    const [willDelete, setWillDelete] = useState()

    // store axis where should the node be displayed
    const [nodeCoordinate, setNodeCoordinate] = useState({ x: 650, y: 100 })
    // store message modal key
    const showMessageKey = "show-message-final";

    useEffect(() => {
        // get form node
        const formApi = new FormApi();
        formApi
            .getElements(formId)
            .then(response => {
                const el = response.data.data.elements

                // console.log('useEffect')
                // console.log(response.data.data.elements)

                if (el !== null) {
                    props.generalSetElements(el)
                    // console.log('props.elements', props.elements);
                }
            })
    }, [])

    useEffect(() => {
        // console.log('[formFlowDesigner.js] props',props)
        if (willDelete !== undefined) {
            // if there's queued node then delete it
            props.generalRemoveElements(willDelete)
            // set back to undefined for prevent infinite loop
            setWillDelete(undefined)
        }

        // show message
        if (props.showMessage !== "" && props.showMessage !== undefined) {
            showMessage(props.showMessage);
        }
    }, [elements, formId, props, willDelete])

    const onElementsRemove = useCallback(
        (elementsToRemove) => {
            const formApi = new FormApi();
            if (!("source" in elementsToRemove[0])) {
                props.updateMessage({
                    status: 'start',
                    msg: '[REMOVE-NODE] Menghapus node'
                });

                // this only applies to non edges element
                // also this will delete connected node
                formApi
                    .removeNode(elementsToRemove[0])
                    .then(response => {
                        // console.log(response.data)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[REMOVE-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(res => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[REMOVE-NODE] Gagal!'
                        });
                    })
                
                setWillDelete(elementsToRemove[0])
                setElements((els) => removeElements(elementsToRemove, els))
            } else {
                props.updateMessage({
                    status: 'start',
                    msg: '[REMOVE-EDGE] Menghapus edge'
                });

                // edges
                formApi
                    .removeEges(elementsToRemove[0])
                    .then(response => {
                        // console.log(response.data)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[REMOVE-EDGE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(res => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[REMOVE-EDGE] Gagal!'
                        });
                    })

                props.generalRemoveEdges(elementsToRemove[0])
            }
        },[props]
    )

    const addNode = () => {
        const getDate = new Date()
        const nodeId = getDate.getTime()
        
        props.updateMessage({
            status: 'start',
            msg: '[ADD-NODE] Menambah node'
        });

        const formApi = new FormApi();
        if (selectedType === 'singleSelectOptionNode') {
            // proper way to update obj
            let newNode = {
                ...InitialSingleSelectOption,
                position: { ...nodeCoordinate },
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                id: nodeId.toString(),
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    options: [],
                    type: 'singleSelectOptionNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left',
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeSingleOptionInput(
                        formId,
                        newNode
                    )
                    .then(response => {
                        // console.log(response.data)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'emailNode') {
            // proper way to update obj
            let newNode = {
                ...InitialEmail,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'emailNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left',
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeEmailQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'textNode') {
            // proper way to update obj
            let newNode = {
                ...InitialText,

                id: nodeId.toString(),
                position: { ...nodeCoordinate },
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'textNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeTextQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'numberNode') {
            // proper way to update obj
            let newNode = {
                ...InitialNumber,

                id: nodeId.toString(),
                position: { ...nodeCoordinate },
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'numberNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left',
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeNumberQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'dateNode') {
            // proper way to update obj
            let newNode = {
                ...InitialDate,

                id: nodeId.toString(),
                position: { ...nodeCoordinate },
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'dateNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeDateQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'timeNode') {
            // proper way to update obj
            let newNode = {
                ...InitialTime,

                id: nodeId.toString(),
                position: { ...nodeCoordinate },
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'timeNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeTimeQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'informationNode') {
            // proper way to update obj
            const newNode = {
                ...InitialInformation,
                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    label: '',
                    type: 'informationNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left',
                }
            }
            
            // add redux component
            props.generalAddElements(newNode)

            const formApi = new FormApi();
            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeInformationQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'fileNode') {
            // proper way to update obj
            let newNode = {
                ...InitialFile,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'fileNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeFileQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'salesAreaNode') {
            // proper way to update obj
            let newNode = {
                ...InitialSalesArea,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,

                    // child node only able to access this
                    type: 'salesAreaNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeSalesAreaQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'salesAreaInfoNode') {
            // proper way to update obj
            let newNode = {
                ...InitialSalesAreaInfo,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    ...InitialSalesAreaInfo.data,
                    
                    id: nodeId.toString(),
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate }
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeSalesAreaQuestionInfo(formId, newNode)
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'companyTypeNode') {
            // proper way to update obj
            let newNode = {
                ...InitialCompanyType,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    options: {
                        perusahaan: 0,
                        perseorangan: 0
                    },
                    score: 0,
                    // child node only able to access this
                    type: 'companyTypeNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeCompanyTypeQuestion(
                        formId,
                        newNode
                    )
                    .then(response => {
                        // console.log(response)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'multiSelectOptionNode') {
            // proper way to update obj
            let newNode = {
                ...InitialMultiSelectOption,
                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    onFinishDst: '',
                    options: [],
                    type: 'singleSelectOptionNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left'
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeMultiOptionInput(
                        formId,
                        newNode
                    )
                    .then(response => {
                        // console.log(response)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        } else if (selectedType === 'itemQtyNode') {
            // console.log('itemQtyNode', props.elements)
            // proper way to update obj
            let newNode = {
                ...InitialItemQty,

                id: nodeId.toString(),
                style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                position: { ...nodeCoordinate },
                data: {
                    id: nodeId.toString(),
                    required: false,
                    label: '',
                    score: 0,
                    itemCode: '',

                    // child node only able to access this
                    type: 'itemQtyNode',
                    style: { border: '1px solid #777', padding: 10, backgroundColor: props.lastSelectedColor },
                    position: { ...nodeCoordinate },
                    targetPosition: 'left',
                }
            };

            // add redux component
            props.generalAddElements(newNode)

            const delayDebounceFn = setTimeout(() => {
                // update database
                formApi
                    .storeItemQtyQuestion(formId, newNode)
                    .then(response => {
                        // console.log(response)

                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[ADD-NODE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(err => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[ADD-NODE] Gagal!'
                        });
                    })
            }, 3000);

            return () => clearTimeout(delayDebounceFn)
        }
    }

    const showMessage = (msg) => {
        if (msg.status === 'start') {
            message.loading({ content: msg.msg, key: showMessageKey });
        } else if (msg.status === 'finish-succeed') {
            message.success({ content: msg.msg, key: showMessageKey, duration: 1});
        } else if (msg.status === 'finish-failed') {
            message.error({ content: msg.msg, key: showMessageKey });
        }

        // other finish-hide isn't display
    };

    const onConnect = (params) => {
        const formApi = new FormApi();
        const getTime = new Date().getTime()
        const newEdge = {
            ...params,
            id: getTime.toString()
        }

        // get existing edges
        let edgesList = [{ ...newEdge}];
        for (let i = 0; i < props.elements.length; i++) {
            // console.log('props.elements[i]', props.elements[i])
            if ("source" in props.elements[i]) {
                edgesList.push(props.elements[i])
            }
        }

        // console.log('[formFlowDesigner.js] newEdge vs edgesList', [newEdge, edgesList]);

        props.updateMessage({
            status: 'start',
            msg: '[EDGE-CONNECT] Menghubungkan edge'
        });

        // save to database
        formApi
            .updateEdges(newEdge, formId)
            .then(response => {
                // console.log(response.data)
                props.updateMessage({
                    status: 'finish-succeed',
                    msg: '[EDGE-CONNECT] Berhasil!'
                });

                props.updateMessage({
                    status: 'finish-hide',
                    msg: ''
                });
            })
            .catch(res => {
                props.updateMessage({
                    status: 'finish-error',
                    msg: '[EDGE-CONNECT] Gagal'
                });
            })

        // save to redux
        props.generalAddEdges(newEdge)
    }

    const onNodeDragStop = (event, node) => {
        const updatePosition = props.elements.map(item => {
            if (item.id === node.id) {
                props.updateMessage({
                    status: 'start',
                    msg: '[NODE-MOVE] Memindahkan node!'
                });

                const formApi = new FormApi();
                formApi
                    .updateSingleOptionPosition(node)
                    .then(response => {
                        // console.log(response.data)
                        props.updateMessage({
                            status: 'finish-succeed',
                            msg: '[NODE-MOVE] Berhasil!'
                        });

                        props.updateMessage({
                            status: 'finish-hide',
                            msg: ''
                        });
                    })
                    .catch(res => {
                        props.updateMessage({
                            status: 'finish-failed',
                            msg: '[NODE-MOVE] Gagal!'
                        });
                    });

                const newPosition = {
                    x: node.position.x,
                    y: node.position.y
                }

                setNodeCoordinate({
                    ... newPosition,
                    x: node.position.x + 300
                });
                
                item.position = newPosition
                item.data['position'] = newPosition
            }

            return item
        })
        
        setElements(updatePosition)        
    }
    
    return (
        <Fragment>
            <ReactFlow
                elements={props.elements}
                onLoad={onLoad}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                connectionLineType="bezier"
                snapToGrid={true}
                onNodeDragStop={onNodeDragStop}
                nodeTypes={nodeTypes}
                snapGrid={[50,50]}
                style={{ width: '100%', height: '90vh', background: '#F6F2E7' }}>
                    <Background 
                        color="#888"
                        gap={16} />
                <MiniMap
                    nodeColor="#2AABE2"
                />
                <Controls/>
            </ReactFlow>

            <div>
                <div className="container">
                    <div className="row" style={{ padding: '20px' }}>
                        <div className="col-md-6">
                            <select className="form-control" onChange={e => setSelectedType(e.target.value)} value={selectedType}>
                                <option value="">Pilih ...</option>
                                <option value="singleSelectOptionNode">Single Select Option</option>
                                <option value="multiSelectOptionNode">Multi Select Option</option>
                                <option value="numberNode">Number</option>
                                <option value="emailNode">Email</option>
                                <option value="textNode">Text</option>
                                <option value="informationNode">Information</option>
                                <option value="dateNode">Date</option>
                                <option value="timeNode">Time</option>
                                <option value="fileNode">File</option>
                                <option value="salesAreaNode">Sales Area</option>
                                <option value="salesAreaInfoNode">Sales Area Info</option>
                                <option value="companyTypeNode">Customer Type</option>
                                <option value="itemQtyNode">Item Qty</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <button
                                onClick={addNode}
                                type="button"
                                className="btn btn-md btn-default">
                                Tambah Pertanyaan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        elements: state.elements,
        lastSelectedColor: state.lastSelectedColor,
        showMessage: state.showMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        generalSetElements: els => dispatch({ type: 'GENERAL.SET-ELEMENTS', payload: {
            elements: els
        }}),
        generalAddElements: el => dispatch({ type: 'GENERAL.ADD-ELEMENTS', payload: {
            node: el
        }}),
        generalRemoveElements: el => dispatch({ type: 'GENERAL.REMOVE-ELEMENTS', payload: {
            node: el
        }}),
        generalAddEdges: edges => dispatch({ type: 'GENERAL.ADD-EDGES', payload: {
            edges: edges
        }}),
        generalRemoveEdges: edges => dispatch({
            type: 'GENERAL.REMOVE-EDGES', payload: {
                edges: edges
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFlowDesigner);