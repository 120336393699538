import {
  BrowserRouter as Router
} from "react-router-dom"
import Path from "./app/routes/path"

function App() {
  return (
    <Router>
      <Path />
    </Router>
  )
}

export default App
