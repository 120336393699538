import AuthApi from '../../../../app/utils/auth'
import { BMMAuthLayout, BMMAuthStyle } from "../../../../component/layout/BMMAuthLayout"
import { Row, Col, Alert } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import { useHistory } from "react-router-dom"

const BMMNewPassword = () => {
    
    const [errorMsg, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [apiCallStatus, setApiCallStatus] = useState(false)

    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")

    const auth = new AuthApi()
    const { token } = useParams()
    const query = useQuery()
    const source = query.get('source')
    const history = useHistory()

    useEffect(() => {
        if (password !== passwordConfirmation) setErrorMsg("Password tidak sama")
        else setErrorMsg("")
    })

    const changePassword = (e) => {
        e.preventDefault()
        if (errorMsg !== "") return
        
        setErrorMsg("")
        setSuccessMsg("")

        setApiCallStatus(true)

        auth
            .changePassword(token, password)
            .then(response => {
                setApiCallStatus(false)
                setSuccessMsg(response.data.msg)
                setTimeout(() => {
                    setSuccessMsg("")
                }, 5000);

                const user = response.data.data
                const sourceUrl = source != null ? "?source=" + source : ""

                localStorage.setItem('token', user.remember_token)
                localStorage.setItem('user', JSON.stringify(user))
                history.push('/' + sourceUrl)
            })
            .catch((err) => {
                setApiCallStatus(false)
                setErrorMsg(err)
            })
    }

    return <BMMAuthLayout>
        <div style={BMMAuthStyle.containerStyle}>
            <h1 style={{ fontWeight: "bold" }}>Ubah Password</h1>

            <span style={{ color: "#212934", fontWeight: "500", ...BMMAuthStyle.marginTop30px }}>
                Atur kata sandi baru
            </span>

            <form style={{ ...BMMAuthStyle.marginTop30px }} onSubmit={changePassword} >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <label style={BMMAuthStyle.labelStyle}>
                            Password {BMMAuthStyle.required}
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <input type="password" minLength="8" value={password} onChange={(e) => setPassword(e.target.value)} style={BMMAuthStyle.inputStyle} required />
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <label style={BMMAuthStyle.labelStyle}>
                            Konfirmasi Password {BMMAuthStyle.required}
                        </label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...BMMAuthStyle.marginTop15px }}>
                        <input type="password" minLength="8" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} style={BMMAuthStyle.inputStyle} required />
                    </Col>
                </Row>

                <Alert message={successMsg} type="success" style={{ ...BMMAuthStyle.marginTop15px, display: successMsg == "" ? "none" : "block" }} />
                <Alert message={errorMsg} type="error" style={{ ...BMMAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                <button type="submit"
                    style={{ ...(!apiCallStatus ? BMMAuthStyle.buttonStyle : BMMAuthStyle.buttonStyleDisabled), marginBottom: "0px" }}
                    disabled={apiCallStatus}>
                    Simpan
                </button>
            </form>
        </div>
    </BMMAuthLayout>
}

export default BMMNewPassword