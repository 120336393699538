import 'antd/dist/antd.css'
import { Row, Col, Divider } from 'antd'
import WelcomeBackPMS from './img/welcome-back.svg'
import WelcomeBackBMM from './img/welcome-back-bmm.svg'
import company from '../../app/config/company_setting'
import ButtonLink from '../../component/button'
import ScrollableList from './scrollAbleList'
import { useEffect, useState } from 'react'
import Auth from '../../app/utils/auth'
import FormPublic from '../../app/utils/formPublic'
import { useParams } from 'react-router-dom'
import useQuery from '../final/main/hooks/useQuery'
import Guard from '../../app/utils/security'

function Draft(props) {

    const [draftData, setDraftData] = useState()
    const { id } = useParams()

    const guard = new Guard()
    const query = useQuery()
    const companyParam = query.get('company')
    const sourceParam = query.get('source')
    const kotaAsalParam = query.get('kota')

    useEffect(() => {
        document.body.classList.remove('body-bg')

        if (draftData === undefined) {
            setDraftData(props.data)
        }
    }, [draftData, props.data])

    const deleteAction = (draftId) => {
        const formPublic = new FormPublic()

        formPublic
            .deleteDraft(id, draftId)
            .then(res => {
                // check if there's a draft to load
                if (res.data.data.length === 0) {
                    // no draft to load display the form instead
                    props.onReload()
                } else {
                    // there are draft to load
                    setDraftData(res.data)
                }
            })
    }

    const auth = new Auth()
    const userDetail = auth.getUser()
    let generatedOpeningMsg = ''

    if (userDetail.jenis_kelamin === 'L') {
        generatedOpeningMsg += ' Bapak ' + userDetail.name
    } else {
        generatedOpeningMsg += ' Ibu ' + userDetail.name
    }

    const welcomeBMM = < div style = {
            { backgroundImage: 'linear-gradient(135deg, #667eea 0%, rgb(178 122 234) 100%)', minHeight: '100vh' }
        } >
        <
        div className = "container"
    style = {
            { paddingTop: '50px' }
        } >
        <
        Row justify = "center"
    align = "middle" >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 12 }
    lg = { 12 }
    xl = { 12 }
    style = {
            { padding: '20px' }
        } >
        <
        h1 style = {
            { color: '#fff', fontWeight: '800' }
        } > Selamat datang kembali { generatedOpeningMsg }! < /h1> <
    p style = {
        { fontSize: '20px', color: '#e0e0e0' }
    } > Kami telah menyimpan pekerjaan terakhir Anda, silahkan pilih draft untuk melanjutkan < /p> <
    br / >

        <
        b style = {
            { fontSize: '20px', color: '#e0e0e0' }
        } > Pilih draft: < /b> <
    Divider style = {
        { backgroundColor: 'white' }
    }
    /> <
    ScrollableList style = {
        { backgroundColor: 'transparent' }
    }
    data = { draftData }
    deleteAction = { deleteAction }
    />

    <
    br / >
        <
        p style = {
            { fontSize: '20px', color: '#e0e0e0' }
        } > Atau apabila Anda ingin memulai baru < /p> {
    guard.isFinalUser() ?
        <
        ButtonLink
    title = "Mulai Baru"
    style = {
        {
            textAlign: 'center',
            backgroundColor: "rgb(237, 147, 64)",
            border: 'none',
            fontWeight: 'bold'
        }
    }
    to = { id + '/new' }
    />: < ButtonLink
    title = "Mulai Baru"
    style = {
        {
            textAlign: 'center',
            backgroundColor: "rgb(237, 147, 64)",
            border: 'none',
            fontWeight: 'bold'
        }
    }
    to = {
        id + '/new' + (
            companyParam.length > 0 ?
            '?company=' +
            companyParam : ''
        ) +
        (
            sourceParam.length > 0 ?
            '&source=' + sourceParam :
            ''
        ) +
        (
            kotaAsalParam.length > 0 ?
            '&kota=' + kotaAsalParam :
            ''
        )
    }
    />
} <
/Col> <
Col xs = { 0 }
sm = { 0 }
md = { 12 }
lg = { 12 }
xl = { 12 }
style = {
        { paddingTop: '60px', paddingRight: '60px', paddingBottom: '0', paddingLeft: '60px' }
    } >
    <
    img src = { WelcomeBackBMM }
alt = ""
width = '100%' / >
    <
    /Col> < /
    Row > <
    /div> < /
    div >

    const welcomePMS = < div className = "container"
style = {
        { paddingTop: '50px' }
    } >
    <
    Row justify = "center"
align = "middle" >
    <
    Col xs = { 24 }
sm = { 12 }
md = { 12 }
lg = { 12 }
xl = { 8 }
style = {
        { paddingTop: '60px', paddingRight: '60px', paddingBottom: '0', paddingLeft: '60px' }
    } >
    <
    img src = { WelcomeBackPMS }
alt = ""
width = '100%' / >
    <
    /Col> <
Col xs = { 24 }
sm = { 12 }
md = { 12 }
lg = { 12 }
xl = { 16 }
style = {
        { padding: '20px' }
    } >
    <
    h1 > Selamat datang kembali { generatedOpeningMsg }! < /h1> <
p style = {
    { fontSize: '20px' }
} > Kami telah menyimpan pekerjaan terakhir Anda, silahkan pilih draft untuk melanjutkan < /p> <
br / >

    <
    b style = {
        { fontSize: '20px' }
    } > Pilih draft: < /b> <
Divider / >
    <
    ScrollableList data = { draftData }
deleteAction = { deleteAction }
/>

<
br / >
    <
    p style = {
        { fontSize: '20px' }
    } > Atau apabila Anda ingin memulai baru < /p> {
guard.isFinalUser() ?
    <
    ButtonLink
title = "Mulai Baru"
style = {
    { textAlign: 'center' }
}
to = { id + '/new' }
/>: < ButtonLink
title = "Mulai Baru"
style = {
    { textAlign: 'center' }
}
to = {
    id + '/new' + (
        companyParam.length > 0 ?
        '?company=' +
        companyParam : ''
    ) +
    (
        sourceParam.length > 0 ?
        '&source=' + sourceParam :
        ''
    ) +
    (
        kotaAsalParam.length > 0 ?
        '&kota=' + kotaAsalParam :
        ''
    )
}
/>
} <
/Col> < /
Row > <
    /div>

return company === 'pms' ? welcomePMS : welcomeBMM
}

export default Draft