import {
  Switch
} from "react-router-dom";
import Index from '../../pages';
import Login from '../../pages/pv/login'
import Logout from '../../pages/pv/logout';
import PrivateHome from '../../pages/pv/home'
import CreateForm from '../../pages/pv/create_form';
import SetupForm from '../../pages/pv/setup_form';
import UserLogout from '../../pages/user/logout';
import FinalApp from "../../public/final/main/finalApp";
import Final from "../../public/final/main/final";
import XkeyPress from '../../component/x/index';
import LandingPage2 from "../../component/landing_page/customer/landingPage2";
import UserLogin from "../../public/final/auth/userLogin";
import UserRegister from "../../public/final/auth/userRegister";
import UserForgotPassword from "../../public/final/auth/userForgotPassword";
import UserConfirmPasswordResetCode from "../../public/final/auth/userConfirmPasswordResetCode";
import UserNewPassword from "../../public/final/auth/userNewPassword";
import Go from "../logic/go";
import AuthMiddleware from "../middleware/auth"
import OnlySalesMiddleware from "../middleware/only_sales";
import OnlyDeveloperMiddleware from "../middleware/only_developer";
import SalesSetup from "../../pages/sales_setup/sales_setup"
import CheckAccount from "../../pages/check-account/check_account";

function Path() {

  const authMiddleware = AuthMiddleware()

  return (
    <Switch>

    {/* route for experiment with compoent, this route will be deleted */}
    <Go path="/x/key" component={XkeyPress} />

    {/* 
    private route, only internal user allowed 

    NOTE : /private = /pv = /pv/home
    the reason why there are three routes for one functionality is to manage user access
    */}
    <Go path="/private" component={Login} />
    <Go path="/pv/home" middleware={[OnlyDeveloperMiddleware()]} component={PrivateHome} />
    <Go path="/pv/create-form" middleware={[OnlyDeveloperMiddleware()]} component={CreateForm} />
    <Go path="/pv/setup-form/:id" middleware={[OnlyDeveloperMiddleware()]} component={SetupForm} />
    <Go path="/pv/logout" middleware={[OnlyDeveloperMiddleware()]} component={Logout} />
    <Go path="/pv" middleware={[OnlyDeveloperMiddleware()]} component={Login} />

    <Go path="/unauthorized/:id" component={UserLogin} />
    <Go path="/login" component={UserLogin} />
    <Go path="/register" component={UserRegister} />
    <Go path="/register-off-sales/:id" component={UserRegister} />

    <Go path="/sales-setup" middleware={[OnlySalesMiddleware(), OnlyDeveloperMiddleware()]} strict={false} component={SalesSetup} />
    <Go path="/check-account" middleware={[OnlySalesMiddleware(), OnlyDeveloperMiddleware()]} strict={false} component={CheckAccount} />

    <Go path="/forgot-password" component={UserForgotPassword} />
    <Go path="/:token/confirm" component={UserConfirmPasswordResetCode} />
    <Go path="/:token/new-password" component={UserNewPassword} />
    
    <Go path="/next" component={LandingPage2} />
    <Go path="/user/logout" component={UserLogout} />

    {/* handle draft continuation. if user opt to continue their draft this route will handle */}
    <Go path="/:id/:draftId" component={Final} />
    {/* route for skiping draft, force new entry */}
    <Go path="/:id/new" component={Final} />
    {/* show repective form */}
    <Go path="/:id" component={FinalApp} />

    {/* final main route, display all the form created */}
    <Go path="/" component={Index} />

    </Switch>
  );
}

export default Path;
