import { useEffect, useState } from 'react';
import company from '../../../app/config/company_setting';
import Patigeni1 from './patigeni/patigeni1';
import Bromindo1 from './bromindo/bromindo1';

function LandingPage1() {

    const [openingText, setOpeningText] = useState('');

    useEffect(() => {
        if (company == 'bmm') {
            setOpeningText(<Bromindo1 />)
        } else {
            setOpeningText(<Patigeni1 />)
        }
    }, [])

    return (
        <div style={{ height: 'auto'}}>
            {openingText}
        </div>
    )
}

export default LandingPage1;