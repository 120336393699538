import { Empty, Skeleton } from 'antd';
import { Component } from 'react';
import { Redirect } from 'react-router';
import Auth from '../../app/utils/auth';
import FormApi from '../../app/utils/formPrivate';
import Form from './form';

export default class FormList extends Component {
    state = {
        error: false,
        deleting: false,
        form : []
    }

    // return true if form data exist
    formExist() {
        return this.state.form !== undefined && this.state.form !== null;
    }

    // return true if token doesn't match the database record
    // means session expired, user need to re-login
    isForbidden(response) {
        return response === 403;
    }

    // clear token then redirect to login page
    clearTokenAndRedirectToLogin() {
        const auth = new Auth();

        // remove session
        auth.removeUserSession();

        return <Redirect to="/pv" />;
    }

    loadFormList() {
        const form = new FormApi();
        form
            .getFormList()
            .then(response => {
                if (this.isForbidden(response)) {
                    return this.clearTokenAndRedirectToLogin();
                }

                if (response !== undefined) {
                    this.setState({
                        form: response.data.data
                    });
                } else {
                    this.setState({
                        error: true
                    });
                }
            })
    }


    deleteForm = (id) => {
        this.setState({
            deleting: true
        });

        const form = new FormApi();
        form
            .deleteForm(id)
            .then(response => {
                // console.log(response.data)

                this.setState({
                    deleting: false
                })

                this.loadFormList()
            })
    }

    errorMessage() {
        return <Empty
            style={{ paddingTop: '200px' }}
            description={
                <span>
                    Oops, sepertinya ada yang salah
                </span>
            } />;
    }

    componentDidMount = () => this.loadFormList()

    render() {
        let formList = <Skeleton active />;
        
        if (this.state.error) {
            return this.errorMessage();
        }

        if (this.formExist()) {
            formList = this.state.form.map(i => {
                return <Form key={i.id} id={i.id} name={i.name} desc={i.desc} deleteHandler={this.deleteForm} />;
            });
        }

        return (
            <div className="container">
                {formList}
            </div>
        )
    }
}