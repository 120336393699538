import { useEffect, useState } from "react";
import { Card } from "antd";
import GeneralHelper from "../../../app/utils/generalHelper";
import endPoint from "../../../app/config/end_point";
import Auth from "../../../app/utils/auth";
import FormPublic from "../../../app/utils/formPublic";

function SubmissionHistory(props) {
    
    const formPublic = new FormPublic();
    const [historyData, setHistoryData] = useState([]);
    const generalHelper = new GeneralHelper();
    const auth = new Auth();
    const { Meta } = Card;

    useEffect(() => {
        formPublic.getSubmissionHistory()
            .then((res) => {
                if (res.data != null) setHistoryData(res.data.data);
            });
    }, [])

    if (!auth.getToken()) {
        return (
            <div></div>
        )
    }

    const submissionEmpty = () => {
        return <div>
            Belum ada penawaran
        </div>
    }

    return (
        <div style={props.style}>
            <h4>Histori penawaran</h4>
            <div>
                {
                    historyData.length > 0
                     ? historyData.map((e) => <div key={e.id}>                        
                        <Card
                            hoverable
                            style={{ width: '100%',marginBottom:'10px' }}
                            onClick={() => {
                                const splitEndPoint = endPoint.split('/');
                                let removeApi = [];
                                for (let i = 0; i < splitEndPoint.length; i++) {
                                    const el = splitEndPoint[i];
                                    if (el != 'api') removeApi.push(el);
                                }

                                const mainUrl = removeApi.join('/');
                                const inquiryUrl = mainUrl + 'inquiry-pdf/0?origin_request=final-dashboard&nomor_inquiry=' + e.nomor_inquiry;
                                
                                window.location.href = inquiryUrl;
                            }}
                        >
                            <Meta title={e.nomor_inquiry} description={generalHelper.IDdateFormate(e.created_at)} />
                        </Card>
                    </div>) : submissionEmpty()
                }
            </div>
        </div>
    );
}

export default SubmissionHistory;