import React, {
    Component
} from 'react';
import Navbar from '../../component/navbar/navbar';
import Guard from '../../app/utils/security';
import FormList from '../../component/form/formList';

class PrivateHome extends Component {

    componentDidMount() {
        document.title = "Beranda";
    }

    render() {
        const guard = new Guard();
        if (guard.privateGeneralGuard() !== undefined) {
            return guard.privateGeneralGuard();
        }

        return (
            <div>
                <Navbar />
                <FormList />
            </div>
        )
    }
}

export default PrivateHome;