import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import company from "../../../app/config/company_setting"
import BMMRegister from "./bmm/register"
import PMSRegister from "./pms/register"

const UserRegister = () => {

    const history = useHistory()

    useEffect(() => {
        const token = localStorage.getItem('token')
        const isTokenExist = token != undefined && token != null
        if (isTokenExist) history.push("/")
    }, [])

    return company == 'bmm'
        ? <BMMRegister />
        : <PMSRegister />
}

export default UserRegister