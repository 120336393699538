import { Divider, Select, Spin } from 'antd'
import { useState, useEffect } from 'react'
import useDebounce from '../public/final/main/hooks/useDebounce'
import { PMSAuthStyle } from './layout/PMSAuthLayout'

function DebounceSelectOrganization({ 
    fetchOptions, 
    debounceTimeout = 800, 
    onAddOrganizationClicked, 
    optionOnNotFound, 
    onChange,
    ...props 
}) {
    const [firstTime, setFirstTime] = useState(true)
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const [value, setValue] = useState("")

    const debouncedData = useDebounce(value, 1000)
    useEffect(() => {
        if (debouncedData && value.length > 3) {
            setFetching(true)
            fetchOptions(value).then((newOptions) => {

                setOptions(newOptions)
                setFetching(false)
                setFirstTime(false)
            })
        }
    }, [debouncedData])

    useEffect(() => {        
        if (options.length > 0) {
            for (let i = 0; i < options.length; i++) {
                const element = options[i]
                const elementValue = element.value
                const isOrganizationExist = elementValue.substring(0, 5) === "1504C"
                
                if (!isOrganizationExist) {
                    triggerChange(element)
                }
            }
        }
    }, [options])

    useEffect(() => {
        if (optionOnNotFound.length > 0) {
            for (let i = 0; i < optionOnNotFound.length; i++) {
                const element = optionOnNotFound[i]
                const elementValue = element.value
                const isOrganizationExist = elementValue.substring(0, 5) === "1504C"

                if (!isOrganizationExist) {
                    triggerChange(element)
                }
            }
        }
    }, [optionOnNotFound])

    const triggerChange = (element) => onChange(element)
    
    return (
        <Select
            labelInValue
            filterOption={false}
            showSearch={true}
            onSearch={(val) => setValue(val)}
            onChange={newVal => onChange(newVal)}
            dropdownRender={menu => (
                <div>
                    {menu}
                    {
                        options.length > 0
                            ? <div>
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <div>
                                        Tidak menemukan yang Anda cari? Tambahkan <strong>{value}</strong> ke pilihan
                                    </div>
                                    <button style={{
                                        ...PMSAuthStyle.buttonStyle,
                                        width: "40px",
                                        marginLeft: "10px",
                                        padding: "0px"
                                    }} onClick={() => {
                                        setOptions([
                                            {
                                                label: <div>{value}</div>,
                                                value: value
                                            },
                                            ...options
                                        ])
                                    }} >+</button>
                                </div>
                            </div>
                            : <div></div>
                    }
                </div>
            )}
            notFoundContent={
                fetching 
                    ? <Spin size="small" /> 
                    : value.length <= 3
                        ? <div style={{ color: "grey" }}>
                            💡 Tip : Masukkan nama organisasi / nama Anda<br />
                            Minimal empat karakter untuk mencari
                        </div>
                        : <div>
                            Data tidak ditemukan
                            <hr/>
                            Tambahkan <strong><u>{value}</u></strong>
                            <button style={{ 
                                ...PMSAuthStyle.buttonStyle, 
                                width: "40px",
                                marginLeft: "10px",
                                padding: "0px"
                            }} onClick={() => onAddOrganizationClicked(value)} >+</button>
                        </div>
            }
            {...props}
            options={
                !firstTime && options.length == 0 && optionOnNotFound.length > 0 && value.length > 3
                    ? optionOnNotFound
                    : options
            }
        />
    )
}

export default DebounceSelectOrganization