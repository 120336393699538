import company from "../app/config/company_setting"

const ContactPerson = () => {

    const handleClick = () => {
        if (company == 'pms') {
            // spv number, pms
            window.open('https://wa.me/+628882510888')
        } else {
            // spv number, bmm
            window.open('https://wa.me/+628886577351')
        }
    }

    const style = { 
        marginTop: "40px",
        marginBottom: "100px",
        backgroundColor: "rgb(0 0 0 / 8%)", 
        padding: "10px", 
        cursor: "pointer", 
        maxWidth: "320px",
        height: "90px"
    }

    return <div onClick={handleClick} style={style}>
        <strong>Butuh bantuan?</strong>
        <br />
        Narahubung kami siap membantu Anda
    </div>
}

export default ContactPerson