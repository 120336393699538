import axios from 'axios'
import endPoint from '../config/end_point';
import Auth from '../utils/auth';

export default class User {

    constructor() {
        this.endPoint = endPoint
        this.client = null;

        const api = new Auth()
        const token = api.getToken();
        const idUser = api.getUser().id_user

        this.token = token
        this.idUser = idUser
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        })

        return this.client
    }

    updatePassword = (password, email = null) => {
        return this.init().post('/customer/change-password', {
            id_user: this.idUser,
            token: this.token,
            password: password,
            email: email
        })
    }
}