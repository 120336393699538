/**
 * ONLY internal user allowed to use the API
 * hanlde creation of form :
 * - create new form
 * - add new question
 * - add connection
 * - update form
 */

import Auth from './auth';
import axios from 'axios';
import EndPoint from '../config/end_point';

export default class FormPrivate {
    
    constructor() {
        this.token = null;
        this.endPoint = EndPoint;
        this.client = null;
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.endPoint,
            timeout: 31000
        });

        return this.client;
    }

    getFormList() {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;
    
            return this.init().get('/private/form/list', {
                params: {
                    id_user: idUser,
                    token: token
                }
            })
            .catch(err => {
                return err.response.status;
            });
        }
        
        return this.init().get('/');
    }

    getDetailFormAndInput(id) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;
            
            return this.init().get('/private/form/detail/' + id, {
                params: {
                    id_user: idUser,
                    token: token
                }
            })
            .catch(err => err);
        }

        return this.init().get('/');
    }

    deleteForm(formId) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/delete', {
                id_user: idUser,
                token: token,
                form_id: formId
            });
        }

        return this.init().get('/');
    }

    getQuestions(formId) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().get('/private/form/get_question', {
                params: {
                    id_user: idUser,
                    token: token,
                    form_id: formId
                }
            });
        }

        return this.init().get('/');
    }
    
    getElements(formId) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().get('/private/form/get_elements', {
                params: {
                    id_user: idUser,
                    token: token,
                    form_id: formId
                }
            });
        }

        return this.init().get('/');
    }

    createNewForm(
        name,
        desc,
        openingTitle,
        openingMsg,
        openingImage,
        closingMsg
    ) {
        let formData = new FormData();
        const api = new Auth();
        const token = api.getToken();
        if (token !== null) {
            const idUser = api.getUser().id_user;
    
            formData.append("id_user", idUser);
            formData.append("token", token);
            formData.append("name", name);
            formData.append("desc", desc);
            formData.append("opening_title", openingTitle);
            formData.append("opening_message", openingMsg);
            formData.append("closing_message", closingMsg);
            formData.append("opening_image", openingImage);
    
            return this.init().post('/private/form/store', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .catch(err => err.response.status);
        }

        return this.init().get('/');
    }

    updateFormGeneralInformation(
        formId,
        name,
        desc,
        openingTitle,
        initialInput,
        openingMsg,
        openingImage,
        closingMsg
    ) {
        let formData = new FormData();
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;
    
            formData.append("id_user", idUser);
            formData.append("token", token);
            formData.append("name", name);
            formData.append("desc", desc);
            formData.append("form_id", formId);
            formData.append("initial_input", initialInput);
            formData.append("opening_title", openingTitle);
            formData.append("opening_message", openingMsg);
            formData.append("opening_image", openingImage);
            formData.append("closing_message", closingMsg);
    
            return this.init().post('/private/form/update_header', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        }

        return this.init().get('/');
    }

    updateElements (elements, formId) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;
            // console.log(elements)
            // console.log(formId)

            return this.init().post('/private/form/update_elements', {
                id_user: idUser,
                token: token,
                form_id: formId,
                elements: JSON.stringify(elements)
            });
        }

        return this.init().get('/');
    }

    updateEdges(
        newEdge,
        formId
    ) {
        const api = new Auth();

        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/edges/update', {
                id_user: idUser,
                token: token,
                new_edge: JSON.stringify(newEdge),
                form_id: formId
            });
        }

        return this.init().get('/');
    }

    removeEges(
        edge
    ) {
        const api = new Auth();

        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/edges/remove', {
                id_user: idUser,
                token: token,
                edge: JSON.stringify(edge)
            });
        }

        return this.init().get('/');
    }

    storeSingleOptionInput(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;
            
            // console.log('storeSingleOptionInput')
            // console.log(node)

            const optionList = {
                option: []
            }

            // console.log('idUser : ' + idUser)
            // console.log('token : ' + token)
            return this.init().post('/private/form/single_select/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                option_list: JSON.stringify(optionList),
                elements: JSON.stringify(node),
                question: node.data.label
            });
        }

        return this.init().get('/');
    }

    updateSingleOptionInput(node) {
        // console.log('node.id = ' + node.id)
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            let optionList = {
                option: []
            }

            for (let i = 0; i < node.data.options.length; i++) {
                let newFormattedNode = {
                    id: node.data.options[i].id,
                    value: node.data.options[i].name,
                    score: node.data.options[i].score,
                    next_input_id: node.data.options[i].dst,
                    desc: node.data.options[i].desc,
                }

                optionList.option.push(newFormattedNode)
            }

            return this.init().post('/private/form/single_select/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                option_list: JSON.stringify(optionList),
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeMultiOptionInput(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            const optionList = {
                multiselect_option: []
            }

            return this.init().post('/private/form/multi_select/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                option_list: JSON.stringify(optionList),
                elements: JSON.stringify(node),
                question: node.data.label
            });
        }

        return this.init().get('/');
    }

    updateMultiOptionInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            let optionList = {
                multiselect_option: []
            }

            for (let i = 0; i < node.data.options.length; i++) {
                let newFormattedNode = {
                    id: node.data.options[i].id,
                    value: node.data.options[i].name,
                    score: node.data.options[i].score,
                    next_input_id: node.data.options[i].dst,
                    desc: node.data.options[i].desc,
                }

                optionList.multiselect_option.push(newFormattedNode)
            }

            return this.init().post('/private/form/multi_select/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                on_finish_dst_name: node.data.onFinishDstName,
                required: node.data.required ? 1 : 0,
                option_list: JSON.stringify(optionList),
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    updateSingleOptionPosition = (node) => {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/update_position', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                x: node.position.x,
                y: node.position.y
            });
        }

        return this.init().get('/');
    }

    storeEmailQuestion(
        formId,
        node
    ) {
        // console.log('storeEmailQuestion')
        // console.log(node)
        
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/email/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateEmailInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/email/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeTextQuestion(
        formId,
        node
    ) {
        // console.log('storeTextQuestion')
        // console.log(node)

        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/text/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateTextInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/text/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeNumberQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/number/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateNumberInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/number/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    updateCompanyTypeInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/company_type/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeDateQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/date/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateDateInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/date/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeTimeQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/time/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateTimeInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/time/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeInformationQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/information/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                elements: JSON.stringify(node),
                question: node.data.label
            });
        }

        return this.init().get('/');
    }

    updateInformationInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/information/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeFileQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/file/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateFileInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/file/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeSalesAreaQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/sales_area/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                elements: JSON.stringify(node),
                question: node.data.label
            });
        }

        return this.init().get('/');
    }

    updateSalesAreaInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/sales_area/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                score: node.data.score,
                required: node.data.required,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeSalesAreaQuestionInfo(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/sales_area_info/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                elements: JSON.stringify(node),
                question: node.data.label
            });
        }

        return this.init().get('/');
    }

    updateSalesAreaInputInfo(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/sales_area_info/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                score: node.data.score,
                required: node.data.required,
                question: node.data.label,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeCompanyTypeQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/company_type/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    storeItemQtyQuestion(
        formId,
        node
    ) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user;

            return this.init().post('/private/form/item_code/add', {
                id_user: idUser,
                token: token,
                form_id: formId,
                input_name: node.id,
                required: node.required ? 1 : 0,
                elements: JSON.stringify(node),
                question: node.data.label,
                item_code: node.data.itemCode,
                score: node.data.score
            });
        }

        return this.init().get('/');
    }

    updateItemQtyInput(node) {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/item_code/update', {
                id_user: idUser,
                token: token,
                input_name: node.id,
                required: node.data.required ? 1 : 0,
                score: node.data.score,
                question: node.data.label,
                item_code: node.data.itemCode,
                elements: JSON.stringify(node)
            });
        }

        return this.init().get('/');
    }

    removeNode = (node) => {
        const api = new Auth();
        const token = api.getToken();

        if (token !== null) {
            const idUser = api.getUser().id_user

            return this.init().post('/private/form/remove', {
                id_user: idUser,
                token: token,
                input_name: node.id
            });
        }

        return this.init().get('/');
    }
}