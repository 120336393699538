import { SmileTwoTone } from '@ant-design/icons'
import { Row, Col, Layout, Card } from "antd"
import ButtonLink from "../../component/button"
import Auth from '../../app/utils/auth'
import { useEffect, useState } from 'react'
import company from '../../app/config/company_setting'

const Layout1 = (props) => {

    const { Content } = Layout;
    const auth = new Auth()
    const [userData, setUserData] = useState({
        email: "",
        name: ""
    })

    useEffect(() => {
        const getUserData = auth.getUser()
        setUserData({
            email: getUserData.email,
            name: getUserData.name
        })
    }, [])

    const userBar = <div>
        {
            <Row>
                <Col xs={0} sm={0} md={12} lg={20} xl={20} xxl={20}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width='3%'><SmileTwoTone twoToneColor={company == 'pms' ? 'rgb(0 227 208)' : 'rgb(255 140 63)'} style={{ fontSize: '20pt' }} /></td>
                                <td width='90%' style={{ color: "white" }}>
                                    <b>{userData.name}</b> <br />
                                    <span style={{ fontSize: '8pt' }}>{userData.email}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4} style={{ paddingBottom: '10px' }}>
                    <ButtonLink title="Keluar" to="/user/logout" style={{ marginTop: '10px', border: 'none', color: 'white', fontWeight: "bold", backgroundColor: company == 'pms' ? 'rgb(2 193 177)' : 'rgb(255 140 63)' }} />
                </Col>
            </Row>
        }
    </div>

    return <div>
        <Layout>
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64, minHeight: '100vh' }}>
                <h3>{ props.title }</h3>
                { props.children }
            </Content>
        </Layout>
        <div style={{ maxHeight: '120px', backgroundColor: company == 'pms' ? "rgb(34, 169, 158)" : "#FB6600", width: '100%', position: 'fixed', bottom: '0px', padding: '10px' }}>
            {userBar}
        </div>
    </div>
}

export default Layout1