import 'antd/dist/antd.css'
import GeneralHelper from '../../app/utils/generalHelper'
import ButtonLink from '../../component/button'
import { Card, Button, Modal } from 'antd'
import { EditTwoTone, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import useQuery from '../final/main/hooks/useQuery'
import Guard from '../../app/utils/security'

function ScrollableList(props) {

    const param = useParams()
    const formId = param.id
    const guard = new Guard()

    const query = useQuery()
    const source = query.get('source')
    const sourceUrl = source != null ? "?source=" + source : ""
    const company = query.get('company')
    const companyUrl = company != null ? "&company=" + company : ""

    const confirmModal = (dateCreated, idDraft) => {
        Modal.confirm({
            title: `Konfirmasi`,
            icon: <ExclamationCircleOutlined />,
            content: `Hapus draft tanggal ${dateCreated}?`,
            okText: 'Hapus',
            cancelText: 'Batal',
            onOk: () => props.deleteAction(idDraft)
        })
    }

    const generalHelper = new GeneralHelper()

    return (
        <ul className="scrollmenu" style={props.style}>
            {
                props.data !== undefined
                ? props.data.data.map(i => {
                    return <li key={i.id_draft}>
                        <Card style={{ borderRadius: '20px' }} title={<EditTwoTone style={{ fontSize:'20px' }} />}>
                            <h6>
                                {
                                    !guard.isFinalUser()
                                        ? <div style={{ textAlign: "left" }}>
                                            {i.nama_customer} <br />
                                            {i.alamat} <br /><br />
                                        </div>
                                        : ""
                                }
                                
                                {generalHelper.IDdateFormate(i.created_at)}
                            </h6>

                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <ButtonLink 
                                                title="Lanjutkan" 
                                                style={{ textAlign: 'center' }} 
                                                to={"/" + formId + "/" + i.id_draft + sourceUrl + (
                                                    i.kode_customer !== null && i.kode_customer.length > 0
                                                        ? "&company=" + i.kode_customer
                                                        : ""
                                                )} 
                                            />
                                        </td>
                                        <td>
                                            <Button type="danger" icon={<DeleteOutlined />} onClick={() => confirmModal(generalHelper.IDdateFormate(i.created_at), i.id_draft )} size='30' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                    </li>
                }) : ''
            }
        </ul>
    )
}

export default ScrollableList