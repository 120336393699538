/**
 * verify token validity
 * 
 * @returns Object
 * example : {
 *     validity: true
 * }
 */

import Auth from "../utils/auth"

async function AuthMiddleware() {
    const token = localStorage.getItem('token')
    if (token === null) return {validity: false}

    const auth = new Auth()
    return auth
        .varifyTokenValidity(token)
        .then(res => res.data)
}

export default AuthMiddleware