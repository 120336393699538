import parse from 'html-react-parser';
import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';
import './style.css';

export default function TimeComponent(props) {
    
    const [valueSetted, setValueSetted] = useState(false);
    const [value, setValue] = useState('');
    const [alert, setAlert] = useState('');

    const handleChange = (e) => {
        setAlert('');
        setValue(e.target.value);
    };

    const keyListener = useCallback(
        () => {
            if (props.data.required) {
                if (value.length > 0) {
                    props.action(props.data.id, props.data.next_input_id, props.draftId, value, props.data.score);
                } else {
                    setAlert('Mohon isi bidang input');
                }
            } else {
                props.action(props.data.id, props.data.next_input_id, props.draftId, value, props.data.score);
            }
        }, [props.draftId, props, props.data.id, props.data.next_input_id, props.data.score, value]
    )

    useEffect(() => {
        // if is currrent is true the the state is latest
        let isCurrent = true;

        // set prev value if exist
        // valueSetted prevent prev props.value to appear when user click next
        if (!valueSetted && value === '' && Object.keys(props.value).length > 0 && value !== props.value.input_value) {
            setValue(props.value.input_value ?? '');
            setValueSetted(true);
        }

        // listen keyboard
        document.addEventListener('keydown', (e) => {
            let pressedKey = e.key.toUpperCase();

            // check if enter btn pressed and the state value is latest state
            if (pressedKey === 'ENTER' && isCurrent) {
                keyListener();
            }
        }, false);

        return () => {
            isCurrent = false;
            document.removeEventListener('keydown', this);
        }
    }, [alert, value, keyListener])

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>{props.data !== undefined ? parse(props.data.question) : ''}</h1>

                    {/* display required status */}
                    { props.data !== undefined && props.data.required ? <span style={{ fontSize: '10pt' }}>* harus diisi</span> : ''}

                    <input type="time" className="input-underline" value={value} onChange={handleChange} autoFocus />
                    {/* alert */}
                    <span style={{ color: 'red'}}>{alert}</span>

                    <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                        <Button className="fancy-shadow" onClick={() => keyListener()}>OK</Button> 
                        <span style={{ fontSize:'10pt', marginLeft:'10px' }}>tekan <strong>Enter ↵</strong></span>
                    </div>
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}