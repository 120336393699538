import Draft from '../../draft/draft'
import Final from '../../final/main/final'
import FormPublic from '../../../app/utils/formPublic'
import { Skeleton } from 'antd'
import AuthApi from '../../../app/utils/auth'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Auth from '../../../app/utils/auth'
import useQuery from './hooks/useQuery'
import Guard from '../../../app/utils/security'

function FinalApp() {

    const [draftData, setDraftData] = useState()
    const [salesSetupMode, setSalesSetupMode] = useState(false)

    // params
    const { id } = useParams()
    const params = useParams('id')
    
    // query param
    const query = useQuery()
    const company = query.get('company')
    const source = query.get('source')

    const history = useHistory()
    
    // form public helper API
    const formPublic = new FormPublic()

    // auth helper API
    const authApi = new AuthApi()
    // check authentication status
    const user = authApi.getUser()

    useEffect(() => {
        const guard = new Guard();
        if (!guard.isFinalUser()) {
            const isCompanyAvaliable = company !== null
            const isSourceAvaliable = source !== null

            if (!(isCompanyAvaliable && isSourceAvaliable)) {
                history.push('/sales-setup?id=' + id)
            }
        }
    }, [])

    useEffect(() => {
        // user authenticated
        if (user !== undefined && user !== null) {
            
            // check draft on load
            // redirect to draft if exist
            formPublic
                .getDraft(id)
                .then(res => {
                    if (isForbidden(res)) {
                        // user forbidden, token expired
                        clearTokenAndRedirectToLogin()
                    }

                    if (draftData === undefined) {
                        setDraftData(res.data)
                    }
                })
        } else {
            // user not authenticated
            // redirect to unauthorized page

            clearTokenAndRedirectToLogin()
        }
    }, [draftData])

    // return true if token doesn't match the database record
    // means session expired, user need to re-login
    const isForbidden = (response) => {
        return response === 403
    }

    // clear token then redirect to login page
    const clearTokenAndRedirectToLogin = () => {
        const auth = new Auth()

        // remove session
        auth.removeUserSession()

        const sourceUrl = source != null ? "?source=" + source : ""
        setTimeout(function () {
            history.push('/unauthorized/' + params.id + sourceUrl)
        }, 2000)
    }

    const reloadPage = () => {
        formPublic
            .getDraft(id)
            .then(res => {
                setDraftData(res.data)
            })
    }

    // rendered variable
    const renderedComponent = useCallback(() => {
        let component = <div style={{ padding: '30px' }}><Skeleton active /></div>

        // check draft if exist
        if (draftData !== undefined) {
            if (draftData.data.length > 0) {
                // draft exist
                component = <Draft data={draftData} onReload={reloadPage} />
            } else {
                // draft doesn't exist
                if (user !== undefined && user !== null) {
                    component = <Final />
                }
            }
        }

        return component
    }, [draftData, salesSetupMode])

    return (
        <div>
            { renderedComponent() }
        </div>
    )
}

export default FinalApp