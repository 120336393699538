import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import company from "../../../app/config/company_setting"
import BMMLogin from "./bmm/login"
import PMSLogin from "./pms/login"

const UserLogin = () => {

    const history = useHistory()

    useEffect(() => {
        const token = localStorage.getItem('token')
        const isTokenExist = token != undefined && token != null
        if (isTokenExist) history.push("/")
    }, [])

    return company == 'bmm'
        ? <BMMLogin />
        : <PMSLogin />
}

export default UserLogin