import company from "../../../app/config/company_setting"
import BMMNewPassword from "./bmm/newPassword"
import PMSNewPassword from "./pms/newPassword"

const UserNewPassword = () => {
    return company == 'bmm'
        ? <BMMNewPassword />
        : <PMSNewPassword />
}

export default UserNewPassword