import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Auth from '../../../../app/utils/auth';
import 'antd/dist/antd.css';
import ContactPerson from '../../../../component/contactPerson';

class OpeningComponent extends Component {

    state = {
        openingTitle: '',
        openingImage: '',
        initialInput: ''
    }

    componentDidMount() {
        // set title
        document.title = this.props.data.data.name;

        if (this.state.openingTitle === '') {
            const copyPropsData = { ...this.props.data.data };

            this.setState({
                openingTitle: copyPropsData.opening_title,
                openingImage: copyPropsData.opening_image,
                initialInput: copyPropsData.initial_input
            })
        }
    }

    render () {
        let time = new Date().toLocaleTimeString('id-ID');
        let splitTime = time.split('.');
        let hour = parseInt(splitTime[0]);
        let generatedOpeningMsg = '';

        if (hour >= 0 && hour <= 10) {
            // pagi
            generatedOpeningMsg = 'Selamat pagi';
        } else if (hour > 10 && hour <= 14) {
            // siang
            generatedOpeningMsg = 'Selamat siang';
        } else if (hour > 14 && hour <= 18) {
            // petang
            generatedOpeningMsg = 'Selamat sore';
        } else if (hour > 18 && hour <= 24) {
            // malam
            generatedOpeningMsg = 'Selamat malam';
        }

        const auth = new Auth();
        const userDetail = auth.getUser();
        if (userDetail.jenis_kelamin === 'L') {
            generatedOpeningMsg += ' Bapak ' + userDetail.name + '.';
        } else {
            generatedOpeningMsg += ' Ibu ' + userDetail.name + '.';
        }

        return (
            <div>
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Col xs={2} sm={2} md={8} lg={8} xl={8} ></Col>
                    <Col xs={20} sm={20} md={8} lg={8} xl={8} >
                        <img
                            alt="FINAL"
                            src={this.state.openingImage}
                            width="70%"
                        />
                        <h1>{this.state.openingTitle}</h1>
                        <div style={{ fontSize: '13pt' }}>
                            {generatedOpeningMsg}
                            {this.props.msg}
                        </div>
                        <button
                            className="fancy-shadow"
                            style={{ padding:'5px 20px' }}
                            onClick={() => this.props.action(this.state.initialInput)}
                        >Mulai</button>

                        <ContactPerson />
                    </Col>
                    <Col xs={2} sm={2} md={8} lg={8} xl={8} ></Col>
                </Row>
            </div>
        )
    }
}

export default OpeningComponent;