import { useEffect, useState } from 'react';
import OnlySalesMiddleware from '../app/middleware/only_sales';
import Customer from '../component/landing_page/customer/customer';
import Sales from '../component/landing_page/sales/sales';

const Index = () => {

    const [component, setComponent] = useState('Loading ...')
    
    useEffect(() => {
        OnlySalesMiddleware()
            .then(res => {
                if (res.validity) {
                    setComponent(<Sales />)
                } else {
                    // sales
                    setComponent(<Customer />)
                }
            })
    }, [])
    
    return (
        <div>
            {component}
        </div>
    )
}

export default Index;