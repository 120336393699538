import parse from 'html-react-parser';
import { useEffect, useState, useCallback, useRef } from 'react';
import { Popover, Divider } from 'antd';
import 'antd/dist/antd.css';
import '../style.css';

function MultiSelectOption(props) {

    const isMounted = useRef(false);
    const [popOverVisibility, setPopOverVisibility] = useState(false);
    
    const keyListener = useCallback((e) => {
        if (isMounted.current) {
            let pressedKey = e.key.toUpperCase();
            
            if (props.optionAlphabet !== '' && pressedKey === props.optionAlphabet) {
                // console.log('id, pressedKey, optionAlphabet, key', [props.id, pressedKey, props.optionAlphabet], props.key2);
                return props.action();
            }
        }
    }, [props.optionAlphabet])

    useEffect(() => {
        isMounted.current = true;
        // console.log('value, alphabet', [props.value, props.optionAlphabet]);
        document.addEventListener('keydown', keyListener, false);

        return () => (isMounted.current = false)
    }, [keyListener, props.optionAlphabet])

    // explanation content
    const popOverContent = <div>
        {parse(props.desc)}
        <br />
        <br />
        <Divider />
        <button onClick={() => setPopOverVisibility(!popOverVisibility)}>[Tutup]</button>
    </div>;

    // help button
    const helpBtn = <Popover content={popOverContent} title="Bantuan" visible={popOverVisibility}>
        <span style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => setPopOverVisibility(!popOverVisibility)} className="explanation-btn">Bantuan</span>
    </Popover>;

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <button className={props.selectedOpt.indexOf(props.id) !== -1 ? "btn-option-selected" : "btn-option"} onClick={() => props.action()}>
                                <span className="opt-key" style={{ marginRight: '10px' }}>{props.optionAlphabet}</span>
                                { props.value }
                            </button>
                        </td>
                        <td>
                            {props.desc !== '' ? helpBtn : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MultiSelectOption;