const initialState = {
    elements: [],
    userFinal: {},
    lastSelectedColor: '#FFFFFF',
    showMessage: ''
}

const reducer = (state = initialState, action) => {
    const el = [...state.elements];
    const userFinal = {...state.userFinal};

    // lastSelectedColor will be use on adding new node, so user dont need to set the color 1 by 1
    let lastSelectedColor = state.lastSelectedColor;

    // showMessage will be used on displaying progress
    let showMessage = state.showMessage;

    switch (action.type) {
        case 'GENERAL.SET-ELEMENTS':
            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [
                    ...action.payload.elements
                ]
            }
        case 'GENERAL.ADD-ELEMENTS':
            // concat existing state with new element
            let concatEl = [...el, action.payload.node];

            // update last selected color
            lastSelectedColor = action.payload.node.data.style.backgroundColor;

            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [
                    ...concatEl
                ]
            }
        case 'GENERAL.UPDATE-ELEMENTS':
            // update node data
            el.filter(i => (i.id === action.payload.node.id))[0].data = action.payload.node.data;
            // update node bg color
            el.filter(i => (i.id === action.payload.node.id))[0].style.backgroundColor = action.payload.node.style.backgroundColor;

            // update last selected color
            lastSelectedColor = action.payload.node.data.style.backgroundColor;

            return {
                lastSelectedColor: lastSelectedColor,
                elements: [...el]
            }
        case 'GENERAL.REMOVE-ELEMENTS':
            const removeEl = el.filter(i => i.id !== action.payload.node.id);

            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [...removeEl]
            }
        case 'GENERAL.ADD-EDGES':
            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [...el, { ...action.payload.edges}]
            }
        case 'GENERAL.REMOVE-EDGES':
            const filter = el.filter(i => i.id !== action.payload.edges.id);

            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [...filter]
            }
        case 'NODE-INFORMATION.SET-QUESTION':
            // update question
            el.filter(i => (i.id === action.payload.node.id))[0].data = action.payload.node.data;
            // update node bg color
            el.filter(i => (i.id === action.payload.node.id))[0].style.backgroundColor = action.payload.node.style.backgroundColor;

            // update last selected color
            lastSelectedColor = action.payload.node.data.style.backgroundColor;
            
            return {
                lastSelectedColor: lastSelectedColor,
                elements: [...el]
            }
        case 'NODE-SINGLE-OPTION.UPDATE':
            // update node bg color
            el.filter(i => (i.id === action.payload.node.id))[0].style.backgroundColor = action.payload.node.style.backgroundColor
            var changeBgColorIfUpdated = [
                ...el
            ];
            
            // update node data
            changeBgColorIfUpdated.filter(i => (i.id === action.payload.node.id))[0].data = action.payload.node.data
            const changeNodeData = [
                ...changeBgColorIfUpdated
            ];

            // update last selected color
            lastSelectedColor = action.payload.node.data.style.backgroundColor;
            
            return {
                lastSelectedColor: lastSelectedColor,
                elements: [...changeNodeData]
            }
        case 'NODE-MULTI-OPTION.UPDATE':
            // update
            el.filter(i => (i.id === action.payload.node.id))[0].data = action.payload.node.data;

            // update last selected color
            lastSelectedColor = action.payload.node.data.style.backgroundColor;

            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [...el]
            }
        case 'UPDATE-MESSAGE':
            // update
            showMessage = action.payload.message;

            return {
                showMessage: showMessage,
                lastSelectedColor: lastSelectedColor,
                elements: [...el]
            }

        // user final
        case 'USER-FINAL.MULTI-SELECT.SET-INITIAL-DATA':
            return {
                elements: [],
                userFinal: {
                    ...userFinal,
                    question: action.payload.data.question,
                    draftId: action.payload.data.draftId,
                    score: action.payload.data.score,
                    inputId: action.payload.data.inputId,
                    selectedOpt: action.payload.data.selectedOpt,
                    optionList: [...action.payload.data.optionList]
                }
            }
        case 'USER-FINAL.MULTI-SELECT.CHOOSE-OPT':
            var newSelectedOpt = [];
            if (userFinal.selectedOpt.indexOf(action.payload.selectedOpt[0]) !== -1) {
                // selected option already selected, remove instead
                newSelectedOpt = userFinal.selectedOpt.filter(i => i !== action.payload.selectedOpt[0]);
            } else {
                // selected option isn't exist yet, add new option
                newSelectedOpt = [...userFinal.selectedOpt, ...action.payload.selectedOpt];
            }
            
            return {
                elements: [],
                userFinal: {
                    ...userFinal,
                    selectedOpt: [
                        ...newSelectedOpt
                    ]
                }
            }
        case 'USER-FINAL.MULTI-SELECT.CLEAR-DATA':
            return {
                elements: [],
                userFinal: {
                    ...userFinal,
                    question: '',
                    draftId: '',
                    score: '',
                    inputId: '',
                    selectedOpt: [],
                    optionList: []
                }
            }

        case 'USER-FINAL.SALES-AREA.SET-INITAL-DATA':
            return {
                elements: [],
                userFinal: {}
            }
        case 'USER-FINAL.SALES-AREA.STORE-LOCATION':
            let newObj = { 
                selectedLocation : { ...action.payload.selectedLocation } 
            };
            // console.log('action.payload newObj', newObj);

            return {
                elements: [],
                userFinal: { ...newObj }
            }
            
        default:
            return state;
    }
}

export default reducer;