import company from "../../../app/config/company_setting"
import BMMConfirmPasswordResetCode from "./bmm/confirmPasswordResetCode"
import PMSConfirmPasswordResetCode from "./pms/confirmPasswordResetCode"

const UserConfirmPasswordResetCode = () => {
    return company == "bmm"
        ? <BMMConfirmPasswordResetCode />
        : <PMSConfirmPasswordResetCode />
}

export default UserConfirmPasswordResetCode