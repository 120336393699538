import { Alert, Col, Row, Carousel } from "antd"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import AuthApi from '../../../../app/utils/auth'
import BmmSlider1 from '../../../../resources/bmm-slider-1.jpg'
import BmmSlider2 from '../../../../resources/bmm-slider-2.jpg'
import BmmSlider3 from '../../../../resources/bmm-slider-3.jpg'
import BmmSlider4 from '../../../../resources/bmm-slider-4.jpg'

const BMMLogin = () => {
    
    const [viewportWidth, setViewportWidth] = useState()

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)
    }, [])

    const location = useLocation()
    const [formField, setFormField] = useState({ email: "", password: "" })
    const [errorMsg, setErrorMsg] = useState('')
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const history = useHistory()
    const query = useQuery()
    const source = query.get('source')

    const containerStyle = { 
        width: "100%", 
        height: "100%",
        paddingTop: "30px",
        paddingHeight: "30px",
        textAlign: "center" 
    }

    const inputStyle = {
        border: "none",
        fontSize: "13pt",
        borderBottom: "3px solid #EBEBEB",
        width: "100%",
        outline: "none",
        color: "#5454548c"
    }

    const labelStyle = {
        color: "##212934",
        width: "100%",
        textAlign: "left",
        fontWeight: "bold"
    }

    const buttonStyle = {
        fontWeight: "bold",
        backgroundColor: "rgb(255, 102, 0)",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const buttonStyleDisabled = {
        fontWeight: "bold",
        backgroundColor: "rgb(255 102 0 / 49%)",
        cursor: "not-allowed",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const marginTop30px = {
        marginTop: "30px"
    }

    const marginTop15px = {
        marginTop: "15px"
    }

    const registerStyle = {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        padding: "30px",
        bottom: "0px"
    }

    const required = <span style={{ color: "#FB0606" }}>*</span>

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setApiCallStatus(true);

        const email = formField.email;
        const password = formField.password;

        const auth = new AuthApi();
        auth
            .login(email, password)
            .then(response => {
                setApiCallStatus(false);

                const isLoginSucceed = response.status === 200;

                if (isLoginSucceed) {
                    // save token and id_user
                    const saveTokenAndIdUser = auth.setUserSession(response.data.user.remember_token, response.data.user);

                    if (saveTokenAndIdUser.token !== '') {
                        const splitCurrUrl = window.location.href.split('/');
                        let formId = '';
                        if (splitCurrUrl.length > 0) {
                            const splitQuestionMark = splitCurrUrl[splitCurrUrl.length - 1].split('?');
                            if (splitQuestionMark.length > 0) formId = splitQuestionMark[0];
                        }

                        const sourceUrl = source != null ? "?source=" + source : "";

                        const isLoginPage = location.pathname == '/login';
                        const isRegisterPage = location.pathname == '/register';
                        if (isLoginPage || isRegisterPage) {
                            history.push('/' + sourceUrl);

                            return;
                        }

                        history.push('/' + formId + sourceUrl);
                    }
                } else {
                    setErrorMsg("Login gagal, mohon periksa email / kata sandi Anda");
                }
            })
    }

    const carausel = <Carousel autoplay>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider1} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider2} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider3} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img src={BmmSlider4} width="100%" />
        </div>
    </Carousel>

    return <div style={{ minHeight: "100vh" }}>
        <Row>
            <Col xs={24} sm={24} md={12} lg={12} style={{ backgroundColor: "#DEDBE8", height: viewportWidth < 768 ? "auto" : "100vh" }}>
                {carausel}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Row>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                    <Col xs={16} sm={16} md={16} lg={18}>
                        <div style={containerStyle}>
                            <h1 style={{ fontWeight: "bold" }}>Masuk</h1>

                            <span style={{ color: "#212934", fontWeight: "500", ...marginTop30px }}>
                                Silahkan login/ daftarkan diri/ perusahaan Anda <br />
                                untuk mendapatkan penawaran kilat dari Bromindo
                            </span>

                            <form style={{ ...marginTop30px }} onSubmit={submitHandler}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <label style={labelStyle}>
                                            Email {required}
                                        </label>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <input type="email" style={inputStyle} value={formField.email} onChange={(e) => setFormField({ ...formField, email: e.target.value })} required />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <label style={labelStyle}>
                                            Password {required}
                                        </label>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                        <input type="password" style={inputStyle} value={formField.password} onChange={(e) => setFormField({ ...formField, password: e.target.value })} required />
                                    </Col>
                                </Row>
                                <div style={{ textAlign: "right", ...marginTop30px }}>
                                    <Link to='/forgot-password' style={{ textDecoration: "none", color: "#B7B8B9", fontWeight: 700 }}>
                                        Lupa Password?
                                    </Link>
                                </div>

                                <Alert message={errorMsg} type="error" style={{ ...marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                                <button type="submit" style={!apiCallStatus ? buttonStyle : buttonStyleDisabled} disabled={apiCallStatus}>Masuk</button>
                            </form>
                        </div>

                        <div style={registerStyle}>
                            <span style={{ color: "#212934", fontWeight: "600" }}>
                                Belum punya akun?
                            </span>
                            <Link to='/register' style={{ textDecoration: "none", color: "#FF6600", fontWeight: 700, paddingLeft: "5px" }}>
                                Daftar
                            </Link>
                        </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default BMMLogin