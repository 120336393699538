import Auth from '../../../../app/utils/auth';
import { Row, Col, Avatar, Card } from 'antd';
import ButtonLink from '../../../button';
import FormPublic from '../../../../app/utils/formPublic';
import SubmissionHistory from '../submissionHistory';
import useQuery from '../../../../public/final/main/hooks/useQuery';
import ExtinguisherIcon from '../../../../resources/icons/fire-extinguisher.svg';
import FireAlarmIcon from '../../../../resources/icons/fire-alarm.svg';
import FireHydrantIcon from '../../../../resources/icons/fire-hydrant.svg';
import { useEffect } from 'react';

function Patigeni1() {
    const auth = new Auth();
    const formPublic = new FormPublic();
    const { Meta } = Card;
    const fontFamily = "'merriweather', Courier, monospace";
    const param = useQuery();
    const source = param.get('source');

    useEffect(() => {
        if (source != null) formPublic.recordInquirySource('https://final.patigeni.com', source)
    }, [])

    const loginOrSignupMessage = () => {
        return <div style={{ color: '#919191' }}>
            <br />
            <p>
                Silahkan login apabila sudah punya akun FINAL atau daftar apabila belum punya akun
            </p>
            <br />
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: '5px', paddingTop: '10px' }}>
                    <ButtonLink title="Login" to={"/login" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ backgroundColor: '#d9ecff', fontWeight: 'bold', border: '1px solid rgb(173 210 249)' }} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                    <ButtonLink title="Daftar" to={"/register" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ fontWeight: 'bold', border: '1px solid rgb(214 214 214)' }} />
                </Col>
            </Row>
        </div>
    }

    const suggestionOption = () => {
        return (
            <div>
                <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px' }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                <h3 style={{ color: '#124265', fontFamily: fontFamily }}>
                                    Belanja Produk
                                </h3>
                                <p style={{ color: '#919191' }}>
                                    Kalau kamu butuh belanja produk silahkan pilih kategori produk yang kamu butuhkan
                                </p>
                                <Row>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE HYDRANT" src={FireHydrantIcon} style={{ padding:"20px" }} />}
                                            onClick={() => {
                                                const link = 'https://patigeni.com/fire-hydrant/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE HYDRANT" description="https://patigeni.com/fire-hydrant/" />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE ALARM" style={{ padding: "20px" }} src={FireAlarmIcon} />}
                                            onClick={() => {
                                                const link = 'https://patigeni.com/fire-alarm/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE ALARM" description="https://patigeni.com/fire-alarm/" />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={5} xl={5} style={{ paddingTop: '10px', paddingRight: '15px' }} justify="left" align="left">
                                        <Card
                                            hoverable
                                            style={{ boxShadow: "0px 2px 15px rgb(18 66 101 / 8%)" }}
                                            cover={<img alt="FIRE EXTINGUISHER" style={{ padding: "20px" }} src={ExtinguisherIcon} />}
                                            onClick={() => {
                                                const link = 'https://patigeni.com/alat-pemadam-api/';

                                                window.open(link);
                                                formPublic.recordActivity(link);
                                            }}
                                        >
                                            <Meta title="FIRE EXTINGUISHER" description="https://patigeni.com/alat-pemadam-api/" />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ backgroundColor: '#2587ce' }}>
                    <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px', textAlign: "center" }}>
                        <Row justify="center" align="middle">
                            <Col xxl={15} xl={20} lg={20} md={20} sm={20} xs={20}>
                                <h4 style={{ padding: '20px 20px 0px 0px', marginBottom: "40px", color: "white", fontFamily: fontFamily }}>Kalau kamu mau belanja produk juga butuh penawaran, silahkan klik lanjut.</h4>
                                <ButtonLink title="Lanjut" to={"/next" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ width: '200px', color: "white", backgroundColor: 'transparent', fontWeight: 'bold' }} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container" style={{ paddingTop: '50px' }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <SubmissionHistory style={{ marginBottom: '120px' }} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div style={{ backgroundColor: '#f8fbfe', paddingBottom: '120px' }}>
                <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px', height: "100vh" }}>
                    <img alt="" src="https://patigeni.com/wp-content/uploads/2015/03/Patigeni_Final.png" width="120px" />
                    <Row justify="center" align="middle" style={{ height: "100%" }}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <h3 style={{ color: '#124265', fontFamily: fontFamily }}>Hello Pelanggan yang baik</h3>
                            <p style={{ color: '#919191' }}>
                                Kami tau kalian pasti pengen dapetin balasan inquiry dengan cepat. Untuk memenuhi apa yang kamu mau sekarang kami sudah dapat wujudkan.
                            </p>
                            
                            {
                                auth.getToken() === null
                                    ? loginOrSignupMessage()
                                    : ''
                            }
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={12} xl={12} justify="center" align="middle">
                            <Avatar src="https://patigeni.com/wp-content/uploads/Foto-2.jpg" className="fancy-shadow-general" style={{ width: "500px", height: "500px" }} />
                        </Col>
                    </Row>
                </div>
            </div>
            {
                auth.getToken() !== null
                 ? suggestionOption()
                 : ''
            }
        </div>
    )
}

export default Patigeni1;