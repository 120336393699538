import { Col, Row, Carousel } from "antd"
import { useEffect, useState } from "react"
import BmmSlider1 from '../../resources/bmm-slider-1.jpg'
import BmmSlider2 from '../../resources/bmm-slider-2.jpg'
import BmmSlider3 from '../../resources/bmm-slider-3.jpg'
import BmmSlider4 from '../../resources/bmm-slider-4.jpg'

const containerStyle = {
    width: "100%",
    height: "100%",
    paddingTop: "30px",
    paddingHeight: "30px",
    textAlign: "center"
}

const inputStyle = {
    border: "none",
    fontSize: "13pt",
    borderBottom: "3px solid #EBEBEB",
    width: "100%",
    outline: "none",
    color: "#5454548c"
}

const labelStyle = {
    color: "##212934",
    width: "100%",
    textAlign: "left",
    fontWeight: "bold"
}

const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "rgb(255, 102, 0)",
    color: "white",
    outline: "none",
    border: "0px",
    width: "100%",
    padding: "10px",
    marginTop: "30px",
    marginBottom: "120px",
    borderRadius: "5px"
}

const buttonStyleDisabled = {
    fontWeight: "bold",
    backgroundColor: "rgb(255 102 0 / 49%)",
    cursor: "not-allowed",
    color: "white",
    outline: "none",
    border: "0px",
    width: "100%",
    padding: "10px",
    marginTop: "30px",
    marginBottom: "120px",
    borderRadius: "5px"
}

const marginTop30px = {
    marginTop: "30px"
}

const marginTop15px = {
    marginTop: "15px"
}

const registerStyle = {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    padding: "30px",
    bottom: "0px"
}

const required = <span style={{ color: "#FB0606" }}>*</span>

const BMMAuthStyle = {
    required: required,
    registerStyle: registerStyle,
    marginTop15px: marginTop15px,
    marginTop30px: marginTop30px,
    containerStyle: containerStyle,
    inputStyle: inputStyle,
    labelStyle: labelStyle,
    buttonStyle: buttonStyle,
    buttonStyleDisabled: buttonStyleDisabled
}

const BMMAuthLayout = (props) => {

    const [viewportWidth, setViewportWidth] = useState()

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)
    }, [])

    const carausel = <Carousel autoplay>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img alt="Slider" src={BmmSlider1} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img alt="Slider" src={BmmSlider2} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img alt="Slider" src={BmmSlider3} width="100%" />
        </div>
        <div style={{ width: "100%", backgroundColor: "#DEDAE5" }}>
            <img alt="Slider" src={BmmSlider4} width="100%" />
        </div>
    </Carousel>

    return <div style={{ minHeight: "100vh" }}>
        <Row>
            <Col xs={24} sm={24} md={12} lg={12} style={{ backgroundColor: "#DEDBE8", height: viewportWidth < 768 ? "auto" : "100vh" }}>
                {carausel}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} style={ viewportWidth >= 768 ? { maxHeight: "100vh", overflow: "scroll" } : { height: "auto" }}>
                <Row>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                    <Col xs={16} sm={16} md={16} lg={18}>
                        { props.children }
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={3}></Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export {
    BMMAuthLayout,
    BMMAuthStyle
}