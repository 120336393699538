import company from "../../../app/config/company_setting"
import PMSForgotPassword from "./pms/forgotPassword"
import BMMForgotPassword from "./bmm/forgotPassword"

const UserForgotPassword = () => {
    return company == "bmm"
        ? <BMMForgotPassword />
        : <PMSForgotPassword />
}

export default UserForgotPassword