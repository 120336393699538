import { Col, Row, Carousel, Alert } from "antd"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import BmmSlider1 from '../../../../resources/bmm-slider-1.jpg'
import BmmSlider2 from '../../../../resources/bmm-slider-2.jpg'
import BmmSlider3 from '../../../../resources/bmm-slider-3.jpg'
import BmmSlider4 from '../../../../resources/bmm-slider-4.jpg'
import AuthApi from '../../../../app/utils/auth'
import useQuery from "../../main/hooks/useQuery"
import { useParams, useHistory } from "react-router-dom"
import DebounceSelectOrganization from "../../../../component/debounce_select_organization"
import Customer from "../../../../app/service/customer"

const BMMRegister = () => {

    const auth = new AuthApi()
    const params = useParams('id')

    const query = useQuery()
    const history = useHistory()
    const source = query.get('source')
    const customer = new Customer()

    const [viewportWidth, setViewportWidth] = useState()
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [provinceList, setProvinceList] = useState([ <
        option key = { '0' }
        value = "" > Pilih... < /option>
    ])
    const [cityList, setCityList] = useState([])
    const [customerInfoField, setCustomerInfoField] = useState({ source: "", company: { value: "", label: "" } })
    const [optionOnNotFound, setOptionOnNotFound] = useState([])
    const [formField, setFormField] = useState({
        name: "",
        gender: "",
        email: "",
        password: "",
        password_confirmation: "",
        organization_type: "",
        organization_name: "",
        is_organization_exist: false,
        existing_organization_code: "",
        telephone: "",
        province: {
            label: "",
            value: ""
        },
        city: {
            label: "",
            value: ""
        },
        address: ""
    })

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)

        auth
            .getProvince()
            .then(response => {
                setProvinceList([
                    ...provinceList,
                    response.data.map(
                        i => < option key = { i.id }
                        value = { i.id } > { i.name } < /option>
                    )
                ])
            })
    }, [])

    async function fetchCustomerList(keyword, isRecordNotFound = false) {
        if (isRecordNotFound) {
            return [{
                label: < div > { keyword } <
                    /div>,
                value: "",
            }]
        }

        return customer.search(keyword)
            .then((body) => {
                return body.data.data.map((customer) => ({
                    label: < div > { customer.nama_customer } < br / > { /* {customer.alamat} <br /> */ } { /* Email : {customer.email} | PIC : {customer.cp} */ } <
                        /div>,
                    value: customer.kode_customer,
                }))
            }, )
    }

    const containerStyle = {
        width: "100%",
        height: "100%",
        paddingTop: "30px",
        paddingHeight: "30px",
        textAlign: "center"
    }

    const inputStyle = {
        border: "none",
        fontSize: "13pt",
        borderBottom: "3px solid #EBEBEB",
        width: "100%",
        outline: "none",
        color: "#5454548c"
    }

    const labelStyle = {
        color: "##212934",
        width: "100%",
        textAlign: "left",
        fontWeight: "bold"
    }

    const buttonStyle = {
        fontWeight: "bold",
        backgroundColor: "rgb(255, 102, 0)",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const buttonStyleDisabled = {
        fontWeight: "bold",
        backgroundColor: "rgb(255 102 0 / 49%)",
        cursor: "not-allowed",
        color: "white",
        outline: "none",
        border: "0px",
        width: "100%",
        padding: "10px",
        marginTop: "30px",
        marginBottom: "120px",
        borderRadius: "5px"
    }

    const marginTop30px = {
        marginTop: "30px"
    }

    const marginTop15px = {
        marginTop: "15px"
    }

    const registerStyle = {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        padding: "30px",
        bottom: "0px"
    }

    const required = < span style = {
        { color: "#FB0606" } } > * < /span>

    const carausel = < Carousel autoplay >
        <
        div style = {
            { width: "100%", backgroundColor: "#DEDAE5" } } >
        <
        img src = { BmmSlider1 }
    width = "100%" / >
        <
        /div> <
        div style = {
            { width: "100%", backgroundColor: "#DEDAE5" } } >
        <
        img src = { BmmSlider2 }
    width = "100%" / >
        <
        /div> <
        div style = {
            { width: "100%", backgroundColor: "#DEDAE5" } } >
        <
        img src = { BmmSlider3 }
    width = "100%" / >
        <
        /div> <
        div style = {
            { width: "100%", backgroundColor: "#DEDAE5" } } >
        <
        img src = { BmmSlider4 }
    width = "100%" / >
        <
        /div> <
        /Carousel>

    const submitHandler = (e) => {
        e.preventDefault()
        setErrorMsg('')

        const formData = {
            email: formField.email,
            password: formField.password,
            perusahaan: formField.organization_name,
            is_organization_exist: formField.is_organization_exist,
            existing_organization_code: formField.existing_organization_code,
            alamat: formField.address,
            telp: formField.telephone,
            kota: formField.city.label,
            id_kabupaten: formField.city.value,
            provinsi: formField.province.label,
            id_provinsi: formField.province.value,
            bentuk_organisasi: formField.organization_type,
            nama: formField.name,
            gender: formField.gender,
            sales_offline: params.id
        }

        setApiCallStatus(true)

        auth
            .verifyEmailavailability(formData.email)
            .then(res => {

                if (res.status === 200 && res.data.availability) {
                    proceedRegistration(formData)
                } else {
                    setApiCallStatus(false)
                    setErrorMsg('Email sudah digunakan. Pernah daftar dengan email ' + formData.email + '? Apabila lupa password silahkan klik Lupa Password')
                }
            })
    }

    const proceedRegistration = (formData) => {
        const formDataMergeWithProvAndCityId = {
            ...formData,
            id_provinsi: parseInt(formData.id_provinsi),
            id_kabupaten: parseInt(formData.id_kabupaten)
        }

        formData = formDataMergeWithProvAndCityId

        auth
            .register(formData)
            .then(response => {
                if (response.status === 200) {
                    // save token and id_user
                    auth
                        .setUserSession(response.data.data.remember_token, response.data.data)

                    const saveTokenAndIdUser = auth.setUserSession(response.data.data.remember_token, response.data.data)
                    if (saveTokenAndIdUser.token !== '') {
                        const sourceUrl = source !== null ? "?source=" + source : ""
                        history.push('/next' + sourceUrl)
                    }
                }

                setApiCallStatus(false)
            })
    }

    const provinceCityFullAddress = () => {
        return <Col xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 24 }
        style = {
                {...marginTop15px } } >
            <
            div style = {
                {...marginTop15px } } >
            <
            label style = { labelStyle } >
            Provinsi { required } <
            /label> <
            /div> <
            div style = {
                {...marginTop15px } } >
            <
            select style = { inputStyle }
        onChange = {
            (e) => {
                const provinceId = e.target.value
                const optionLabel = e.target.selectedOptions[0].label

                auth
                    .getCity(provinceId)
                    .then(response => {
                        setCityList(response.data.map(
                            i => < option key = { i.id }
                            value = { i.id } > { i.name } < /option>
                        ))

                        setFormField({
                            ...formField,
                            province: { label: optionLabel, value: provinceId },
                            city: {
                                label: response.data.length > 0 ?
                                    response.data[0].name :
                                    "",
                                value: response.data.length > 0 ?
                                    response.data[0].id :
                                    ""
                            }
                        })
                    })

            }
        }
        value = { formField.province.value }
        required > { provinceList } <
            /select> <
            /div>

        <
        div style = {
                {...marginTop15px } } >
            <
            label style = { labelStyle } >
            Kota { required } <
            /label> <
            /div> <
            div style = {
                {...marginTop15px } } >
            <
            select style = { inputStyle }
        onChange = {
            (e) => {
                const cityId = e.target.value
                const optionLabel = e.target.selectedOptions[0].label

                setFormField({...formField, city: { label: optionLabel, value: cityId } })
            }
        }
        value = { formField.city.value }
        required > { cityList } <
            /select> <
            /div>

        <
        div style = {
                {...marginTop15px } } >
            <
            label style = { labelStyle } >
            Alamat Lengkap { required } <
            /label> <
            /div> <
            div style = {
                {...marginTop15px } } >
            <
            input type = "text"
        style = { inputStyle }
        value = { formField.address }
        onChange = {
            (e) => setFormField({...formField, address: e.target.value }) }
        required / >
            <
            /div> <
            /Col>
    }

    return <div style = {
            { minHeight: "100vh" } } >
        <
        Row >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 12 }
    lg = { 12 }
    style = {
            { backgroundColor: "#DEDBE8", height: viewportWidth < 768 ? "auto" : "100vh" } } > { carausel } <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 12 }
    lg = { 12 }
    style = { viewportWidth >= 768 ? { maxHeight: "100vh", overflow: "scroll" } : { height: "auto" } } >
        <
        Row >
        <
        Col xs = { 4 }
    sm = { 4 }
    md = { 4 }
    lg = { 3 } > < /Col> <
        Col xs = { 16 }
    sm = { 16 }
    md = { 16 }
    lg = { 18 } >
        <
        div style = { containerStyle } >
        <
        h1 style = {
            { fontWeight: "bold" } } > Daftar < /h1>

    <
    span style = {
            { color: "#212934", fontWeight: "500", ...marginTop30px } } >
        Silahkan login / daftarkan diri / perusahaan Anda < br / >
        untuk mendapatkan penawaran kilat dari Bromindo <
        /span>

    <
    form style = {
        {...marginTop30px } }
    onSubmit = { submitHandler } >
        <
        Row >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Nama { required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        input type = "text"
    style = { inputStyle }
    value = { formField.name }
    onChange = {
        (e) => setFormField({...formField, name: e.target.value }) }
    required / >
        <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Email { required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        input type = "email"
    style = { inputStyle }
    value = { formField.email }
    onChange = {
        (e) => setFormField({...formField, email: e.target.value }) }
    required / >
        <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Jenis Kelamin { required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        select style = { inputStyle }
    onChange = {
        (e) => setFormField({...formField, gender: e.target.value }) }
    required >
        <
        option value = "" > Pilih.. < /option> <
        option value = { "L" } > Laki - laki < /option> <
        option value = { "P" } > Perempuan < /option> <
        /select> <
        Alert message = { "Jenis Kelamin harus diisi" }
    type = "error"
    style = {
        {...marginTop15px, display: formField.gender == "" && formField.email !== "" ? "block" : "none" } }
    /> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Password { required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        input type = "password"
    style = { inputStyle }
    value = { formField.password }
    onChange = {
        (e) => setFormField({...formField, password: e.target.value }) }
    required / >
        <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Konfirmasi Password { required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        input type = "password"
    style = { inputStyle }
    value = { formField.password_confirmation }
    onChange = {
        (e) => setFormField({...formField, password_confirmation: e.target.value }) }
    required / >

        <
        Alert message = { "Password tidak sama" }
    type = "error"
    style = {
        {...marginTop15px, display: formField.password === formField.password_confirmation ? "none" : "block" } }
    /> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Nomor Telepon { required } <
        /label> <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        input type = "number"
    style = { inputStyle }
    value = { formField.telephone }
    onChange = {
        (e) => setFormField({
            ...formField,
            telephone: e.target.value
        })
    }
    required / >
        <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Bentuk Organisasi { required } <
        /label> <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        select style = { inputStyle }
    onChange = {
        (e) => setFormField({...formField, organization_type: e.target.value }) }
    required >
        <
        option value = "" > Pilih.. < /option> <
        option value = { "perusahaan" } > Perusahaan < /option> <
        option value = { "perseorangan" } > Perseorangan < /option> <
        /select> <
        Alert message = { "Bentuk Organisasi harus dipilih" }
    type = "error"
    style = {
        {...marginTop15px, display: formField.organization_type === "" && formField.telephone.length >= 9 ? "block" : "none" } }
    /> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        label style = { labelStyle } >
        Pilih Organisasi { required } <
        /label> <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...marginTop15px } } >
        <
        DebounceSelectOrganization
    onAddOrganizationClicked = {
        (val) => {
            setOptionOnNotFound([{
                label: < div > { val } <
                    /div>,
                value: val
            }])
        }
    }
    optionOnNotFound = { optionOnNotFound }
    value = { customerInfoField.company }
    fetchOptions = { fetchCustomerList }
    onChange = {
        newValue => {
            const firstFiveChar = newValue.value.substring(0, 5)
            const isOrganizationExist = firstFiveChar == "1504C"

            setFormField({
                ...formField,
                organization_name: isOrganizationExist ? "" : newValue.value,
                is_organization_exist: isOrganizationExist,
                existing_organization_code: isOrganizationExist ? newValue.value : ""
            })

            setCustomerInfoField({...customerInfoField, company: newValue })
        }
    }
    style = {
        {
            width: '100%',
            textAlign: 'left',
            border: "1px solid white",
            borderBottom: "3px solid #EBEBEB",
        }
    }
    required
        /
        >
        <
        Alert message = { "Organisasi harus diisi" }
    type = "error"
    style = {
        {...marginTop15px, display: formField.existing_organization_code === "" && formField.organization_type !== "" ? "block" : "none" } }
    />

    <
    /Col>

    {
        /* {
                                                formField.organization_type === "perusahaan"
                                                    ? <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                                        <label style={labelStyle}>
                                                            Nama Perusahaan / Instansi {required}
                                                        </label>
                                                    </Col>
                                                    : <Col xs={0} />
                                            }

                                            {
                                                formField.organization_type === "perusahaan"
                                                    ? <Col xs={24} sm={24} md={24} lg={24} style={{ ...marginTop15px }}>
                                                        <input type="text" style={inputStyle} value={formField.organization_name} onChange={(e) => setFormField({ ...formField, organization_name: e.target.value })} required />
                                                    </Col>
                                                    : <Col xs={0} />
                                            } */
    }

    {
        provinceCityFullAddress()
    }

    <
    /Row>

    <
    Alert message = { errorMsg }
    type = "error"
    style = {
        {...marginTop15px, display: errorMsg == "" ? "none" : "block" } }
    /> <
    button type = "submit"
    style = {!apiCallStatus ? buttonStyle : buttonStyleDisabled }
    disabled = { apiCallStatus } > Lanjut < /button> <
        /form> <
        /div>

    <
    div style = { registerStyle } >
        <
        span style = {
            { color: "#212934", fontWeight: "600" } } >
        Sudah punya akun ?
        <
        /span> <
        Link to = '/login'
    style = {
            { textDecoration: "none", color: "#FF6600", fontWeight: 700, paddingLeft: "5px" } } >
        Masuk <
        /Link> <
        /div> <
        /Col> <
        Col xs = { 4 }
    sm = { 4 }
    md = { 4 }
    lg = { 3 } > < /Col> <
        /Row> <
        /Col> <
        /Row> <
        /div>
}

export default BMMRegister