import {Component} from 'react';
import Button from '../button';

export default class Form extends Component {
    state = {
        showConfirmDelete: false,
        deleting: false
    }

    componentDidMount() {
        // 
    }

    render() {
        const style = this.state.deleting
            ? { margin: '20px', padding: '20px', background: '#e0ddd7' }
            : { margin: '20px', padding: '20px'};
        const editUrl = '/pv/setup-form/' + this.props.id;
        const confirmDeleteView = <div>
            Yakin hapus? <br/> <hr/>
            <div className="row">
                <div className="col-sm-6">
                    <button className="btn btn-light" onClick={() => this.setState({ showConfirmDelete: false })}>Batal</button>
                </div>
                <div className="col-sm-6">
                    <button className="btn btn-danger" onClick={() => {
                        this.props.deleteHandler(this.props.id)
                        this.setState({
                            showConfirmDelete: false,
                            deleting: true
                        })
                    }}>Hapus</button>
                </div>
            </div>
        </div>;
        const toolbar = <div className="row">
            <div className="col-sm-6">
                <Button to={editUrl} title="Edit" />
            </div>
            <div className="col-sm-6">
                <button className="btn btn-light" onClick={() => this.setState({ showConfirmDelete: true })}>Hapus</button>
            </div>
        </div>;

        const deletingState = <div className="row">
            <div className="col-md-12">
                <h5 className="card-title">
                    Menghapus ...
                </h5>
            </div>
        </div>;
        const form = <div className="row">
            <div className="col-md-9">
                <h5 className="card-title">
                    {this.props.name}
                </h5>
                {this.props.desc}
            </div>
            <div className="col-md-3">
                {this.state.showConfirmDelete ? confirmDeleteView : toolbar}
            </div>
        </div>;

        return (
            <div className="card" style={style}>
                {this.state.deleting ? deletingState : form}
            </div>
        )
    }
}