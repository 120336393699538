import React, { useEffect, useState } from 'react'
import { Handle } from 'react-flow-renderer'
import FormApi from '../../app/utils/formPrivate'
import { connect } from 'react-redux'
import useDebounce from '../../public/final/main/hooks/useDebounce';

const nodeText = React.memo(function NodeText(props) {

    const currentElement = props.elements.filter(i => i.id === props.data.id)[0];
    const [nodeData, setNodeData] = useState({
        newScore: currentElement.data.score,
        newLabel: currentElement.data.label,
        newRequired: currentElement.data.required,
        newBgColor: currentElement.style.backgroundColor
    });

    const debouncedData = useDebounce(nodeData, 1000);

    useEffect(() => {
        // console.log('[nodeText.js] isChanged', isChanged());

        if (debouncedData && isChanged()) {
            changeHandler();
        }
    }, [debouncedData])

    // check for change
    const isChanged = () => {
        // check score
        if (currentElement.data.score !== nodeData.newScore) return true;

        // check label
        if (currentElement.data.label !== nodeData.newLabel) return true;

        // check required
        if (currentElement.data.required !== nodeData.newRequired) return true;

        // check background color
        if (currentElement.style.backgroundColor !== nodeData.newBgColor) return true;

        return false;
    }

    const changeHandler = () => {
        const fromElement = currentElement;
        const currentNode = {
            id: fromElement.id, // [FIRST ID] NEED UPDATE
            data: {
                id: fromElement.id, // [SECOND ID] VALUE SAME WITH FIRST ID. THIS ID USED TO GET THE NODE ID TO SINGLE OPTION COMPONENT
                required: nodeData.newRequired,
                label: nodeData.newLabel,
                score: nodeData.newScore,

                // child node only able to access this
                type: 'textNode',
                style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
                position: fromElement.data.position,
                targetPosition: 'left',
            },
            type: 'textNode',
            style: { border: '1px solid #777', padding: 10, backgroundColor: nodeData.newBgColor },
            position: fromElement.data.position,
            targetPosition: 'left',
        }

        props.nodeUpdate(currentNode)
        props.updateMessage({
            status: 'start',
            msg: '[TEXT] Memperbarui pertanyaan'
        });

        const formApi = new FormApi();
        formApi
            .updateTextInput(currentNode)
            .then(response => {
                // console.log(response.data)
                props.updateMessage({
                    status: 'finish-succeed',
                    msg: '[TEXT] Berhasil disimpan'
                });

                props.updateMessage({
                    status: 'finish-hide',
                    msg: ''
                });
            })
            .catch(err => {
                props.updateMessage({
                    status: 'finish-failed',
                    msg: '[TEXT] Gagal'
                });
            })
    }

    return (
        <div style={{ backgroundColor: nodeData.newBgColor, margin: '-10px', padding: '10px' }}>
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <div>
                <input
                    style={{ marginRight: '10px' }}
                    type="color"
                    onChange={(e) => setNodeData({
                        ...nodeData,
                        newBgColor: e.target.value
                    })}
                    defaultValue={nodeData.newBgColor}
                />
                
                Text <hr />
                <b>Pertanyaan :</b> <br />
                <textarea type="text" value={nodeData.newLabel} onChange={(e) => setNodeData({
                    ...nodeData,
                    newLabel: e.target.value
                })} />
                <hr />
                <b>Harus diisi :</b>
                <input type="checkbox" style={{ marginLeft: '10px' }} onChange={(e) => setNodeData({
                    ...nodeData,
                    newRequired: !nodeData.newRequired
                })} checked={nodeData.newRequired} />
                <hr />
                <b>Skor :</b>
                <input type="number" style={{ marginLeft: '10px' }} value={nodeData.newScore} onChange={(e) => setNodeData({
                    ...nodeData,
                    newScore: e.target.value
                })} />
            </div>
            <Handle
                type="source"
                position="right"
                id={'1'}
                onConnect={(params) => {
                    // console.log('from node text')
                    // console.log(params)
                }}
                style={{
                    background: '#555'
                }}
            />
        </div>
    );
})

const mapStateToProps = state => {
    return {
        elements: state.elements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nodeUpdate: params => dispatch({
            type: 'GENERAL.UPDATE-ELEMENTS', payload: {
                node: params
            }
        }),
        updateMessage: params => dispatch({
            type: 'UPDATE-MESSAGE', payload: {
                message: params
            }
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(nodeText);