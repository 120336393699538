import {Component} from 'react';
import Navbar from '../../component/navbar/navbar';
import {withRouter} from 'react-router-dom';
import Auth from '../../app/utils/auth';

class UserLogout extends Component {

    componentDidMount() {
        const api = new Auth();
        
        api.removeUserSession()
        this.props.history.push('/')
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <br/>
                    Tunggu sebentar ...
                </div>
            </div>
        )
    }
}

export default withRouter(UserLogout);