var companyTypeNode = {
    id: 0, // [FIRST ID] NEED UPDATE
    data: {
        id: 0, // [SECOND ID] VALUE SAME WITH FIRST ID. THIS ID USED TO GET THE NODE ID TO SINGLE OPTION COMPONENT
        label: '',
        options: {
            perusahaan: 0,
            perseorangan: 0
        },
        
        // child node only able to access this
        type: 'companyTypeNode',
        style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
        position: { x: 650, y: 100 },
        targetPosition: 'left',
    },
    type: 'companyTypeNode',
    style: { border: '1px solid #777', padding: 10, backgroundColor: '#FFFFFF' },
    position: { x: 650, y: 100 },
    targetPosition: 'left',
}

export default companyTypeNode