import { Component } from 'react';
import Navbar from '../../component/navbar/navbar';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormApi from '../../app/utils/formPrivate';
import Guard from '../../app/utils/security';
import { withRouter } from "react-router-dom";
import EntryQuestion from '../../component/form/entry_question';
import Auth from '../../app/utils/auth';

class SetupForm extends Component {
    state = {
        fields: {
            id: '',
            initialInput: '',
            initialInputOpt: [],
            name: '',
            desc: '',
            openingTitle: '',
            openingMsg: EditorState.createEmpty(),
            closingMsg: EditorState.createEmpty(),
            openingImage: '',
            createdAt: ''
        },
        errors: {
            name: '',
            desc: '',
            openingTitle: '',
            openingMsg: ''
        },
        views: {
            headerCollapse: true,
            openingImage: '',
            msg: {
                header: '',
                detail: ''
            },
            apiCall: ''
        }
    }

    // return true if token doesn't match the database record
    // means session expired, user need to re-login
    isForbidden(response) {
        return response === 403;
    }

    // clear token then redirect to login page
    clearTokenAndRedirectToLogin() {
        const auth = new Auth();

        // remove session
        auth.removeUserSession();

        this.props.history.push('/pv');
    }

    componentDidMount() {
        document.title = "Form builder";

        const id = this.props.match.params.id;
        const formApi = new FormApi();
        formApi
            .getDetailFormAndInput(id)
            .then(response => {
                if (this.isForbidden(response)) {
                    return this.clearTokenAndRedirectToLogin();
                }

                const views = this.state.views;
                views['openingImage'] = response.data.data.form.opening_image;
                this.setState({ views })

                const fields = this.state.fields;
                let openingMsgContent = '';
                let closingMsgContent = '';

                try {
                    if (response.data.data.form.opening_message !== null && response.data.data.form.opening_message !== undefined) {
                        openingMsgContent = JSON.parse(response.data.data.form.opening_message);
                    }

                    if (response.data.data.form.closing_message !== null && response.data.data.form.closing_message !== undefined) {
                        closingMsgContent = JSON.parse(response.data.data.form.closing_message);
                    }
                } catch (error) { }
                // console.log('[setupForm.js] parseJson', response.data);

                // set opening msg
                if (openingMsgContent.constructor === Object) {
                    const openingContent = convertFromRaw(openingMsgContent.content)

                    fields['openingMsg'] = EditorState.createWithContent(openingContent);
                } else {
                    fields['openingMsg'] = EditorState.createEmpty();
                }

                // set closing msg
                if (closingMsgContent.constructor === Object) {
                    const closingContent = convertFromRaw(closingMsgContent.content)

                    fields['closingMsg'] = EditorState.createWithContent(closingContent);
                } else {
                    fields['closingMsg'] = EditorState.createEmpty();
                }

                fields['id'] = response.data.data.form.id
                fields['initialInput'] = response.data.data.form.initial_input;
                fields['name'] = response.data.data.form.name;
                fields['openingTitle'] = response.data.data.form.opening_title;
                fields['desc'] = response.data.data.form.desc;
                fields['createdAt'] = response.data.data.form.created_at;

                this.setState({ fields })
            })
    }

    handleFormHeaderSubmit(e) {
        e.preventDefault();
        const views = this.state.views;
        views['apiCall'] = true;
        this.setState({ views })

        const getContent = this.state.fields.openingMsg.getCurrentContent();
        const convertContentToRaw = JSON.stringify({
            content: convertToRaw(getContent),
        });
        const openingMsg = convertContentToRaw;

        const getClosingContent = this.state.fields.closingMsg.getCurrentContent();
        const convertClosingContentToRaw = JSON.stringify({
            content: convertToRaw(getClosingContent),
        });
        const closingMsg = convertClosingContentToRaw;

        const formApi = new FormApi();
        formApi
            .updateFormGeneralInformation(
                this.state.fields.id,
                this.state.fields.name,
                this.state.fields.desc,
                this.state.fields.openingTitle,
                this.state.fields.initialInput,
                openingMsg,
                this.state.fields.openingImage,
                closingMsg
            )
            .then(response => {
                console.log(response.data);

                const views = this.state.views;
                views['apiCall'] = false;
                views['msg']['header'] = 'Berhasil disimpan';
                this.setState({ views })
            });
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        let targetValue;

        if (field === 'openingImage') {
            targetValue = e.target.files[0];
        } else {
            targetValue = e.target.value;
        }

        fields[field] = targetValue;

        this.setState({ fields });
    }

    onOpeningChange = (editorState) => {
        let fields = this.state.fields;
        fields['openingMsg'] = editorState;

        this.setState({
            fields
        });
    }

    onClosingChange = (editorState) => {
        let fields = this.state.fields;
        fields['closingMsg'] = editorState;

        this.setState({
            fields
        });
    }

    formHeader = () => {
        const questionOptions = this.state.fields.initialInputOpt.map(i => {
            return <option key={i.id} value={i.id}>{i.question}</option>
        })

        if (this.state.views.headerCollapse) {
            return (
                <div></div>
            )
        } else {
            return (
                <form className="form-control" onSubmit={this.handleFormHeaderSubmit.bind(this)} style={{ paddingTop: '20px' }}>
                    <b>Nama form *</b>
                    <input type="text" className="form-control" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]} style={{ marginTop: '10px' }} required />
                    <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                    <br />
                    <b>Deskripsi form *</b>
                    <textarea className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "desc")} value={this.state.fields["desc"]} required></textarea>
                    <span style={{ color: "red" }}>{this.state.errors["desc"]}</span>
                    <br />
                    <b>Opening image</b><br />
                    <img className="rounded d-block" src={this.state.views.openingImage} alt="" width='200px' />
                    <input type="file" className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "openingImage")} />
                    <span style={{ color: "red" }}>{this.state.errors["openingImage"]}</span>
                    <br />
                    <b>Opening title *</b>
                    <input type="text" className="form-control" style={{ marginTop: '10px' }} onChange={this.handleChange.bind(this, "openingTitle")} value={this.state.fields["openingTitle"]} required />
                    <span style={{ color: "red" }}>{this.state.errors["openingTitle"]}</span>
                    <br />
                    <b>Opening message</b>
                    <Editor
                        editorState={this.state.fields['openingMsg']}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="form-control"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onOpeningChange}
                    />
                    <br />
                    <b>Closing message</b>
                    <Editor
                        editorState={this.state.fields['closingMsg']}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="form-control"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onClosingChange}
                    />
                    <br />
                    <b>Pertanyaan pertama</b>
                    <select value={this.state.fields.initialInput} className="form-control" onClick={() => {
                        const formApi = new FormApi();
                        formApi
                            .getQuestions(this.props.match.params.id)
                            .then(response => {
                                const getQuestionOpt = [...response.data.data]
                                const fields = this.state.fields

                                fields['initialInputOpt'] = getQuestionOpt
                                this.setState({
                                    fields
                                })
                            })
                    }} onChange={(e) => {
                        let fields = this.state.fields
                        fields['initialInput'] = e.target.value

                        this.setState({ fields })
                    }} required>
                        <option value="">Pilih ...</option>
                        {questionOptions}
                    </select>
                    <br />
                    <div className="alert alert-success" style={{ display: this.state.views.msg.header !== '' ? 'block' : 'none' }}>
                        {this.state.views.msg.header}
                    </div>
                    <button className="btn btn-md btn-dark">{!this.state.views.apiCall ? 'Simpan' : 'Menyimpan ...'}</button>
                </form>
            )
        }
    }

    render() {
        const header = this.formHeader();
        const guard = new Guard();
        if (guard.privateGeneralGuard() !== undefined) {
            return guard.privateGeneralGuard();
        }

        return (
            <div>
                <Navbar />
                <div className="container" style={{ paddingTop: '20px' }}>
                    <h1>Form builder - {this.state.fields.name}</h1> <br />
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <h5>Informasi umum</h5>
                                </td>
                                <td>
                                    <button onClick={() => {
                                        const currViewsState = this.state.views
                                        currViewsState['msg']['header'] = ''
                                        currViewsState['headerCollapse'] = !this.state.views.headerCollapse
                                        this.setState({
                                            currViewsState
                                        })
                                    }}>[{this.state.views.headerCollapse ? 'Buka' : 'Tutup'}]</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {header}
                </div>
                <hr />
                <EntryQuestion formId={this.props.match.params.id} />
            </div>
        )
    }
}

export default withRouter(SetupForm);