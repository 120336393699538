import { Select, Spin } from 'antd'
import { useState, useEffect } from 'react'
import useDebounce from '../public/final/main/hooks/useDebounce'

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const [value, setValue] = useState("")

    const debouncedData = useDebounce(value, 1000)
    useEffect(() => {
        if (debouncedData && value.length > 3) {
            setFetching(true)
            fetchOptions(value).then((newOptions) => {

                setOptions(newOptions)
                setFetching(false)
            })
        }
    }, [debouncedData])

    return (
        <Select
            labelInValue
            filterOption={false}
            showSearch={true}
            onSearch={(val) => setValue(val)}
            notFoundContent={
                fetching 
                    ? <Spin size="small" /> 
                    : value.length <= 3
                        ? <div style={{ color: "grey" }}>
                            💡 Tip : Cari customer dengan nama atau email<br />
                            Minimal empat karakter untuk mencari
                        </div>
                        : "Data tidak ditemukan"
            }
            {...props}
            options={options}
        />
    )
}

export default DebounceSelect