import Auth from './auth'
import { Redirect } from "react-router-dom"

export default class Guard {
    
    privateLoginGuard() {
        const api = new Auth()
        const user = api.getUser()
        
        if (user !== undefined && user !== null) {
            return <Redirect to='pv/home' />
        }
    }

    privateGeneralGuard() {
        const api = new Auth()
        const user = api.getUser()

        if (user === undefined || user === null) {
            return <Redirect to='/pv' />
        }
    }

    isFinalUser() {
        const userData = localStorage.getItem('user')
        const user = JSON.parse(userData !== null ? userData : '{}')
        const isFinalUser = 'from_final' in user && user.from_final !== 0
        const isUserHasKdCustomer = isFinalUser && 'kode_customer' in user && user.kode_customer !== null

        if (!isUserHasKdCustomer) {
            return false
        }

        return true
    }

    isSales() {
        const userData = localStorage.getItem('user')
        const user = JSON.parse(userData !== null ? userData : '{}')
        const isSales = 'level' in user && (user.level == 3 || user.level == 7 || user.level == 4)

        return isSales
    }
}