import { Button, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import useKeyPress from '../hooks/useKeyPress';
import SingleSelectOption from './subComponent/singleSelectOption';
import parse from 'html-react-parser';
import './style.css';
import ContactPerson from '../../../../component/contactPerson';

export default function SingleSelectOptionComponent(props) {
    
    const [optList, setOptList] = useState();
    const [selectedId, setSelectedId] = useState('');
    const [draftId, setDraftId] = useState();
    const [otherInputTextValue, setOtherInputTextValue] = useState('');
    const [otherInputTextFocus, setOtherInputTextFocus] = useState(null);
    const [executeAction, setExecuteAction] = useState(false);
    
    const enterPressed = useKeyPress('Enter');

    const onClickHandler = (id) => {
        setExecuteAction(true);
        setSelectedId(id);
    };

    const changeOtherInputTextFocus = (id, value) => {
        setOtherInputTextValue(value);
        setOtherInputTextFocus(id);
    }

    useEffect(() => {
        // on load from back
        // display selected option if exist
        if (props.value.input_value_id != undefined) {
            setSelectedId(props.value.input_value_id);
        }

        return () => {
            setOptList(undefined);
        }
    }, [])

    useEffect(() => {
        const propsDataOptList = { ...JSON.parse(props.data.option_list)};

        let score = 0;
        let nextInputId = 0;

        // for non other option
        if ('option' in propsDataOptList) {
            let selectedOption = propsDataOptList.option.filter(i => i.id === selectedId);
            if (selectedId !== '' && selectedOption.length > 0) {
                score = selectedOption[0].score;
                nextInputId = selectedOption[0].next_input_id;
            }
            
            if (selectedId !== 'other' && selectedOption.length > 0 && executeAction) {
                setExecuteAction(false);

                let getValue = selectedOption[0].value;
                let newAnswer = {
                    answerId: selectedId,
                    answerValue: getValue
                };
    
                props.action(props.data.id, nextInputId, draftId, JSON.stringify(newAnswer), score);
            }
        }

        // set option list
        if (optList === undefined) {
            setOptList(JSON.parse(props.data.option_list));
        }

        // on enter btn pressed
        if (enterPressed && otherInputTextValue !== '') {
            nextInputHandler();
        }

        setDraftId(props.draftId);

    }, [selectedId, otherInputTextFocus, enterPressed, optList, props.data.option_list])

    const nextInputHandler = () => {
        const propsDataOptList = { ...JSON.parse(props.data.option_list) };

        let score = 0;
        let nextInputId = 0;

        if (selectedId !== '') {
            score = propsDataOptList.option.filter(i => i.id === selectedId)[0].score;
            nextInputId = propsDataOptList.option.filter(i => i.id === selectedId)[0].next_input_id;
        }

        let newAnswer = {
            answerId: selectedId,
            answerValue: otherInputTextValue
        };
        
        props.action(props.data.id, nextInputId, draftId, JSON.stringify(newAnswer), score);
    }

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    <h1>
                        {parse( props.data.question )}
                    </h1>
                    <div style={{ fontSize: '13pt' }}>
                        {
                            optList !== undefined ? optList.option.map(
                                i => <SingleSelectOption
                                    fullData={optList.option}
                                    key={i.id}
                                    id={i.id}
                                    value={i.value}
                                    score={i.score}
                                    next_input_id={i.next_input_id}
                                    desc={i.desc}
                                    form_id={i.form_id}

                                    // control if other option selected change its appearance to input
                                    otherInputTextFocus={otherInputTextFocus}
                                    otherInputTextValue={otherInputTextValue}
                                    otherInputTextFocusAction={changeOtherInputTextFocus}

                                    action={() => onClickHandler(i.id)}
                                    selectedOpt={selectedId}
                                />
                            ) : ''
                        }
                    </div>
                    {
                        otherInputTextFocus !== null
                            ? <div style={{ fontSize: '13pt', marginTop: '10px' }}>
                                <Button className="fancy-shadow" onClick={nextInputHandler}>OK</Button>
                                <span style={{ fontSize: '10pt', marginLeft: '10px' }}>tekan <strong>Enter ↵</strong></span>
                            </div>
                            : ''
                    }

                    <ContactPerson />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}