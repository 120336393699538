import FormPublic from '../../../app/utils/formPublic'
import { Row, Col, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useMemo, useEffect, useState } from 'react'
import { Editor, EditorState, convertFromRaw } from "draft-js"
import 'antd/dist/antd.css'
import AuthApi from '../../../app/utils/auth'
import Company from '../../../app/config/company_setting'
import { AnimateOnChange } from 'react-animation'
import OpeningComponent from './questionComponent/openingComponent'
import SingleSelectOptionComponent from './questionComponent/singleSelectOptionComponent'
import MultiSelectOptionComponent from './questionComponent/multiSelectOptionComponent'
import NumberComponent from './questionComponent/numberComponent'
import ItemQtyComponent from './questionComponent/itemQtyComponent'
import TextComponent from './questionComponent/textComponent'
import EmailComponent from './questionComponent/emailComponent'
import TimeComponent from './questionComponent/timeComponent'
import InformationComponent from './questionComponent/informationComponent'
import FileComponent from './questionComponent/fileComponent'
import DateComponent from './questionComponent/dateComponent'
import SalesAreaComponent from './questionComponent/salesAreaComponent'
import SalesAreaInfoComponent from './questionComponent/salesAreaInfoComponent'
import FinishingComponent from './questionComponent/finishingComponent'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Auth from '../../../app/utils/auth'
import useQuery from './hooks/useQuery'
import Guard from '../../../app/utils/security'
import Customer from '../../../app/service/customer'

function FinalMain(props) {

    // first question ID
    const [firstQuestionId, setFirstQuestionId] = useState('')

    // active question ID
    const [activeQuestionTypeId, setActiveQuestionTypeId] = useState('opening')
    const [activeQuestionData, setActiveQuestionData] = useState('')
    const [savedInputValue, setSavedInputValue] = useState('')

    // minibar percentage (width)
    const [loadingMiniBar, setLoadingMiniBar] = useState('0%')

    // draft id
    const [activeDraftId, setActiveDraftId] = useState('')

    // opening message data
    const [openingMsg, setOpeningMsg] = useState('')

    // history
    const history = useHistory()

    // parameter
    const params = useParams()
        // url query
    const query = useQuery()

    // form api
    const formApi = new FormPublic()
    const authApi = new AuthApi()
    const userDetail = authApi.getUser()

    useEffect(() => {
        document.body.classList.add('body-bg')

        handleDraft()
    }, [])

    // check if draft exist
    // if exist show draft else set normal form setting
    const handleDraft = () => {
        const isDraftParamExist = 'draftId' in params
        if (isDraftParamExist) {
            formApi
                .getLastFormState(params.draftId)
                .then(res => {
                    const isDraftExist = Object.keys(res.data.data).length > 0

                    if (isDraftExist) {
                        const contentState = convertFromRaw(res.data.data.openingMsg)
                        const editorState = EditorState.createWithContent(contentState)
                        const editorElement = < Editor editorState = { editorState }
                        readOnly = { true }
                        />

                        setOpeningMsg(editorElement)

                        setFirstQuestionId(res.data.data.firstQuestionId)

                        setActiveQuestionTypeId(res.data.data.activeQuestionTypeId)
                        setActiveQuestionData(res.data.data.activeQuestionData)
                        setSavedInputValue(res.data.data.savedInputValue)
                        setActiveDraftId(res.data.data.activeDraftId)
                    } else {
                        setUpInitialFormSetting()
                    }
                })
                .catch(err => setUpInitialFormSetting())
        } else {
            setUpInitialFormSetting()
        }
    }

    // if draft isn't exist, use this settings to configure form
    const setUpInitialFormSetting = () => {
        if (openingMsg === '') {
            // set opening message
            formApi
                .getFormHeader(props.formId)
                .then(response => {
                    if (isForbidden(response)) {
                        clearTokenAndRedirectToLogin()
                    } else {
                        if (response.data.data !== null) {
                            const contentState = convertFromRaw(response.data.data.opening_message)
                            const editorState = EditorState.createWithContent(contentState)
                            const editorElement = < Editor editorState = { editorState }
                            readOnly = { true }
                            />

                            if (activeQuestionData === '') {
                                setActiveQuestionData(response.data)
                            }

                            if (openingMsg === '') {
                                setOpeningMsg(editorElement)
                            }
                        }
                    }
                })
        }
    }

    // return true if token doesn't match the database record
    // means session expired, user need to re-login
    const isForbidden = (response) => {
        return response === 403
    }

    // clear token then redirect to login page
    const clearTokenAndRedirectToLogin = () => {
        const auth = new Auth()

        // remove session
        auth.removeUserSession()

        history.push('/unauthorized/' + params.id)
    }

    // from opening component
    const getFirstQuestion = (inputId) => {
        formApi
            .getFirstInput(inputId)
            .then(res => {
                // history.push('/' + props.formId + '/' + inputId)

                if (res.data.data.input_type_id === 11) {
                    // company type question
                    const guard = new Guard()
                    const customer = new Customer()
                    const nextQuestionObj = {...JSON.parse(res.data.data.option_list) }

                    let bentukOrganisasi = ''

                    if (guard.isFinalUser()) bentukOrganisasi = userDetail.bentuk_organisasi
                    customer.type(query.get('company')).then(organizationType => {
                        if (!guard.isFinalUser()) bentukOrganisasi = organizationType.data.data.bentuk_organisasi

                        console.log('bentukOrganisasi', bentukOrganisasi)
                        if (bentukOrganisasi === 'perusahaan') {
                            // set first question ID
                            if (firstQuestionId === '') setFirstQuestionId(nextQuestionObj.perusahaan)

                            getFirstQuestion(nextQuestionObj.perusahaan)
                        } else {
                            // set first question ID
                            if (firstQuestionId === '') setFirstQuestionId(nextQuestionObj.perseorangan)

                            getFirstQuestion(nextQuestionObj.perseorangan)
                        }
                    })

                    return
                }

                // set first question ID
                if (firstQuestionId === '') setFirstQuestionId(res.data.data.id)

                setActiveQuestionData(res.data.data)
                setActiveQuestionTypeId(res.data.data.input_type_id)
            })
    }

    // handle on click next
    const nextQuestion = (currInputId, nextInputId, draftId, answer, score, pendingDst = null) => {
        setLoadingMiniBar('20%')

        if (activeDraftId !== '') {
            draftId = activeDraftId
        }

        // console.log('[finalMain.js.nextInputId]', nextInputId)
        formApi
            .getAndStoreInput(
                nextInputId,
                currInputId,
                draftId,
                answer,
                score,
                pendingDst,
                query.get('company'),
                query.get('source'),
                query.get('kota')
            )
            .then(response => {
                if (response.status === 200) {
                    if (nextInputId !== 'done' && nextInputId === 0 && response.data.data.input.id !== 0) {
                        nextInputId = response.data.data.input.id
                    } else if (nextInputId === 'done' || nextInputId !== 0) {
                        // input done or next input single / multi option
                    }
                }

                setLoadingMiniBar('100%')

                setActiveDraftId(response.data.data.draft.id_draft)
                setSavedInputValue(response.data.data.value)

                if (response.data.data.input !== 'done') {
                    setActiveQuestionData(response.data.data.input)
                    setActiveQuestionTypeId(response.data.data.input.input_type_id)
                } else {
                    setActiveQuestionData({})
                    setActiveQuestionTypeId('done')
                }

                setTimeout(function() {
                    setLoadingMiniBar('0%')
                }, 500)
            })
    }

    // handle on back
    const backToPrevQuestion = () => {
        setLoadingMiniBar('20%')

        let draftId, nextInputId = activeQuestionData.id
        if (activeDraftId !== '') {
            draftId = activeDraftId
        }
        // console.log('nextInputId', nextInputId)

        formApi
            .getBack(
                nextInputId,
                draftId
            )
            .then(response => {
                if (response.status === 200) {
                    if (nextInputId !== 'done' && nextInputId === 0 && response.data.data.input.id !== 0) {
                        nextInputId = response.data.data.input.id
                    } else if (nextInputId === 'done' || nextInputId !== 0) {
                        // input done or next input single / multi option
                    }
                }

                setLoadingMiniBar('100%')

                setSavedInputValue(response.data.data.value)
                setActiveDraftId(response.data.data.draft.id_draft)

                if (response.data.data.input !== 'done') {
                    setActiveQuestionData(response.data.data.input)
                    setActiveQuestionTypeId(response.data.data.input.input_type_id)
                } else {
                    setActiveQuestionData({})
                    setActiveQuestionTypeId('done')
                }

                setTimeout(function() {
                    setLoadingMiniBar('0%')
                }, 500)
            })
    }

    const activeQuestion = useMemo(() => {
        console.log('activeQuestion.activeQuestionTypeId', activeQuestionData)
        let activeQuestion = ''
        switch (activeQuestionTypeId) {
            case 'opening':
                // opening
                if (activeQuestionData !== '') {
                    activeQuestion = < OpeningComponent msg = { openingMsg }
                    data = { activeQuestionData }
                    action = { getFirstQuestion }
                    />
                }
                break

            case 'done':
                // done
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < FinishingComponent source = { query.get('source') }
                    key = { Date.now() }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 1:
                // single select
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < SingleSelectOptionComponent value = { savedInputValue }
                    key = { Date.now() }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 2:
                // multi select
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < MultiSelectOptionComponent value = { savedInputValue }
                    key = { Date.now() }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 3:
                // date
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < DateComponent value = { savedInputValue }
                    key = { Date.now() }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 4:
                // email
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < EmailComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 5:
                // file
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < FileComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 6:
                // number
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < NumberComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 7:
                // text
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previously used
                    activeQuestion = < TextComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 8:
                // time
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < TimeComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 9:
                // information
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < InformationComponent key = { Date.now() }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 10:
                // sales area
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previously used
                    activeQuestion = < SalesAreaComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 11:
                // company type
                if (activeQuestionData !== '') {
                    activeQuestion = ''
                }
                break

            case 12:
                // item qty
                if (activeQuestionData !== '') {
                    // using key let's react know that component need to be re-rendered if previuosly used
                    activeQuestion = < ItemQtyComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            case 13:
                // sales area information component
                if (activeQuestionData !== '') {
                    activeQuestion = < SalesAreaInfoComponent key = { Date.now() }
                    value = { savedInputValue }
                    data = { activeQuestionData }
                    action = { nextQuestion }
                    draftId = { activeDraftId }
                    />
                }
                break

            default:
                activeQuestion = ''
                break
        }

        return activeQuestion
    }, [activeQuestionData, activeQuestionTypeId, openingMsg, savedInputValue, activeDraftId])

    return ( 
    <div>
        <div style = {
            { transition: 'width 0.2s', transitionTimingFunction: 'ease', position: 'fixed', top: '0px', width: loadingMiniBar, height: "8px", zIndex: '1', backgroundColor: "#7ceaa6" } } > </div>

        <Row justify = "center" align = "middle" >
            <Col xs = { 24 }
            sm = { 24 }
            md = { 24 }
            lg = { 24 }
            xl = { 24 } >
            <div style = {
                        { width: '100%', minHeight: '100%' } }
                    className = { Company === "pms" ? "page-background-pms" : "page-background-bmm" } >
                <div style = {{ zIndex: '51' } } >
                    <AnimateOnChange durationOut = { 500 } style = {{ display: 'block' } } >
                        { activeQuestion } 
                    </AnimateOnChange> 
                </div> 
                { activeQuestionTypeId > 0 && activeQuestionData.id !== firstQuestionId ?
                <div className = "footer-toolbar"
                    style = {{ zIndex: '50', pointerEvents: 'none' } } >
                    <Row>
                        <Col xs = { 12 }
                        sm = { 2 }
                        md = { 12 }
                        lg = { 12 }
                        xl = { 12 } > 
                        </Col> 
                        <Col xs = { 10 }
                        sm = { 20 }
                        md = { 8 }
                        lg = { 8 }
                        xl = { 8 }
                        align = "right"
                        style = {{ pointerEvents: 'auto' }} >

                            <Button icon = { < LeftOutlined /> }onClick = {
                                () => backToPrevQuestion() } > Kembali </Button> 
                                
                            <Button> {
                                Company === 'pms' ?
                                <img alt = ""
                                src = "https://patigeni.com/wp-content/uploads/2015/03/Patigeni_Final.png"
                                height = "25px" />
                                :
                                <img alt = ""
                                src = "https://www.bromindo.com/wp-content/uploads/2018/02/logo.png"
                                height = "25px" />
                            } </Button> 
                        </Col> 
                        <Col xs = { 2 }
                                sm = { 2 }
                                md = { 4 }
                                lg = { 4 }
                                xl = { 4 }> 
                        </Col> 
                    </Row> 
                </div> : ''
                } 
            </div> </Col> </Row> </div>
    )
}

export default FinalMain