import parse from 'html-react-parser';
import { FileTextTwoTone } from '@ant-design/icons';
import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Card } from 'antd';
import 'antd/dist/antd.css';
import './style.css';
import ContactPerson from '../../../../component/contactPerson';

export default function FileComponent(props) {
    
    const [uploadFileModeActive, setUploadFileModeActive] = useState(false);
    const [inputFileKey, setInputFileKey] = useState(Date.now());
    const [value, setValue] = useState(null);
    const [alert, setAlert] = useState('');
    const [uploadingFile, setUploadingFile] = useState(false);

    const handleChange = (e) => {
        if (alert !== '') setAlert('');
        setValue(e.target.files[0]);
    };

    const keyListener = useCallback(
        () => {
            if (props.data.required) {
                if (value !== null) {
                    setUploadingFile(true);
                    // console.log('[fileComponent.js] API FIRED');
                    props.action(props.data.id, props.data.next_input_id, props.draftId, value, props.data.score);
                } else {
                    setAlert('Mohon upload file');
                }
            } else {
                setUploadingFile(true);
                // console.log('[fileComponent.js] API FIRED');
                props.action(props.data.id, props.data.next_input_id, props.draftId, value, props.data.score);
            }
        }, [props.draftId, props, props.data.id, props.data.next_input_id, props.data.score, value]
    )

    useEffect(() => () => {
        // reset uploading status
        setUploadingFile(false);

        // reset value
        setValue(null);

        // reset input file appearance
        setInputFileKey(Date.now());
    }, [props.action])

    useEffect(() => {
        // console.log('[fileComponent.js] props', props);
        // console.log('[fileComponent.js] uploadingFile', uploadingFile);
        // console.log('[fileComponent.js] value', value);

        // if is currrent is true the the state is latest
        let isCurrent = true;

        // listen keyboard
        document.addEventListener('keydown', (e) => {
            let pressedKey = e.key.toUpperCase();

            // check if enter btn pressed and the state value is latest state
            if (pressedKey === 'ENTER' && isCurrent) {
                // console.log('[fileComponent.js] enter pressed');
                keyListener();
            }
        }, false);

        return () => {
            isCurrent = false;
        }
    }, [alert, props.data, value, uploadingFile, keyListener])

    const fileAlreadyUploaded = <>
        <h1>{props.data !== undefined ? parse(props.data.question) : ''}</h1>
        <Card style={{ width: '100%', height: 'auto', borderRadius:'20px' }} >
            <Row>
                <Col xs={4} sm={4} md={1} lg={1} xl={1}>
                    <FileTextTwoTone style={{ fontSize: '30pt' }} />
                </Col>
                <Col xs={1} sm={1} md={2} lg={1} xl={1}></Col>
                <Col xs={17} sm={17} md={21} lg={22} xl={22}>
                    <h5>File sudah di unggah</h5>
                    <table style={{ width:'100%' }}>
                        <thead>
                            <tr>
                                <td style={{ width:'50px' }}>
                                    <a target="_blank" href={ props.value.input_value }>Lihat</a>
                                </td>
                                <td style={{ width: '80px' }}>
                                    <Button style={{ border: '0px', borderRadius: '20px' }} onClick={(e) => setUploadFileModeActive(true)}>Ubah file</Button>
                                </td>
                                <td>
                                    <Button style={{ border: '0px', borderRadius: '20px' }} type="primary" onClick={(e) => props.action(props.data.id, props.data.next_input_id, props.draftId, value, props.data.score)}>Selanjutnya</Button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Col>
            </Row>
        </Card>
    </>

    const goToExternalLink = () => window.open('https://id.wikihow.com/Membuat-Berkas-Zip', '_blank')

    const fileUpload = <>
        <h1>{props.data !== undefined ? parse(props.data.question) : ''}</h1>

        {/* display required status */}
        <div style={{ marginBottom: '10px' }}>
            {
                props.data !== undefined && props.data.required 
                ? <div>
                    <span style={{ fontSize: '10pt' }}>* harus diisi</span>
                    <br />
                </div> 
                : ''
            }
            <span style={{ fontSize: '10pt',marginBottom: '20px' }}>
                * untuk upload lebih dari satu file mohon zip file terlebih dahulu. <b style={{ cursor: 'pointer' }} onClick={goToExternalLink}>Bantuan</b>
            </span>
        </div>

        <input type="file" key={inputFileKey} className="input-underline-file" onChange={handleChange} />
        {/* alert */}
        <span style={{ color: 'red' }}>{alert}</span>

        <div style={{ fontSize: '13pt', marginTop: '10px' }}>
            <Button className="fancy-shadow" onClick={() => keyListener()} loading={uploadingFile}>OK</Button>
            <span style={{ fontSize: '10pt', marginLeft: '10px' }}>tekan <strong>Enter ↵</strong></span>
        </div>
    </>;

    return (
        <div>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
                <Col xs={20} sm={20} md={16} lg={16} xl={16} >
                    {
                        Object.keys(props.value).length > 0 && !uploadFileModeActive && props.value.input_value != null && props.value.input_value != ''
                            ? fileAlreadyUploaded
                            : fileUpload
                    }

                    <ContactPerson />
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4} ></Col>
            </Row>
        </div>
    )
}