/**
 * 
 * @param {*} props 
 * path [url] : String
 * component [javascript component] : ReactComponent
 * middleware [access middleware] : Promise<bool>
 * 
 * @returns 
 * route
 */

import { useEffect, useState } from "react"
import {
    Route
} from "react-router-dom"

/**
 * 
 * @param {*} props 
 * - middleware : Array
 * limit route access based on defined middleware rule
 * 
 * - strict : bool | default Strict : true
 * if true, all middleware specified must be valid to current user. if false, only one valid middleware needed to access the url
 * 
 * - path
 * url route
 * 
 * - component
 * component displayed
 * @returns 
 */
const Go = (props) => {

    const [accessGranted, setAccessGranted] = useState()
    const [errorMsg, setErrorMsg] = useState()

    useEffect(async () => {
        let mounted = true
        if (mounted) {
            const isMiddlewareAvailable = props.middleware !== undefined && props.middleware.length > 0
            if (isMiddlewareAvailable) {
                let accessList = []
                
                for (let i = 0; i < props.middleware.length; i++) {
                    const el = await props.middleware[i]
                        .catch((err) => setErrorMsg(err.toString()))

                    if (el !== undefined && 'validity' in el) accessList.push(el.validity)
                }

                let isAccessListValid = false;
                if ('strict' in props) {
                    if (props.strict) {
                        // strict mode true
                        isAccessListValid = accessList.filter(v => v == false).length == 0
                    } else {
                        // strict mode false
                        isAccessListValid = accessList.filter(v => v == true).length > 0
                    }
                } else {
                    // default strict = true
                    isAccessListValid = accessList.filter(v => v == false).length == 0
                }

                if (isAccessListValid)
                    setAccessGranted(true)
                else
                    setAccessGranted(false)
            } else {
                setAccessGranted(true)
            }
        }

        return () => {
            mounted = false
            setAccessGranted(undefined)
        }
    })

    const forbidden = () => {
        return <div style={{ minHeight: "100vh", textAlign: "center"}}>
            <span style={{ fontSize: "40pt" }}>🔒</span>
            <h1>Anda tidak memiliki akses</h1>
            <button onClick={() => window.location.reload()}>Muat Ulang</button>
        </div>
    }

    const loading = () => {
        return <div style={{ minHeight: "100vh", textAlign: "center" }}>
            <span style={{ fontSize: "40pt" }}>⏰</span>
            <h1>Tunggu ya ..</h1>
        </div>
    }

    return errorMsg !== undefined
        ? <div>{errorMsg}</div>
        : accessGranted === undefined
            ? <div>{loading()}</div>
            : accessGranted
                ? <Route path={props.path} exact={true} component={props.component} />
                : <div>{forbidden()}</div>
}

export default Go