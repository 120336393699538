import { Row, Col } from 'antd';
import { useEffect } from 'react';
import DocsIllustrationImg from '../../../../resources/docs-illustration-min.jpeg';
import ButtonLink from '../../../button';
import useQuery from '../../../../public/final/main/hooks/useQuery';
import FormPublic from '../../../../app/utils/formPublic';

function Patigeni2() {

    const fontFamily = "'merriweather', Courier, monospace";
    const param = useQuery();
    const source = param.get('source');
    const formPublic = new FormPublic();

    useEffect(() => {
        if (source != null) formPublic.recordInquirySource('https://final.patigeni.com/next', source)
    }, [])

    return (
        <div className="container" style={{ paddingTop: '20px', paddingBottom: '50px' }}>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <img alt="Document" src={DocsIllustrationImg} width="100%" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div style={{ width: "100%" }}>
                        <h1 style={{ color: '#124265', fontWeight: 'bold', fontFamily: fontFamily }}>
                            Supply & Jasa Instalasi
                        </h1>
                        <h6 style={{ color: '#124265', fontFamily: fontFamily }}>FIRE HYDRANT - FIRE ALARM - FIRE SYSTEM</h6>
                        <p style={{ color: '#919191', fontSize: '15pt' }}>
                            Kirimkan secara lengkap data yang kami butuhkan agar dapat menghitung kebutuhan proyek yang akan kalian bangun / kerjakan, tanpa data yang lengkap inquiry semakin lambat.
                            <br />
                            Persiapkan data telebih dulu, Kalau sudah komplit, silahkan klik lanjut
                        </p>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: '5px', paddingTop: '10px' }}>
                            <ButtonLink title="Lanjut" to={"/Mg==" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ backgroundColor: '#d9ecff', fontWeight: 'bold', border: '1px solid rgb(173 210 249)' }} />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                            <ButtonLink title="Kembali" to={"/" + (param.get('source') != null ? "?source=" + param.get('source') : "")} style={{ fontWeight: 'bold', border: '1px solid rgb(214 214 214)' }} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Patigeni2;